var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseOutlined, FileOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { message, Progress, Space, Upload } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getUploadUrl, UploadUrlType } from 'src/hooks/api/useUploadApi';
import octoClient from '../services/octoClient';
import './ComponentWithUploadAttachment.scss';
var ComponentWithUploadAttachment = function (_a) {
    var children = _a.children, type = _a.type, fileList = _a.fileList, setFileList = _a.setFileList;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _b = useState(), token = _b[0], setToken = _b[1];
    var uploadFileURL = octoClient.serverUrl + getUploadUrl(token ? type : UploadUrlType.Public);
    useEffect(function () {
        var mounted = true;
        getAccessTokenSilently().then(function (resp) {
            if (mounted)
                setToken(resp);
        });
        return function () {
            mounted = false;
        };
    }, [getAccessTokenSilently]);
    var updateFileList = useCallback(function (info) {
        setFileList(info.fileList.map(function (file) {
            var _a;
            if (file.response && ((_a = file.type) === null || _a === void 0 ? void 0 : _a.startsWith('image/'))) {
                return __assign(__assign({}, file), { thumbUrl: (location.hostname === 'localhost'
                        ? process.env.REACT_APP_FILE_URL
                        : '') + file.response.data.url });
            }
            return file;
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs("div", { className: "UploadButtonWrapper", children: [_jsx(Upload, { className: "FileList", fileList: fileList, listType: "picture", onChange: updateFileList, itemRender: function (originNode, file) {
                    var _a, _b, _c;
                    var content;
                    if (file.status === 'uploading') {
                        content = (_jsx(Progress, { className: "ProgressIndicator", type: "circle", width: 36, percent: Math.round((file.percent || 0) * 1) / 1 }));
                    }
                    else if (file.status === 'done' &&
                        file.thumbUrl &&
                        ((_a = file.type) === null || _a === void 0 ? void 0 : _a.startsWith('image/'))) {
                        content = _jsx("img", { className: "ImagePreview", src: file.thumbUrl });
                    }
                    else if (file.status === 'error') {
                        content = (_jsxs(Space, { className: "ErrorPreview", children: [_jsx(FileOutlined, {}), file.name] }));
                    }
                    else {
                        content = (_jsxs(Space, { className: "FilePreview", children: [_jsx(FileOutlined, {}), _jsx("a", { href: (_c = (_b = file.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.url, target: "_blank", rel: "noreferrer", children: file.name })] }));
                    }
                    return (_jsxs("div", { className: "AttachmentSendingPreview", children: [content, _jsx("div", { className: "RemoveAttachmentButtonWrapper", onClick: function () {
                                    setFileList(fileList.filter(function (f) { return f.uid !== file.uid; }));
                                }, children: _jsx(CloseOutlined, { className: "RemoveAttachmentButton" }) })] }));
                } }), _jsx(Upload, { showUploadList: false, action: uploadFileURL, fileList: fileList, headers: token ? { Authorization: "Bearer ".concat(token) } : {}, onChange: function (info) {
                    message.destroy();
                    if (info.file.status === 'uploading') {
                        message.loading('Uploading...', 1000);
                    }
                    else if (info.file.status === 'done') {
                        message.success("".concat(info.file.name, " file uploaded successfully."));
                    }
                    else if (info.file.status === 'error') {
                        message.error("".concat(info.file.name, " file upload failed."));
                    }
                    updateFileList(info);
                }, children: children })] }));
};
export default React.memo(ComponentWithUploadAttachment);
