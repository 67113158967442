import { jsx as _jsx } from "react/jsx-runtime";
import { Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useRecoilValue } from 'recoil';
import { currentGeneralPermissions } from 'src/state';
import NoPermissionSettingContent from './NoPermissionSettingContent';
import { GeneralPermissionKey } from './Permission/Constants';
import AttestationPage from './Attestation/AttestationPage';
import BillingPage from './Billing/BillingPage';
import BreaksPage from './Breaks/BreaksPage';
import BusinessDetails from './BusinessDetails/BusinessDetails';
import Collections from './Collections/Collections';
import DifferentialsPage from './Differentials/DifferentialsPage';
import GeofencePage from './Geofence/GeofencePage';
import IntegrationPage from './Integration/IntegrationPage/IntegrationPage';
import IntegrationSetup from './Integration/IntegrationPage/IntegrationSetup';
import JobCodesPage from './JobCodes/JobCodesPage';
import OvertimePage from './Overtime/OvertimePage';
import PermissionPage from './Permission/PermissionPage';
import TimeClockPage from './TimeClock/TimeClockPage';
import TimeoffsPage from './Timeoffs/TimeoffsPage';
import PayRatePage from './PayRate/DefaultRatesTab/PayRateSettingsPage';
import PayPeriodsPage from './PayPeriod/PayPeriodSettingPage';
import ChatSettingPage from './Chat/ChatSettingPage';
import SchedulingSettingsPage from './Scheduling/SchedulingSettingsPage';
import AccountBranding from './Branding/AccountBranding';
import HolidayPayPage from './HolidayPay/HolidayPayPage';
import DashboardSettingsPage from './Dashboard/DashboardSettings';
var ACCOUNT_SETTING_ROUTES = [
    {
        path: '/business',
        permissionKey: GeneralPermissionKey.EDIT_ACCOUNT,
        content: _jsx(BusinessDetails, {}),
    },
    {
        path: '/branding',
        permissionKey: GeneralPermissionKey.EDIT_ACCOUNT,
        content: _jsx(AccountBranding, {}),
    },
    {
        path: '/dashboard',
        permissionKey: GeneralPermissionKey.EDIT_ACCOUNT,
        content: _jsx(DashboardSettingsPage, {}),
    },
    {
        path: '/timeoff',
        permissionKey: GeneralPermissionKey.TIME_TRACKING,
        content: _jsx(TimeoffsPage, {}),
    },
    {
        path: '/jobcodes',
        permissionKey: GeneralPermissionKey.TIME_TRACKING,
        content: _jsx(JobCodesPage, {}),
    },
    {
        path: '/breaks',
        permissionKey: GeneralPermissionKey.TIME_TRACKING,
        content: _jsx(BreaksPage, {}),
    },
    {
        path: '/overtime',
        permissionKey: GeneralPermissionKey.TIME_TRACKING,
        content: _jsx(OvertimePage, {}),
    },
    {
        path: '/timeclock',
        permissionKey: GeneralPermissionKey.TIME_TRACKING,
        content: _jsx(TimeClockPage, {}),
    },
    {
        path: '/geofence',
        permissionKey: GeneralPermissionKey.TIME_TRACKING,
        content: _jsx(GeofencePage, {}),
    },
    {
        path: '/payrates',
        permissionKey: GeneralPermissionKey.PAY,
        content: _jsx(PayRatePage, {}),
    },
    {
        path: '/differentials',
        permissionKey: GeneralPermissionKey.PAY,
        content: _jsx(DifferentialsPage, {}),
    },
    {
        path: '/attestation',
        permissionKey: GeneralPermissionKey.PAY,
        content: _jsx(AttestationPage, {}),
    },
    {
        path: '/payperiods',
        permissionKey: GeneralPermissionKey.PAY_PERIODS,
        content: _jsx(PayPeriodsPage, {}),
    },
    {
        path: '/access_groups',
        permissionKey: GeneralPermissionKey.ACCESS_GROUPS,
        content: _jsx(PermissionPage, {}),
    },
    {
        path: '/collections',
        permissionKey: GeneralPermissionKey.BUILD_DATA,
        content: _jsx(Collections, {}),
    },
    {
        path: '/integrations',
        permissionKey: GeneralPermissionKey.INTEGRATIONS,
        content: _jsx(IntegrationPage, {}),
    },
    {
        path: '/integrations/:ipName',
        permissionKey: GeneralPermissionKey.INTEGRATIONS,
        content: _jsx(IntegrationSetup, {}),
    },
    {
        path: '/billing',
        permissionKey: GeneralPermissionKey.BILLING,
        content: _jsx(BillingPage, {}),
    },
    {
        path: '/team_chat',
        permissionKey: GeneralPermissionKey.CHAT,
        content: _jsx(ChatSettingPage, {}),
    },
    {
        path: '/scheduling',
        permissionKey: GeneralPermissionKey.SCHEDULE_TEMPLATE &&
            (GeneralPermissionKey.NEEDS_COVERAGE || GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY),
        content: _jsx(SchedulingSettingsPage, {}),
    },
    {
        path: '/holiday',
        permissionKey: GeneralPermissionKey.PAY,
        content: _jsx(HolidayPayPage, {}),
    },
];
var SentryRoutes = Sentry.withSentryRouting(Routes);
var SettingsContent = function () {
    var generalPermissions = useRecoilValue(currentGeneralPermissions);
    return (_jsx(SentryRoutes, { children: ACCOUNT_SETTING_ROUTES.map(function (item) { return (_jsx(Route, { path: item.path, element: (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions[item.permissionKey]) ? (item.content) : (_jsx(NoPermissionSettingContent, {})) }, item.path)); }) }));
};
export default SettingsContent;
