export var PayRuleConditionOperand;
(function (PayRuleConditionOperand) {
    PayRuleConditionOperand[PayRuleConditionOperand["OR"] = 0] = "OR";
    PayRuleConditionOperand[PayRuleConditionOperand["AND"] = 1] = "AND";
})(PayRuleConditionOperand || (PayRuleConditionOperand = {}));
export var PayRuleAdjustmentField;
(function (PayRuleAdjustmentField) {
    PayRuleAdjustmentField["PAY_RATE"] = "PAY_RATE";
    PayRuleAdjustmentField["BILL_RATE"] = "BILL_RATE";
    PayRuleAdjustmentField["TOTAL_PAY"] = "TOTAL_PAY";
    // TOTAL_BILL = 'TOTAL_BILL, // TODO: Get product clarification
    PayRuleAdjustmentField["CUSTOM"] = "CUSTOM";
    PayRuleAdjustmentField["NONE"] = "NONE";
})(PayRuleAdjustmentField || (PayRuleAdjustmentField = {}));
export var PayRuleAdjustmentType;
(function (PayRuleAdjustmentType) {
    PayRuleAdjustmentType["BY_AMOUNT"] = "BY_AMOUNT";
    PayRuleAdjustmentType["BY_PERCENT"] = "BY_PERCENT";
    PayRuleAdjustmentType["SET_TO"] = "SET_TO";
    PayRuleAdjustmentType["PER_UNIT"] = "PER_UNIT";
})(PayRuleAdjustmentType || (PayRuleAdjustmentType = {}));
export var PayItemType;
(function (PayItemType) {
    PayItemType["PAY_RATE"] = "PAY_RATE";
    PayItemType["PAY_RULE"] = "PAY_RULE";
})(PayItemType || (PayItemType = {}));
export var DefaultRateTableHeaderType;
(function (DefaultRateTableHeaderType) {
    DefaultRateTableHeaderType["CONDITION"] = "CONDITION";
    DefaultRateTableHeaderType["ADJUSTMENT"] = "ADJUSTMENT";
})(DefaultRateTableHeaderType || (DefaultRateTableHeaderType = {}));
