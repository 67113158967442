import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorBoundary } from '@sentry/react';
import { Button, Skeleton } from 'antd';
import React, { lazy, Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useSetRecoilState } from 'recoil';
import { usePayApi } from 'src/hooks/api/usePayApi';
import { useRecordComponent } from 'src/hooks/component';
import { detailModalPropsState, isNewModalVisibleState } from 'src/state';
import BlockCollectionTableErrorBoundary from '../OptimizedTableView/Error/BlockCollectionTableErrorBoundary';
import BlockWrapper from '../TableView/BlockWrapper';
import UserAvatar from '../User/UserAvatar';
import ZiraModal from '../../ui/ZiraModal/ZiraModal';
import TotalPayReportCard from './PayReport/TotalPayReportCard';
import UserAttestationPopOver from './UserAttestationPopOver';
var PrintOnlyPayDetailContent = lazy(function () { return import('./PrintOnlyPayDetailContent'); });
var PayReportDetailModal = function (_a) {
    var recordId = _a.recordId, payrollEnhancementEnabled = _a.payrollEnhancementEnabled;
    var _b = useParams().payPeriodId, payPeriodId = _b === void 0 ? '' : _b;
    var useGetRecordPayDetails = usePayApi().useGetRecordPayDetails;
    var data = useGetRecordPayDetails({
        payPeriodId: payPeriodId,
        recordId: recordId,
    }).data;
    var _c = useMemo(function () {
        return { recordTitle: data === null || data === void 0 ? void 0 : data.recordTitle, userImageUrl: data === null || data === void 0 ? void 0 : data.userImageUrl };
    }, [data]), recordTitle = _c.recordTitle, userImageUrl = _c.userImageUrl;
    var blocks = useMemo(function () { var _a; return (_a = data === null || data === void 0 ? void 0 : data.payPageBlocks) !== null && _a !== void 0 ? _a : []; }, [data === null || data === void 0 ? void 0 : data.payPageBlocks]);
    var _d = useState([]), printPayMetric = _d[0], setPrintPayMetric = _d[1];
    var printHeader = useMemo(function () {
        return {
            title: (data === null || data === void 0 ? void 0 : data.recordTitle) || '',
            startDate: (data === null || data === void 0 ? void 0 : data.payPeriodStart) || '',
            endDate: (data === null || data === void 0 ? void 0 : data.payPeriodEnd) || '',
        };
    }, [data]);
    var setDetailModalProps = useSetRecoilState(detailModalPropsState);
    var setIsNewModalVisible = useSetRecoilState(isNewModalVisibleState);
    var onCreateNewRecord = useRecordComponent().onCreateNewRecord;
    var blockWrapper = useCallback(function (blockId, contentType) {
        return (_jsx("div", { className: "mb-16 flex flex-col", id: blockId, children: _jsx(ErrorBoundary, { fallback: _jsx(BlockCollectionTableErrorBoundary, { blockId: blockId }), children: _jsx(BlockWrapper, { blockId: blockId, blockContentType: contentType, pageId: payPeriodId, onCreateNewRecord: onCreateNewRecord, setDetailModalProps: setDetailModalProps, setIsModalVisible: setIsNewModalVisible }) }) }, blockId));
    }, [onCreateNewRecord, payPeriodId, setDetailModalProps, setIsNewModalVisible]);
    var payDetailBlocks = useMemo(function () {
        return blocks.map(function (block) { return blockWrapper(block.blockId, block.contentType); });
    }, [blocks, blockWrapper]);
    var navigate = useNavigate();
    var closeModal = useCallback(function () {
        navigate("/home/pay/".concat(payPeriodId), { replace: true });
    }, [navigate, payPeriodId]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onBeforeGetContentResolve = useRef(null);
    var _e = useState(false), isPrinting = _e[0], setIsPrinting = _e[1];
    var printRef = useRef(null);
    var handlePrint = useReactToPrint({
        content: function () {
            return printRef.current;
        },
        onBeforeGetContent: function () {
            return new Promise(function (resolve) {
                setIsPrinting(true);
                onBeforeGetContentResolve.current = resolve;
            });
        },
        onAfterPrint: function () { return setIsPrinting(false); },
    });
    var printCallbackRef = useCallback(function (node) {
        if (node !== null && onBeforeGetContentResolve.current !== null) {
            printRef.current = node;
            onBeforeGetContentResolve.current();
        }
    }, []);
    return (_jsx(ZiraModal, { show: true, onClose: closeModal, children: _jsxs("div", { className: "PayModal flex w-full flex-col", children: [_jsxs("div", { className: "ModalHeader bg-white/50", children: [recordTitle ? (_jsxs(_Fragment, { children: [userImageUrl && (_jsx(UserAvatar, { className: "mr-2 flex-none", name: recordTitle, avatar: userImageUrl, size: 48 })), _jsx("div", { className: "h-full overflow-hidden text-ellipsis text-3xl font-semibold", children: recordTitle }), (data === null || data === void 0 ? void 0 : data.attestation) && (_jsx("span", { className: "ml-4", children: _jsx(UserAttestationPopOver, { name: recordTitle, attestation: data === null || data === void 0 ? void 0 : data.attestation }) }))] })) : (_jsx(Skeleton.Input, { active: true })), _jsx(Button, { className: "HeaderCloseButton", onClick: closeModal, children: "\u2715" })] }), _jsxs("div", { className: "overflow-y-auto", children: [_jsx(TotalPayReportCard, { payPeriodId: payPeriodId, recordId: recordId, handlePrint: handlePrint, setPrintMetric: function (value) { return setPrintPayMetric(value); }, payrollEnhancementEnabled: payrollEnhancementEnabled }), payDetailBlocks] }), isPrinting && (_jsx(Suspense, { fallback: null, children: _jsx("div", { style: { display: 'none' }, children: _jsx(PrintOnlyPayDetailContent, { headerContent: printHeader, payMetricData: printPayMetric, blocks: blocks, ref: printCallbackRef }) }) }))] }) }));
};
export default React.memo(PayReportDetailModal);
