var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var CircleIconSvg = function (props) { return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("rect", { x: "4", y: "4", width: "16", height: "16", rx: "8", fill: props.fill || '#A7A8AC' }) })); };
export var CircleIcon = function (props) { return (_jsx(Icon, __assign({ component: function () { return _jsx(CircleIconSvg, __assign({}, props)); } }, props))); };
