import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useNaviTreeComponent, useRecordDetail } from 'src/hooks/component';
import NewsFeed from '../CenterPanel/NewsFeed/NewsFeed';
import ButtonBlock from '../PageView/ButtonBlock/ButtonBlock';
import GeoMapBlock from '../PageView/GeoMapBlock/GeoMapBlock';
import HeadcountBlock from '../PageView/HeadcountBlock/HeadcountBlock';
import ImageBlock from '../PageView/ImageBlock/ImageBlock';
import MarkdownBlock from '../PageView/MarkdownBlock/MarkdownBlock';
import MetricBar from '../PageView/MetricBar/MetricBar';
import PayPeriodsBlock from '../PageView/PayPeriodsBlock/PayPeriodsBlock';
import VideoBlock from '../PageView/VideoBlock/VideoBlock';
import ReportBlockWrapper from '../ReportBlock/ReportBlockWrapper';
import ShiftClaimTable from '../PageView/ShiftRequest/ShiftClaim/ShiftClaimTable';
import ShiftReleaseTable from '../PageView/ShiftRequest/ShiftRelease/ShiftReleaseTable';
import BlockCollectionView from '../OptimizedTableView/BlockCollectionView';
import ScheduleBlockTable from '../ScheduleView/ScheduleBlockTable';
var BlockWrapper = function (_a) {
    var blockId = _a.blockId, blockContentType = _a.blockContentType, pageId = _a.pageId;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var saveRenameNode = useNaviTreeComponent().saveRenameNode;
    var onUpdateBlockTitle = useCallback(function (isPageTitle, blockId, title, requestSave) {
        return saveRenameNode('block', blockId, title, requestSave);
    }, [saveRenameNode]);
    var moveBlockUpOrDown = useNaviTreeComponent().moveBlockUpDown;
    var moveBlockUpDown = useCallback(function (blockId, isUp) {
        if (!pageId)
            return;
        moveBlockUpOrDown(pageId, blockId, isUp);
    }, [moveBlockUpOrDown, pageId]);
    switch (blockContentType) {
        /**
         * Text Markdown Block
         */
        case 'markdown': {
            return (_jsx(MarkdownBlock, { blockId: blockId, moveBlockUpDown: function (isMoveUp) { return moveBlockUpDown(blockId, isMoveUp); } }));
        }
        case 'newsfeed': {
            return (_jsx(NewsFeed, { blockId: blockId, moveBlockUpDown: function (isMoveUp) { return moveBlockUpDown(blockId, isMoveUp); } }));
        }
        case 'button': {
            return (_jsx(ButtonBlock, { blockId: blockId, moveBlockUpDown: function (isMoveUp) { return moveBlockUpDown(blockId, isMoveUp); } }));
        }
        case 'shift_requests': {
            return (_jsx(ShiftClaimTable, { blockId: blockId, updateBlockName: function (title, requestSave) {
                    return onUpdateBlockTitle(false, blockId, title, requestSave);
                } }));
        }
        case 'release_requests': {
            return (_jsx(ShiftReleaseTable, { blockId: blockId, updateBlockName: function (title, requestSave) {
                    return onUpdateBlockTitle(false, blockId, title, requestSave);
                } }));
        }
        case 'image': {
            return (_jsx(ImageBlock, { blockId: blockId, pageId: pageId || '', moveBlockUpDown: function (isMoveUp) { return moveBlockUpDown(blockId, isMoveUp); } }));
        }
        case 'video': {
            return (_jsx(VideoBlock, { blockId: blockId, pageId: pageId || '', moveBlockUpDown: function (isMoveUp) { return moveBlockUpDown(blockId, isMoveUp); } }));
        }
        case 'metric_bar': {
            return (_jsx(MetricBar, { blockId: blockId, moveBlockUpDown: function (isMoveUp) { return moveBlockUpDown(blockId, isMoveUp); } }));
        }
        case 'geomap': {
            return (_jsx(GeoMapBlock, { blockId: blockId, pageId: pageId || '', moveBlockUpDown: function (isMoveUp) { return moveBlockUpDown(blockId, isMoveUp); } }));
        }
        /**
         * Schedule Block
         */
        case 'scheduling': {
            return _jsx(ScheduleBlockTable, { blockId: blockId });
        }
        case 'report': {
            return (_jsx(ReportBlockWrapper, { blockId: blockId, contentType: blockContentType, openRecordDetail: openRecordDetail }));
        }
        case 'pay_periods': {
            return (_jsx(PayPeriodsBlock, { blockId: blockId, moveBlockUpDown: function (isMoveUp) { return moveBlockUpDown(blockId, isMoveUp); } }));
        }
        case 'headcount': {
            return (_jsx(HeadcountBlock, { blockId: blockId, moveBlockUpDown: function (isMoveUp) { return moveBlockUpDown(blockId, isMoveUp); } }));
        }
        case 'collection': {
            return _jsx(BlockCollectionView, { blockId: blockId });
        }
        default: {
            return _jsx(_Fragment, {});
        }
    }
};
export default React.memo(BlockWrapper);
