var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Input, Menu, Select, Space, Tag } from 'antd';
import { DeleteOutlined, EllipsisOutlined, CheckOutlined, CloseOutlined, LoadingOutlined, PlusOutlined, DownOutlined, UpOutlined, } from '@ant-design/icons';
import '../../../TableView/TableCell/SelectorCell.scss';
import _, { cloneDeep, isEqual, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { collectionTableSchemaSelector, ifCurrentUserHasDataBuilderPermission, } from 'src/state';
import { useRecordComponent, useSchemaComponent } from 'src/hooks/component';
import { useRecoilValue } from 'recoil';
import UserTagWithIcon from '../../../User/UserTagWithIcon';
import { allowInlineCreateStandardObj, allowCreateUserObj, isBooleanType, isPlainSelection, isShiftPayrollStatusSchema, NativeMultiSet, OPTIONS_COLOR, OPTIONS_COLOR_NAME, } from '../../../../shared';
import { disableScroll, getTagTextColor } from '../../../../utils/Collection';
import './LogicSelectionCell.scss';
import WarningModalForShiftPayrollStatusSelector from './StandardModals/WarningModalForShiftPayrollStatusSelector';
import AddNewStandardObjConfirmationModal from './StandardModals/AddNewStandardObjConfirmationModal';
var Option = Select.Option;
export var colorPickerItem = function (index, selectedColor) {
    return (_jsx("div", { className: "color-picker-item-wrapper", children: _jsxs(Space, { align: "start", children: [_jsx("div", { className: "color-picker-item-indicator", style: {
                        backgroundColor: OPTIONS_COLOR[index],
                        color: getTagTextColor(OPTIONS_COLOR[index]),
                    } }), _jsx("span", { className: "color-picker-item-text", children: OPTIONS_COLOR_NAME[index] }), selectedColor === OPTIONS_COLOR[index] && _jsx(CheckOutlined, {})] }) }));
};
var generateRandomIndex = function () { return Math.floor(Math.random() * OPTIONS_COLOR.length); };
var DropdownInput = function (props) {
    var tagTitle = props.tagTitle, onTextChange = props.onTextChange;
    var _a = useState(tagTitle), inputValue = _a[0], setInputValue = _a[1];
    return (_jsx(Input, { className: "selector-menu-input", onClick: function (e) {
            e.currentTarget.focus();
            e.preventDefault();
            e.stopPropagation();
        }, value: inputValue, onChange: function (e) {
            setInputValue(e.target.value);
            onTextChange(e.target.value);
        }, autoFocus: true }));
};
var LogicSelectionCell = function (props) {
    var _a;
    var cellPos = props.cellPos, defaultDisplayOptions = props.displayedOption, defaultAvailableOptions = props.availableOption, defaultIsUserSelecting = props.isUserSelecting, editCell = props.editCell, setIsClicked = props.setIsClicked, mutateColumnOption = props.mutateColumnOption, deleteColumnOption = props.deleteColumnOption, addColumnOption = props.addColumnOption, reorderColumnOptions = props.reorderColumnOptions, isLoading = props.isLoading, paginationProps = props.paginationProps;
    var _b = paginationProps || {}, onScroll = _b.onScroll, onSearch = _b.onSearch;
    var _c = useState(defaultDisplayOptions === null || defaultDisplayOptions === void 0 ? void 0 : defaultDisplayOptions.map(function (_a) {
        var id = _a.id;
        return id;
    })), selectedItemIds = _c[0], setSelectedItemIds = _c[1];
    useEffect(function () {
        setSelectedItemIds(defaultDisplayOptions === null || defaultDisplayOptions === void 0 ? void 0 : defaultDisplayOptions.map(function (_a) {
            var id = _a.id;
            return id;
        }));
    }, [defaultDisplayOptions]);
    var _d = useState(_.uniqBy(defaultAvailableOptions, 'id')), availableOptions = _d[0], setAvailableOptions = _d[1];
    useEffect(function () {
        setAvailableOptions(cloneDeep(_.uniqBy(defaultAvailableOptions, 'id')));
        return function () {
            setAvailableOptions([]);
        };
    }, [defaultAvailableOptions]);
    var schema = useRecoilValue(collectionTableSchemaSelector(cellPos));
    var onCreateNewRecord = useRecordComponent().onCreateNewRecord;
    var addNewStandardObjInColumnOption = useSchemaComponent().addNewStandardObjInColumnOption;
    var _e = useState(''), newOptionNameInput = _e[0], setNewOptionNameInput = _e[1];
    var _f = useState(false), showCantAddMessage = _f[0], setShowCantAddMessage = _f[1];
    var isNewOptionName = useMemo(function () {
        return !_.find(availableOptions, function (option) { return option.text === newOptionNameInput; });
    }, [availableOptions, newOptionNameInput]);
    var areOptionsOnSearch = useMemo(function () {
        return _.find(availableOptions, function (option) {
            return option.text.toLowerCase().indexOf(newOptionNameInput.toLowerCase().trim()) > -1;
        });
    }, [availableOptions, newOptionNameInput]);
    var _g = useState(0), editOptionIndex = _g[0], setEditOptionIndex = _g[1];
    var _h = useState(Array(availableOptions.length).fill(false)), isEditMenuVisible = _h[0], setIsEditMenuVisible = _h[1];
    var _j = useState(null), renameText = _j[0], setRenameText = _j[1];
    var _k = useState(generateRandomIndex()), randomColorIndex = _k[0], setRandomColorIndex = _k[1];
    var _l = useState([]), selectedItems = _l[0], setSelectedItems = _l[1];
    var _m = useState(false), isShiftPayrollStatusWarningModalVisible = _m[0], setIsShiftPayrollStatusWarningModalVisible = _m[1];
    var _o = useState(false), isAddNewObjConfirmationModalVisible = _o[0], setIsAddNewObjConfirmationModalVisible = _o[1];
    var isShowPlainSelectionActions = useMemo(function () { return isPlainSelection(schema.type); }, [schema.type]);
    var isMulti = useMemo(function () { return schema.inputMode === 'multi' || NativeMultiSet.has(schema.nativePath); }, [schema.inputMode, schema.nativePath]);
    var isUserSelecting = useMemo(function () { return defaultIsUserSelecting || false; }, [defaultIsUserSelecting]);
    var selectRef = useRef(null);
    useEffect(function () {
        var _a;
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        disableScroll(true);
        return function () { return disableScroll(false); };
    }, []);
    var allOptions = useMemo(function () { return uniqBy(__spreadArray(__spreadArray(__spreadArray([], selectedItems, true), defaultDisplayOptions, true), availableOptions, true), 'id'); }, [availableOptions, defaultDisplayOptions, selectedItems]);
    //! Set selected items/options based on selected item ids
    useEffect(function () {
        var items = allOptions.filter(function (opt) {
            if (selectedItemIds.includes(opt.id))
                return opt;
        });
        setSelectedItems(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemIds]);
    var handleDropDownVisibleChange = useCallback(function (dropdownVisible) {
        setIsClicked(dropdownVisible);
    }, [setIsClicked]);
    var onRemoveTag = useCallback(function (id) {
        var selectedIds = selectedItemIds.filter(function (itemId) { return itemId !== id; });
        setSelectedItemIds(selectedIds);
        if (!isMulti) {
            editCell([]);
        }
    }, [selectedItemIds, isMulti, editCell]);
    var onClearAll = useCallback(function () {
        setSelectedItemIds([]);
    }, []);
    var onSelectAll = useCallback(function () {
        var selectedIds = availableOptions.map(function (option) { return option.id; });
        setSelectedItemIds(selectedIds);
    }, [availableOptions]);
    var openNewObjectModal = useCallback(function () {
        var targetCollectionId = schema.refCollectionId;
        onCreateNewRecord('', targetCollectionId);
        handleDropDownVisibleChange(false);
    }, [handleDropDownVisibleChange, onCreateNewRecord, schema.refCollectionId]);
    var tagRender = useCallback(function (props) {
        var _a, _b, _c;
        var label = props.label;
        var tagColor = (_a = _.find(allOptions, function (item) { return item.id === label; })) === null || _a === void 0 ? void 0 : _a.color;
        var tagText = (_b = _.find(allOptions, function (item) { return item.id === label; })) === null || _b === void 0 ? void 0 : _b.text;
        if (!tagText) {
            return _jsx(_Fragment, {});
        }
        var closable = !isBooleanType(schema.type) && !isShiftPayrollStatusSchema(schema.nativePath);
        if (isUserSelecting) {
            var selectCellProps = {
                closable: closable,
                onRemoveTag: onRemoveTag,
                label: label,
            };
            var tagAvatar = (_c = _.find(allOptions, function (item) { return item.id === label; })) === null || _c === void 0 ? void 0 : _c.userAvatar;
            var avatarProps = {
                avatar: tagAvatar,
            };
            return (_jsx(UserTagWithIcon, { name: tagText, size: 22, selectCellProps: selectCellProps, avatarProps: avatarProps }));
        }
        if (!tagText)
            return _jsx(_Fragment, {});
        return (_jsx(Tag, { color: tagColor, onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, className: "selector-tag", closeIcon: _jsx(CloseOutlined, { className: "selector-tag-close" }), closable: closable, onClose: function () { return onRemoveTag(label); }, style: { color: getTagTextColor(tagColor) }, children: tagText }));
    }, [allOptions, isUserSelecting, onRemoveTag, schema.nativePath, schema.type]);
    var onTextChange = useCallback(function (newText) {
        setRenameText(newText);
    }, []);
    var onOptionColorChange = useCallback(function (e) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
        var tempOptionData = cloneDeep(availableOptions);
        tempOptionData[editOptionIndex].color = e.key;
        setAvailableOptions(tempOptionData);
    }, [availableOptions, editOptionIndex]);
    var handleEditMenuVisibleChange = useCallback(function (index, visible) {
        var tempIsEditMenuVisible = cloneDeep(isEditMenuVisible);
        tempIsEditMenuVisible[index] = visible;
        setIsEditMenuVisible(tempIsEditMenuVisible);
    }, [isEditMenuVisible]);
    var deleteSelectedOption = useCallback(function (id) {
        var newSelectedIds = _.filter(selectedItemIds, function (item) { return item !== id; });
        setSelectedItemIds(newSelectedIds);
        var newAvailableOptions = _.filter(availableOptions, function (option) { return option.id !== id; });
        setAvailableOptions(newAvailableOptions);
    }, [availableOptions, selectedItemIds]);
    var onDeleteColumnOption = useCallback(function () {
        deleteColumnOption === null || deleteColumnOption === void 0 ? void 0 : deleteColumnOption(availableOptions[editOptionIndex].id);
        deleteSelectedOption(availableOptions[editOptionIndex].id);
        handleEditMenuVisibleChange(editOptionIndex, false);
        handleDropDownVisibleChange(false);
    }, [
        deleteColumnOption,
        availableOptions,
        editOptionIndex,
        deleteSelectedOption,
        handleEditMenuVisibleChange,
        handleDropDownVisibleChange,
    ]);
    var onEditMenuClose = useCallback(function () {
        var editedOption = defaultAvailableOptions.find(function (_a) {
            var id = _a.id;
            return id === availableOptions[editOptionIndex].id;
        });
        // cannot find option, no need to mutate
        if (!editedOption)
            return;
        var mutatedColumnOptionContent = {
            id: availableOptions[editOptionIndex].id,
            text: renameText !== null && renameText !== void 0 ? renameText : availableOptions[editOptionIndex].text, // ?? used to check for equality and prevent empty string after reopening edit menu
            color: availableOptions[editOptionIndex].color,
            userAvatar: null,
        };
        // Mutate if changed
        if (!_.isEqual(editedOption, mutatedColumnOptionContent)) {
            mutateColumnOption === null || mutateColumnOption === void 0 ? void 0 : mutateColumnOption(availableOptions[editOptionIndex].id, renameText, availableOptions[editOptionIndex].color);
        }
    }, [availableOptions, defaultAvailableOptions, editOptionIndex, mutateColumnOption, renameText]);
    var renderEditMenu = useCallback(function (selectedColor) { return (_jsxs(Menu, { className: "selector-menu", onClick: onOptionColorChange, children: [isPlainSelection(schema.type) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "selector-menu-input-wrapper", children: _jsx(DropdownInput, { tagTitle: availableOptions[editOptionIndex]
                                ? availableOptions[editOptionIndex].text
                                : '', onTextChange: onTextChange }) }), _jsx("div", { className: "block-padding-6px" }), _jsxs(Menu.Item, { onClick: onDeleteColumnOption, children: [_jsx(DeleteOutlined, { className: "selector-menu-delete" }), _jsx("span", { className: "selector-menu-delete-text", children: "Delete" })] })] })), _jsx("div", { className: "block-padding-12px" }), _jsx("span", { className: "selector-menu-color-description", onClick: function (e) { return e.stopPropagation(); }, children: "Select a color." }), _jsx("div", { className: "block-padding-8px" }), OPTIONS_COLOR === null || OPTIONS_COLOR === void 0 ? void 0 : OPTIONS_COLOR.map(function (_color, index) { return (_jsx(Menu.Item, { children: colorPickerItem(index, selectedColor) }, OPTIONS_COLOR[index])); })] })); }, [
        availableOptions,
        editOptionIndex,
        onDeleteColumnOption,
        onOptionColorChange,
        onTextChange,
        schema.type,
    ]);
    var onChangeInput = useCallback(function (values, newOption) {
        if (!_.includes(isEditMenuVisible, true)) {
            setNewOptionNameInput('');
            var singleChoiceValue = values.length === 0 ? undefined : values[values.length - 1];
            var selectedIds_1 = isMulti ? values : singleChoiceValue ? [singleChoiceValue] : [];
            if (!isMulti) {
                var newOptions = newOption
                    ? [newOption]
                    : availableOptions.filter(function (option) { return selectedIds_1.includes(option.id); });
                if (!isBooleanType(schema.type) || newOptions.length) {
                    //Update shift payroll status
                    if (isShiftPayrollStatusSchema(schema.nativePath)) {
                        if (!newOptions.length) {
                            return;
                        }
                        var currentPayrollStatus = availableOptions.find(function (option) { return option.id === selectedItemIds[0]; });
                        // Show warning modal when try to update shift payroll status from Paid or Approved to something else
                        if ((currentPayrollStatus === null || currentPayrollStatus === void 0 ? void 0 : currentPayrollStatus.text) === 'Paid' ||
                            (currentPayrollStatus === null || currentPayrollStatus === void 0 ? void 0 : currentPayrollStatus.text) === 'Approved') {
                            setIsShiftPayrollStatusWarningModalVisible(true);
                            setSelectedItemIds(selectedIds_1);
                        }
                        else {
                            editCell(newOptions);
                            handleDropDownVisibleChange(false);
                            setSelectedItemIds(selectedIds_1);
                        }
                    }
                    else {
                        editCell(newOptions);
                        handleDropDownVisibleChange(false);
                        setSelectedItemIds(selectedIds_1);
                    }
                }
            }
            else {
                // For multi selection support inline adding, combine existing options and new input option
                if (newOption) {
                    var selectedOptions = allOptions.filter(function (option) {
                        return selectedIds_1.includes(option.id);
                    });
                    selectedOptions.push(newOption);
                    editCell(selectedOptions);
                }
                setSelectedItemIds(selectedIds_1);
            }
        }
    }, [
        isEditMenuVisible,
        isMulti,
        availableOptions,
        schema.type,
        schema.nativePath,
        selectedItemIds,
        editCell,
        handleDropDownVisibleChange,
        allOptions,
    ]);
    var onSearchInDropdown = useCallback(function (value) {
        if (isBooleanType(schema.type) || isShiftPayrollStatusSchema(schema.nativePath))
            return;
        setNewOptionNameInput(value);
        setShowCantAddMessage(false);
        onSearch === null || onSearch === void 0 ? void 0 : onSearch(value);
    }, [onSearch, schema.nativePath, schema.type]);
    var onFilterOption = useCallback(function (input, option) {
        var search = input.trim().toLowerCase();
        var optionItem = availableOptions.find(function (item) { return item.id === option.value; });
        return optionItem ? optionItem.text.toLowerCase().indexOf(search) > -1 : false;
    }, [availableOptions]);
    // Reset random color index to a new color when done adding new option
    var resetRandomColorIndex = useCallback(function () {
        setRandomColorIndex(generateRandomIndex());
    }, []);
    var addNewOption = useCallback(function () {
        var newOptionId = uuidv4();
        var newOption = {
            id: newOptionId,
            text: newOptionNameInput,
            color: isUserSelecting ? '' : OPTIONS_COLOR[randomColorIndex],
        };
        setAvailableOptions(__spreadArray(__spreadArray([], availableOptions, true), [newOption], false));
        addColumnOption === null || addColumnOption === void 0 ? void 0 : addColumnOption(newOptionId, newOptionNameInput, OPTIONS_COLOR[randomColorIndex]);
        // Add option after creating
        onChangeInput(__spreadArray(__spreadArray([], selectedItemIds, true), [newOptionId], false), newOption);
        resetRandomColorIndex();
    }, [
        newOptionNameInput,
        isUserSelecting,
        randomColorIndex,
        availableOptions,
        addColumnOption,
        onChangeInput,
        selectedItemIds,
        resetRandomColorIndex,
    ]);
    var addNewStandardObjOption = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var newOptionId, newOption;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    newOptionId = uuidv4();
                    newOption = {
                        id: newOptionId,
                        text: newOptionNameInput,
                        color: OPTIONS_COLOR[randomColorIndex],
                    };
                    setAvailableOptions(__spreadArray(__spreadArray([], availableOptions, true), [newOption], false));
                    return [4 /*yield*/, addNewStandardObjInColumnOption((_a = schema.refCollectionId) !== null && _a !== void 0 ? _a : '', schema.id, newOptionId, newOptionNameInput, OPTIONS_COLOR[randomColorIndex])
                        // Add option after creating
                    ];
                case 1:
                    _c.sent();
                    // Add option after creating
                    onChangeInput(__spreadArray(__spreadArray([], selectedItemIds, true), [newOptionId], false), newOption);
                    resetRandomColorIndex();
                    (_b = selectRef.current) === null || _b === void 0 ? void 0 : _b.focus();
                    return [2 /*return*/];
            }
        });
    }); }, [
        newOptionNameInput,
        randomColorIndex,
        availableOptions,
        addNewStandardObjInColumnOption,
        schema.refCollectionId,
        schema.id,
        onChangeInput,
        selectedItemIds,
        resetRandomColorIndex,
    ]);
    var onKeyDown = useCallback(function (e) {
        // Add new option on 'Enter'
        if (e.key === 'Enter') {
            if (isNewOptionName && !areOptionsOnSearch) {
                if (isPlainSelection(schema.type)) {
                    addNewOption();
                }
                // Open add new standard obj confirmation modal
                else if (allowInlineCreateStandardObj(schema.type)) {
                    setIsAddNewObjConfirmationModalVisible(true);
                }
                else {
                    setShowCantAddMessage(true);
                }
            }
        }
    }, [addNewOption, areOptionsOnSearch, isNewOptionName, schema.type]);
    var onMoveOptionUpDown = useCallback(function (optionIndex, isUp) {
        if ((optionIndex === 0 && isUp) ||
            (optionIndex === availableOptions.length - 1 && !isUp)) {
            return;
        }
        var temp = cloneDeep(availableOptions);
        var toIndex = isUp ? optionIndex - 1 : optionIndex + 1;
        var group = temp[optionIndex];
        temp.splice(optionIndex, 1);
        temp.splice(toIndex, 0, group);
        setAvailableOptions(temp);
        reorderColumnOptions === null || reorderColumnOptions === void 0 ? void 0 : reorderColumnOptions(temp.map(function (option) { return option.id; }));
    }, [availableOptions, reorderColumnOptions]);
    var hasDataBuilderAccess = useRecoilValue(ifCurrentUserHasDataBuilderPermission);
    var notFoundContent = useMemo(function () {
        if ((isPlainSelection(schema.type) && hasDataBuilderAccess) ||
            allowInlineCreateStandardObj(schema.type)) {
            return (_jsx("div", { className: "selector-not-found-content-wrapper mb-2", children: newOptionNameInput && (_jsx("div", { className: "selector-not-found-content", children: _jsxs("a", { className: "selector-not-found-content-link", onClick: function () {
                            if (isPlainSelection(schema.type)) {
                                addNewOption();
                            }
                            else {
                                setIsAddNewObjConfirmationModalVisible(true);
                            }
                        }, children: ["Add ", allowInlineCreateStandardObj(schema.type) ? schema.name : '', _jsx("span", { className: "ml-1", style: {
                                    backgroundColor: OPTIONS_COLOR[randomColorIndex],
                                    color: getTagTextColor(OPTIONS_COLOR[randomColorIndex]),
                                }, children: newOptionNameInput })] }) })) }));
        }
        else {
            return _jsx(_Fragment, { children: !isLoading && _jsx("span", { children: "No options found" }) });
        }
    }, [
        addNewOption,
        isLoading,
        newOptionNameInput,
        randomColorIndex,
        schema.name,
        schema.type,
        hasDataBuilderAccess,
    ]);
    var dropdownMenuRender = useCallback(function (menu) { return (_jsxs("div", { children: [_jsxs("div", { className: "selector-dropdown-wrapper", children: ["Select an option", (isPlainSelection(schema.type) ||
                        allowInlineCreateStandardObj(schema.type)) && _jsx(_Fragment, { children: " or create one" }), isMulti && !newOptionNameInput && (_jsxs(Space, { className: "SelectAndClearAllWrapper", size: "large", children: [selectedItemIds.length !== availableOptions.length && (_jsx("span", { className: "SelectAll", onClick: onSelectAll, children: "Select All" })), !!selectedItemIds.length && (_jsx("span", { className: "ClearAll", onClick: onClearAll, children: "Clear All" }))] }))] }), menu, showCantAddMessage && (_jsx("div", { className: "selector-dropdown-wrapper", children: allowCreateUserObj(schema.type)
                    ? "Please click Create New ".concat(schema.name, " if you want to add new record.")
                    : 'You cannot add records to a linked object from the reference. Please add the record to the Data Source first.' })), allowCreateUserObj(schema.type) && (_jsxs("div", { className: "cursor-pointer bg-gray-50 py-2 px-3 font-medium flex items-center text-black", onClick: openNewObjectModal, children: [_jsx(PlusOutlined, { className: "mr-1 text-xs mb-[1px]" }), " Create New ", schema.name] })), isLoading && (_jsxs(Space, { className: "selector-dropdown-wrapper", children: ["Loading...", _jsx(LoadingOutlined, {})] }))] })); }, [
        availableOptions.length,
        isLoading,
        isMulti,
        newOptionNameInput,
        onClearAll,
        onSelectAll,
        openNewObjectModal,
        schema.name,
        schema.type,
        selectedItemIds.length,
        showCantAddMessage,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(Select, { ref: selectRef, placeholder: '', open: true, showSearch: true, showAction: ['focus'], bordered: false, mode: 'multiple', className: "LogicSelectorCellWrapper", dropdownMatchSelectWidth: 400, optionFilterProp: "children", value: isMulti ? selectedItemIds : [selectedItemIds[selectedItemIds.length - 1]], onChange: function (values) { return onChangeInput(values); }, onSearch: onSearchInDropdown, filterOption: onFilterOption, menuItemSelectedIcon: _jsx(_Fragment, {}), tagRender: tagRender, optionLabelProp: "label", notFoundContent: notFoundContent, dropdownRender: dropdownMenuRender, dropdownStyle: { zIndex: 1100 }, onKeyDown: onKeyDown, loading: isLoading, onPopupScroll: onScroll, onBlur: function () {
                    if (isMulti) {
                        if (!isEqual(selectedItemIds, defaultDisplayOptions === null || defaultDisplayOptions === void 0 ? void 0 : defaultDisplayOptions.map(function (_a) {
                            var id = _a.id;
                            return id;
                        }))) {
                            editCell(selectedItems);
                        }
                    }
                }, children: _jsx(_Fragment, { children: allOptions.map(function (option, index) { return (_jsx(Option, { value: option.id, label: option.id, children: _jsxs("div", { className: "selector-option-wrapper", children: [isUserSelecting ? (_jsx(UserTagWithIcon, { name: option.text, avatarProps: { avatar: option.userAvatar }, size: 22 })) : (_jsx("span", { className: "selector-option-text", style: {
                                        backgroundColor: option.color,
                                        color: getTagTextColor(option.color),
                                    }, children: option.text })), isShowPlainSelectionActions && hasDataBuilderAccess && (_jsxs("span", { className: "text-gray-300 float-right", children: [_jsx(UpOutlined, { className: "cursor-pointer hover:text-black pl-1", onClick: function (e) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onMoveOptionUpDown(index, true);
                                            } }), _jsx(DownOutlined, { className: "cursor-pointer hover:text-black pl-1", onClick: function (e) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onMoveOptionUpDown(index, false);
                                            } })] })), _jsx("span", { onClick: function (e) { return e.stopPropagation(); }, onKeyDown: function (e) { return e.stopPropagation(); }, children: isShowPlainSelectionActions && (_jsx(Dropdown, { overlayStyle: { zIndex: 1101 }, trigger: ['click'], overlayClassName: "selector-dropdown-overlay", open: isEditMenuVisible[index], dropdownRender: function () { return renderEditMenu(option.color); }, onOpenChange: function (visible) {
                                            setTimeout(function () {
                                                return handleEditMenuVisibleChange(index, visible);
                                            }, 100);
                                            if (!visible) {
                                                onEditMenuClose();
                                            }
                                        }, children: _jsx("a", { className: "selector-dropdown-more-icon", onClick: function (e) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setEditOptionIndex(index);
                                            }, children: _jsx(EllipsisOutlined, {}) }) })) })] }) }, option.id)); }) }) }), isShiftPayrollStatusSchema(schema.nativePath) &&
                isShiftPayrollStatusWarningModalVisible && (_jsx(WarningModalForShiftPayrollStatusSelector, { onClose: function () {
                    setIsShiftPayrollStatusWarningModalVisible(false);
                    handleDropDownVisibleChange(false);
                }, onConfirm: function () {
                    var newOptions = availableOptions.filter(function (option) { return option.id === selectedItemIds[0]; });
                    editCell(newOptions);
                    handleDropDownVisibleChange(false);
                    setIsShiftPayrollStatusWarningModalVisible(false);
                } })), isAddNewObjConfirmationModalVisible && (_jsx(AddNewStandardObjConfirmationModal, { schemaName: schema.name, collectionId: (_a = schema.refCollectionId) !== null && _a !== void 0 ? _a : '', onClose: function () {
                    var _a;
                    setIsAddNewObjConfirmationModalVisible(false);
                    (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                }, onAddNewObj: addNewStandardObjOption })), _jsx("div", { className: "fpOverlay", onClick: function () {
                    handleDropDownVisibleChange(false);
                }, style: { display: 'block' } })] }));
};
export default React.memo(LogicSelectionCell);
