var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button, Form, Popconfirm, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { useSetting } from '../../../../hooks/api/useSettingApi';
import { IntegrationStepsState } from '../SyncStepsContainer';
import { ZIRA_TITLE } from '../../../../shared';
import { getNativeType } from '../../../../utils/Collection';
import { getIpDisplayText } from '../../../../utils/Account';
import IntegrationHeaderContent from './IntegrationHeaderContent';
var Option = Select.Option;
var SchemaMappingStep = function () {
    var _a;
    var _b = useSetting(), useGetSchemaMappingData = _b.useGetSchemaMappingData, useSaveSchemasMappingResult = _b.useSaveSchemasMappingResult;
    var _c = IntegrationStepsState.useContainer(), isLoading = _c.isLoading, setIsLoading = _c.setIsLoading, goNextStep = _c.goNextStep, selectedIpCollection = _c.selectedIpCollection, selectedZiraCollectionId = _c.selectedZiraCollectionId;
    var ipName = useParams().ipName;
    var formInstance = Form.useForm()[0];
    var schemaMappingData = useGetSchemaMappingData({
        ipCollectionName: selectedIpCollection,
        ziraCollectionId: selectedZiraCollectionId,
    }).data;
    var handleGoNext = useCallback(function () {
        formInstance.submit();
    }, [formInstance]);
    var trimNativeType = useCallback(function (schema) {
        if (schema.inputMode === 'native') {
            return getNativeType(schema.type);
        }
        return schema.type;
    }, []);
    var toSaveSchemaRequestBodyLinks = useCallback(function (mapData, values) {
        if (!mapData) {
            return [];
        }
        var ipSchemas = mapData.ipSchemas;
        return ipSchemas.map(function (schema) {
            return {
                ipSchema: schema,
                ziraSchema: values[schema.displayName] === '' ? null : values[schema.displayName],
            };
        });
    }, []);
    var mutation = useSaveSchemasMappingResult();
    var handleFormSubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, mutation.mutateAsync({
                            collectionId: selectedZiraCollectionId,
                            links: toSaveSchemaRequestBodyLinks(schemaMappingData, values),
                        }, {
                            onSuccess: function () {
                                goNextStep();
                            },
                        })];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [
        goNextStep,
        mutation,
        schemaMappingData,
        selectedZiraCollectionId,
        setIsLoading,
        toSaveSchemaRequestBodyLinks,
    ]);
    function addZiraSchemaToIpSchemas(ipSchemas, predefinedMappings) {
        ipSchemas === null || ipSchemas === void 0 ? void 0 : ipSchemas.forEach(function (ipSchema) {
            var matchingMapping = predefinedMappings === null || predefinedMappings === void 0 ? void 0 : predefinedMappings.find(function (mapping) { return mapping.ipSchema.internalName === ipSchema.internalName; });
            if (matchingMapping) {
                ipSchema.ziraSchema = matchingMapping.ziraSchema;
            }
        });
    }
    if (schemaMappingData) {
        var hasPredefinedMapping = (schemaMappingData === null || schemaMappingData === void 0 ? void 0 : schemaMappingData.predefinedMapping) && ((_a = schemaMappingData === null || schemaMappingData === void 0 ? void 0 : schemaMappingData.predefinedMapping) === null || _a === void 0 ? void 0 : _a.length) > 0;
        if (hasPredefinedMapping) {
            addZiraSchemaToIpSchemas(schemaMappingData === null || schemaMappingData === void 0 ? void 0 : schemaMappingData.ipSchemas, schemaMappingData === null || schemaMappingData === void 0 ? void 0 : schemaMappingData.predefinedMapping);
        }
    }
    return (_jsxs("div", { className: "IntegrationWrapper", children: [_jsx(IntegrationHeaderContent, { children: _jsx("div", { children: _jsx(Popconfirm, { placement: "bottomLeft", title: "Are you sure to go to next step", okText: "Confirm", onConfirm: handleGoNext, children: _jsx(Button, { loading: isLoading, children: "Next" }) }) }) }), _jsxs("div", { className: "SchemaMappingContent", children: [_jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "w-[330px] max-w-1/2", children: getIpDisplayText(ipName) }), _jsx("div", { children: ZIRA_TITLE })] }), _jsx("div", { children: _jsx(Form, { onFinish: handleFormSubmit, form: formInstance, children: schemaMappingData === null || schemaMappingData === void 0 ? void 0 : schemaMappingData.ipSchemas.map(function (schema) { return (_jsx(Form.Item, { name: schema.displayName, colon: false, label: _jsxs("div", { children: [_jsx("span", { children: schema.displayName }), _jsx("span", { className: "IntegrationSchemaType", children: schema.type })] }), initialValue: (schema === null || schema === void 0 ? void 0 : schema.ziraSchema) && (schema === null || schema === void 0 ? void 0 : schema.ziraSchema), children: _jsx(Select, { disabled: isLoading, className: "max-w-full", children: schemaMappingData.ziraSchemas.map(function (schema) { return (_jsxs(Option, { value: schema.id, children: [_jsx("span", { children: schema.name }), _jsx("span", { className: "SchemaMappingOption", children: trimNativeType(schema) })] }, schema.id)); }) }) }, schema.displayName)); }) }) })] })] }));
};
export default React.memo(SchemaMappingStep);
