var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useState, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import CustomCell from 'src/components/Cell/CustomCell';
import { SchemaWithNewValuesType } from 'src/components/RecordDetailModal/RecordDetailModal';
import { ValueDataType } from 'src/shared';
import { useTimeZone } from 'src/hooks/component';
import { getSpecialMutateSchemaProperties, getMutateSchemaProperties } from 'src/services';
import AutomationDynamicVariableSelect from '../BuilderComponents/AutomationDynamicVariableSelect';
import { OffsetType, } from '../Interface';
import { automationBaseCollectionIdState } from '../atom';
import ModifyTiming from '../BuilderComponents/ModifyTiming';
var CreateRecordSchemaRow = function (_a) {
    var _b, _c;
    var schema = _a.schema, index = _a.index, actionObject = _a.actionObject, updateAction = _a.updateAction;
    var detail = actionObject.detail;
    var relativeTimeValue = ((_b = detail.relativeTimeValues) !== null && _b !== void 0 ? _b : []).find(function (r) { return r.schemaId === schema.id; });
    var replaceRelativeTimeValue = function (relativeTimeValue) {
        var _a;
        var newRelativeTimeValues = __spreadArray(__spreadArray([], ((_a = detail.relativeTimeValues) !== null && _a !== void 0 ? _a : []).filter(function (r) { return r.schemaId !== relativeTimeValue.schemaId; }), true), [
            relativeTimeValue,
        ], false);
        updateAction(__assign(__assign({}, actionObject), { detail: __assign(__assign({}, detail), { relativeTimeValues: newRelativeTimeValues }) }));
    };
    var baseCollectionId = useRecoilValue(automationBaseCollectionIdState);
    var _d = useState(detail.dynamicValues &&
        ((_c = detail.dynamicValues) === null || _c === void 0 ? void 0 : _c.findIndex(function (dynamicValue) {
            return schema.id === dynamicValue.schemaId;
        })) !== -1), isDynamicValue = _d[0], setIsDynamicValue = _d[1];
    var schemaContent = {
        id: schema.id,
        name: schema.name,
        inputMode: schema.inputMode,
        type: schema.type,
        nativePath: schema.nativePath,
        refCollectionId: schema.refCollectionId,
        canEdit: true,
        canOnlyUpdateEmpty: false,
        isRequired: false,
        isQuickCreate: false,
    };
    var timeZone = useTimeZone().timeZone;
    var existingSchemaPropIndex = useMemo(function () {
        return detail.inputSchemaProperties.findIndex(function (schemaProperty) {
            return schema.id === schemaProperty.schemaId;
        });
    }, [detail.inputSchemaProperties, schema.id]);
    var existingDynamicValueIndex = useMemo(function () {
        var _a, _b;
        return ((_b = (_a = detail.dynamicValues) === null || _a === void 0 ? void 0 : _a.findIndex(function (dynamicValue) {
            return schema.id === dynamicValue.schemaId;
        })) !== null && _b !== void 0 ? _b : -1);
    }, [detail.dynamicValues, schema.id]);
    var onEditSchemaValue = function (value) {
        var tempActionObj = cloneDeep(actionObject);
        var tempActionDetail = tempActionObj.detail;
        var schemaProperty = schemaContent.type == ValueDataType.FILE
            ? getSpecialMutateSchemaProperties([
                {
                    schema: schemaContent,
                    newValues: value,
                    type: SchemaWithNewValuesType.SPECIAL,
                },
            ])[0]
            : getMutateSchemaProperties([
                {
                    schema: schemaContent,
                    newValues: value,
                    type: SchemaWithNewValuesType.NORMAL,
                },
            ], timeZone)[0];
        var isNonEmptyValue = !!value[0];
        // Add/Replace/Remove new schema property
        if (existingSchemaPropIndex != -1) {
            if (isNonEmptyValue) {
                tempActionDetail.inputSchemaProperties.splice(existingSchemaPropIndex, 1, schemaProperty);
            }
            else {
                tempActionDetail.inputSchemaProperties.splice(existingSchemaPropIndex, 1);
            }
        }
        else if (isNonEmptyValue) {
            tempActionDetail.inputSchemaProperties.push(schemaProperty);
        }
        tempActionObj.detail = tempActionDetail;
        updateAction(tempActionObj);
    };
    var onSelectDynamicValue = function (_val, elem) {
        var tempActionObj = cloneDeep(actionObject);
        var tempActionDetail = tempActionObj.detail;
        var dynamicValue = elem.parentSchemaId
            ? {
                schemaId: schema.id,
                dynamicSchemaId: elem.parentSchemaId,
                dynamicSecondarySchemaId: elem.optionId,
            }
            : {
                schemaId: schema.id,
                dynamicSchemaId: elem.optionId,
                dynamicSecondarySchemaId: null,
            };
        if (!tempActionDetail.dynamicValues) {
            tempActionDetail.dynamicValues = [];
        }
        if (existingDynamicValueIndex !== -1) {
            tempActionDetail.dynamicValues.splice(existingDynamicValueIndex, 1, dynamicValue);
        }
        else {
            tempActionDetail.dynamicValues.push(dynamicValue);
        }
        tempActionObj.detail = tempActionDetail;
        updateAction(tempActionObj);
    };
    var toggleIsDynamicValue = useCallback(function () {
        var tempActionObj = cloneDeep(actionObject);
        var tempActionDetail = tempActionObj.detail;
        if (tempActionDetail.dynamicValues && isDynamicValue && existingDynamicValueIndex !== -1) {
            // Clear dynamic value
            tempActionDetail.dynamicValues.splice(existingDynamicValueIndex, 1);
        }
        else if (!isDynamicValue && existingSchemaPropIndex !== -1) {
            // Clear schema property
            tempActionDetail.inputSchemaProperties.splice(existingSchemaPropIndex, 1);
        }
        tempActionObj.detail = tempActionDetail;
        updateAction(tempActionObj);
        setIsDynamicValue(!isDynamicValue);
    }, [
        actionObject,
        existingDynamicValueIndex,
        existingSchemaPropIndex,
        isDynamicValue,
        updateAction,
    ]);
    var _e = useMemo(function () {
        var defaultCellValue;
        var defaultFileValue;
        detail.inputSchemaProperties.forEach(function (property) {
            var _a, _b;
            if (property.schemaId === schema.id) {
                defaultFileValue = (_a = property.collectionValue) === null || _a === void 0 ? void 0 : _a.files;
                defaultCellValue = property.collectionValue
                    ? ((_b = [
                        property.collectionValue[property.collectionValue.type],
                    ]) !== null && _b !== void 0 ? _b : undefined)
                    : property.uuids
                        ? property.uuids
                        : [
                            property.name ||
                                property.text ||
                                property.number ||
                                property.amount ||
                                property.email ||
                                property.phone ||
                                property.uuid ||
                                (property.timestamp !== undefined
                                    ? String(property.timestamp)
                                    : property.boolean !== undefined
                                        ? String(property.boolean)
                                        : property[property.type]),
                        ];
            }
        });
        return { defaultCellValue: defaultCellValue, defaultFileValue: defaultFileValue };
    }, [detail.inputSchemaProperties, schema.id]), defaultCellValue = _e.defaultCellValue, defaultFileValue = _e.defaultFileValue;
    var cellProps = {
        schema: schemaContent,
        editCell: onEditSchemaValue,
        canEdit: true,
        updateWithDefault: true,
        defaultValue: defaultCellValue,
        defaultFileValues: defaultFileValue,
    };
    var isAutomationDetermined = schema.refCollectionId && schema.refCollectionId == baseCollectionId;
    return (_jsxs("div", { className: "clear-both", children: [_jsxs("div", { className: "schemaSection", children: [_jsx("p", { className: "schemaLabel", children: schema.name }), isDynamicValue ? (_jsx(AutomationDynamicVariableSelect, { value: existingDynamicValueIndex !== -1
                            ? detail.dynamicValues[existingDynamicValueIndex]
                            : undefined, onSelect: onSelectDynamicValue })) : (_jsx("div", { className: "inputValueWrapper", children: isAutomationDetermined || relativeTimeValue ? (_jsx("div", { className: "lockedSchemaInputWrapper", children: _jsx("p", { children: isAutomationDetermined ? "Automation Determined" : "Relative" }) })) : (_jsx(CustomCell, __assign({}, cellProps))) })), !isAutomationDetermined && (_jsx(Button, { onClick: toggleIsDynamicValue, className: "ant-btn ant-btn-primary m-[8px] min-w-[32px]", icon: _jsx(EditOutlined, {}) })), !isAutomationDetermined &&
                        (schema.type === ValueDataType.DATETIME ||
                            schema.type === ValueDataType.NATIVE_DATETIME) && (_jsx(Button, { onClick: function () {
                            var _a, _b, _c;
                            var newAction = __assign(__assign({}, actionObject), { detail: __assign(__assign({}, detail), { inputSchemaProperties: detail.inputSchemaProperties.filter(function (p) { return p.schemaId !== schema.id; }), relativeTimeValues: ((_a = detail.relativeTimeValues) !== null && _a !== void 0 ? _a : []).find(function (r) { return r.schemaId === schema.id; })
                                        ? ((_b = detail.relativeTimeValues) !== null && _b !== void 0 ? _b : []).filter(function (r) { return r.schemaId !== schema.id; })
                                        : __spreadArray(__spreadArray([], ((_c = detail.relativeTimeValues) !== null && _c !== void 0 ? _c : []), true), [
                                            {
                                                schemaId: schema.id,
                                                offset: 0,
                                                offsetType: OffsetType.MINUTES,
                                            },
                                        ], false) }) });
                            updateAction(newAction);
                        }, className: "ant-btn ant-btn-primary m-[8px] min-w-[32px]", icon: _jsx(ClockCircleOutlined, {}) }))] }, 'schemaSection-' + index), relativeTimeValue && (_jsx("div", { className: "float-right mb-2", children: _jsx(ModifyTiming, { triggerOffset: relativeTimeValue.offset, setTriggerOffset: function (triggerOffset) {
                        if (triggerOffset !== null) {
                            replaceRelativeTimeValue(__assign(__assign({}, relativeTimeValue), { offset: triggerOffset }));
                        }
                    }, isOffsetDelayed: null, setIsOffsetDelayed: function () { }, canTriggerBefore: null, offsetType: relativeTimeValue.offsetType, setOffsetType: function (offsetType) {
                        if (offsetType !== null) {
                            replaceRelativeTimeValue(__assign(__assign({}, relativeTimeValue), { offsetType: offsetType }));
                        }
                    }, min: 0, hideClose: true }) }))] }));
};
export default React.memo(CreateRecordSchemaRow);
