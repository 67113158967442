export var ClaimUserAvailability;
(function (ClaimUserAvailability) {
    ClaimUserAvailability["CONFLICTING"] = "CONFLICTING";
    ClaimUserAvailability["AVAILABLE"] = "AVAILABLE";
})(ClaimUserAvailability || (ClaimUserAvailability = {}));
export var AccessGroupType;
(function (AccessGroupType) {
    AccessGroupType["OWNER"] = "owner";
    AccessGroupType["OTHER"] = "other";
})(AccessGroupType || (AccessGroupType = {}));
export function isEntry(item) {
    return item.type == 'ENTRY';
}
export function isDescription(item) {
    return item.type == 'DESCRIPTION';
}
export function isImage(item) {
    return item.type == 'IMAGE';
}
export function isUpdateRecordForm(item) {
    return item.type == 'UPDATE_RECORD';
}
