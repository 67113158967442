import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import ContactEmailModal from './ContactEmailModal';
import ExpertContactItem from './ExpertContactItem';
var EXPERTS = [
    {
        name: 'Morgan Goffen',
        title: 'Account Executive',
        email: 'morgan@zira.ai',
    },
    {
        name: 'Patrick Shunney',
        title: 'Account Executive',
        email: 'patrick@teambridge.com',
    },
    {
        name: 'Robert Moore',
        title: 'Account Solution Engineer',
        email: 'rob@teambridge.com',
    },
];
var SUPPORT_CONTACT_INFO = { name: 'TeamBridge Support', title: 'Support Team' };
var ExpertTeamContactWidget = function () {
    var _a = useState(), emailContactModalInfo = _a[0], setEmailContactModalInfo = _a[1];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "ExpertTeamContactWidgetContainer w-full rounded-[15px] bg-white shadow-block", children: [_jsx("div", { className: "mb-5 flex items-center text-xl font-medium", children: "Your Expert Team" }), EXPERTS.map(function (expert) { return (_jsx(ExpertContactItem, { info: expert, setEmailContactModalInfo: function () { return setEmailContactModalInfo(expert); } }, expert.email)); }), _jsx(ExpertContactItem, { info: SUPPORT_CONTACT_INFO })] }), _jsx(ContactEmailModal, { contactInfo: emailContactModalInfo, onClose: function () { return setEmailContactModalInfo(undefined); } })] }));
};
export default React.memo(ExpertTeamContactWidget);
