var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Select } from 'antd';
import { useAccountApi } from 'src/hooks/api';
import { IpName } from 'src/shared';
import { TaskTemplateType } from '../interface';
import { TASK_TYPE_OPTIONS } from '../constant';
var SelectTaskDetailType = function (_a) {
    var updateTaskType = _a.updateTaskType, taskDetail = _a.taskDetail;
    var useGetAccountDocSigningTemplatesListQuery = useAccountApi().useGetAccountDocSigningTemplatesListQuery;
    var signingTemplates = useGetAccountDocSigningTemplatesListQuery({
        enabled: taskDetail !== undefined,
    }).data;
    var docusignTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.DOCUSIGN; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var signNowTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.SIGNNOW; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var taskTypeOptions = useMemo(function () {
        if (docusignTemplates && docusignTemplates.length > 0) {
            return __spreadArray(__spreadArray([], TASK_TYPE_OPTIONS, true), [
                {
                    value: TaskTemplateType.SIGNING_PARTNER,
                    name: 'Docusign',
                },
            ], false);
        }
        else if (signNowTemplates && signNowTemplates.length > 0) {
            return __spreadArray(__spreadArray([], TASK_TYPE_OPTIONS, true), [
                {
                    value: TaskTemplateType.SIGNING_PARTNER,
                    name: 'SignNow',
                },
            ], false);
        }
        else {
            return TASK_TYPE_OPTIONS;
        }
    }, [docusignTemplates, signNowTemplates]);
    return (_jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel", children: "Select Task Type" }), _jsx(Select, { className: "TaskDetailModalSelector", placeholder: "Task Type", value: taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type, onChange: function (type) { return updateTaskType(type); }, showSearch: true, children: taskTypeOptions.map(function (type, index) { return (_jsx(Select.Option, { value: type.value, children: type.name }, index)); }) })] }));
};
export default React.memo(SelectTaskDetailType);
