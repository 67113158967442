import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import ComponentWithUpload, { UploadFileType } from 'src/components/ComponentWithUpload';
import { UploadUrlType } from 'src/hooks/api';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useFileApi } from 'src/hooks/api/useFileApi';
import { uploadedDefaultRateFileState } from 'src/components/Settings/PayRate/atoms';
import { CsvFileIcon } from '../utils/icons/CsvFileIcon';
import { FileUploadIcon } from '../utils/icons/FileUploadIcon';
var UploadDefaultRateFileSection = function () {
    var handleDeleteFile = useFileApi().deleteFile;
    var _a = useRecoilState(uploadedDefaultRateFileState), uploadedFile = _a[0], setUploadedFile = _a[1];
    var acceptedFileType = useMemo(function () { return [UploadFileType.CSV]; }, []);
    var onUploadSucceed = useCallback(function (info) {
        var url = info.file.response.data.url;
        if (url) {
            setUploadedFile({
                name: info.file.name,
                type: info.file.type,
                url: url,
            });
        }
    }, [setUploadedFile]);
    var removeFile = useCallback(function () {
        if (uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.url) {
            handleDeleteFile(uploadedFile.url);
            setUploadedFile(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFile]);
    var replaceFile = useCallback(function (info) {
        var newUrl = info.file.response.data.url;
        var prevUrl = uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.url;
        // Set new file info for the new uploaded file
        if (newUrl) {
            setUploadedFile({
                name: info.file.name,
                type: info.file.type,
                url: newUrl,
            });
        }
        // Delete the previous file url
        if (prevUrl) {
            handleDeleteFile(prevUrl);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.url, setUploadedFile]);
    var renderUploadedFileInfo = useMemo(function () { return (_jsxs("div", { children: [_jsxs("div", { className: "flex items-center gap-2", children: [_jsx(CsvFileIcon, { width: '', height: '', fill: '' }), _jsx("div", { className: "font-medium", children: uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.name })] }), _jsxs("div", { className: "flex gap-4 text-[#818A9B] font-semibold text-sm mt-2 text-center", children: [_jsx(ComponentWithUpload, { acceptedFileType: acceptedFileType, successAction: replaceFile, type: UploadUrlType.Import, children: _jsxs("div", { className: "cursor-pointer flex text-[#818A9B] h-[25px] items-center", children: [_jsx(FileUploadIcon, { width: '', height: '', fill: '', className: "mr-1" }), "Replace File"] }) }), _jsxs("div", { className: "cursor-pointer flex items-center", onClick: removeFile, children: [_jsx(CloseOutlined, { className: "mr-1" }), "Remove"] })] })] })); }, [uploadedFile, acceptedFileType, replaceFile, removeFile]);
    return (_jsxs("div", { children: [_jsx("div", { className: "font-semibold text-gray-300 mb-2", children: "Please refer to the sample csv template and make sure the columns exactly match the format." }), uploadedFile ? (renderUploadedFileInfo) : (_jsx(ComponentWithUpload, { acceptedFileType: acceptedFileType, successAction: onUploadSucceed, type: UploadUrlType.Import, children: _jsxs("div", { className: "bg-gray-40 border border-dashed border-gray-100 rounded-lg text-center py-10 font-medium", children: [_jsx(FileUploadIcon, { width: '', height: '', fill: '' }), _jsx("div", { children: "Click here or drag .csv file to upload" })] }) })), _jsxs("div", { className: "text-red-500 bg-red-50 mt-5 rounded-lg p-3 font-medium flex items-start gap-2", children: [_jsx(InfoCircleOutlined, { style: {
                            color: '#B3342F',
                            stroke: '#B3342F',
                            fontSize: '18px',
                        }, size: 24 }), _jsxs("div", { children: [_jsx("span", { className: "font-medium", children: "Warning" }), _jsx("br", {}), " The new rates will take effect once the file is verified. You cannot go back to the old pay rates once the columns are synced."] })] })] }));
};
export default React.memo(UploadDefaultRateFileSection);
