import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RollupConfigurationContext } from 'src/components/RollupConfigurationModal/RollupConfigurationModal';
import { useContext, useState } from 'react';
import { useAccountApi } from 'src/hooks/api';
import { NativePath, ValueDataType } from 'src/shared';
import { Select } from 'antd';
var NumberSchemaSelector = function () {
    var _a = useContext(RollupConfigurationContext), rollupConfiguration = _a.rollupConfiguration, setSumNumberSchema = _a.setSumNumberSchema;
    var refCollectionId = rollupConfiguration.refCollectionId;
    var useGetSchemasInCollection = useAccountApi().useGetSchemasInCollection;
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    var numberTypes = [
        ValueDataType.NUMBER,
        ValueDataType.NATIVE_NUMBER,
        ValueDataType.NATIVE_DURATION,
    ];
    //These are not supported in the BE
    var nativePaths = [
        NativePath.SHIFT_REGULAR_HOURS,
        NativePath.SHIFT_REGULAR_PAY,
        NativePath.SHIFT_SINGLE_OVERTIME_HOURS,
        NativePath.SHIFT_DOUBLE_OVERTIME_HOURS,
        NativePath.SHIFT_DOUBLE_OVERTIME_PAY,
        NativePath.SHIFT_TOTAL_PAY,
        NativePath.SHIFT_TOTAL_BILL,
    ];
    var isFetching = useGetSchemasInCollection({
        collectionId: refCollectionId !== null && refCollectionId !== void 0 ? refCollectionId : '',
        options: {
            enabled: !!refCollectionId,
            onSuccess: function (data) {
                var tempOptions = (data === null || data === void 0 ? void 0 : data.filter(function (column) { return numberTypes.includes(column.type); }).filter(function (column) {
                    return !column.nativePath || !nativePaths.includes(column.nativePath);
                }).map(function (columns) { return ({
                    label: columns.name,
                    value: columns.id,
                }); })) || [];
                setOptions(tempOptions);
                if (rollupConfiguration.sumNumberSchemaId === undefined) {
                    setSumNumberSchema(tempOptions === null || tempOptions === void 0 ? void 0 : tempOptions[0].value);
                }
            },
        },
    }).isFetching;
    return (_jsxs("div", { children: [_jsx("div", { className: "font-medium text-gray-400 text-xs", children: "Number to Sum" }), _jsx(Select, { labelInValue: true, disabled: isFetching || options.length === 0, options: options, onSelect: function (item) {
                    var value = item.value;
                    setSumNumberSchema(value);
                }, value: rollupConfiguration.sumNumberSchemaId
                    ? options.find(function (_a) {
                        var value = _a.value;
                        return value === rollupConfiguration.sumNumberSchemaId;
                    })
                    : undefined, className: "w-[161px]" })] }));
};
export default NumberSchemaSelector;
