var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useFetch } from 'src/hooks/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { appSessionState, collectionTableSchemaNativePathMapSelector } from 'src/state';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { CollectionType } from 'src/shared';
import { useCallback } from 'react';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { PAYMENT_RULES_TAB } from 'src/components/FeatureGatingKeyConstant';
import { constructCreateUpdatePayItemRequest } from './util';
var usePayRateV2Api = function () {
    var _a;
    var customFetch = useFetch().request;
    var accountId = ((_a = useRecoilValue(appSessionState)) !== null && _a !== void 0 ? _a : {}).accountId;
    var shiftCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.SHIFT));
    var queryClient = useQueryClient();
    var isPaymentRulesTabOn = useRecoilValue(featureEnableStatusState(PAYMENT_RULES_TAB));
    var invalidateQueryCacheForDefaultRates = useCallback(function () {
        queryClient.invalidateQueries({ queryKey: ['DefaultRates', accountId] });
    }, [accountId, queryClient]);
    var invalidateQueryCacheForPayRules = useCallback(function () {
        queryClient.invalidateQueries({ queryKey: ['PayRules', accountId] });
    }, [accountId, queryClient]);
    var nativeSchema = useRecoilValue(collectionTableSchemaNativePathMapSelector(shiftCollectionId || ''));
    var payRate = nativeSchema['shift.wage'];
    var billRate = nativeSchema['shift.bill_rate'];
    var useGetPayRulesForAccount = function () {
        return useQuery(['PayRules', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'pay_rules',
            });
        }, {
            enabled: !!accountId && isPaymentRulesTabOn,
        });
    };
    var deletePayRule = useMutation(function (request) {
        return customFetch({
            method: 'POST',
            url: 'pay_rules/delete',
        }, {
            accountId: accountId,
            payRuleId: request,
        });
    }, {
        onSuccess: invalidateQueryCacheForPayRules,
    });
    var updatePayRulesOrder = useMutation(function (request) {
        return customFetch({
            method: 'POST',
            url: 'pay_rules/ordering',
        }, {
            accountId: accountId,
            payRuleIds: request || [],
        });
    }, {
        onSuccess: invalidateQueryCacheForPayRules,
    });
    var createPayRules = useMutation(function (data) {
        var request = constructCreateUpdatePayItemRequest(data, shiftCollectionId, accountId, payRate, billRate);
        return customFetch({
            method: 'POST',
            url: "pay_rules/create",
        }, request);
    }, {
        onSuccess: invalidateQueryCacheForPayRules,
    });
    var updatePayRules = useMutation(function (data) {
        var payRuleInfo = data.info;
        var request = constructCreateUpdatePayItemRequest(data, shiftCollectionId, accountId, payRate, billRate);
        return customFetch({
            method: 'POST',
            url: "pay_rules/update",
        }, {
            info: __assign({}, payRuleInfo),
            conditions: request.conditions,
            adjustments: request.adjustments,
        });
    }, {
        onSuccess: invalidateQueryCacheForPayRules,
    });
    var useBreakdownPayRules = function (isShift, shiftRid) {
        return useQuery(['PayRulesBreakdown', shiftRid], function () {
            return customFetch({
                method: 'POST',
                url: 'pay_rules/get_calculated_breakdowns_for_shift',
            }, {
                shiftRid: shiftRid,
            });
        }, {
            enabled: isShift && Boolean(shiftRid) && isPaymentRulesTabOn,
        });
    };
    var useGetShiftPaySchemas = function () {
        return useQuery(['ShiftPaySchemas'], function () {
            return customFetch({
                method: 'GET',
                url: 'pay_rules/shift_pay_schemas',
            });
        }, {
            enabled: !!accountId && isPaymentRulesTabOn,
        });
    };
    var useGetDefaultRatesForAccount = function () {
        return useQuery(['DefaultRates', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'default_rate',
            });
        }, {
            enabled: !!accountId,
        });
    };
    var useGetDefaultRatesForIndividualUser = function (recordId) {
        return useQuery(['IndividualUserRates', recordId], function () {
            return customFetch({
                method: 'GET',
                url: "default_rate/get_default_rates_per_user/".concat(recordId),
            });
        }, {
            enabled: !!accountId && !!recordId,
        });
    };
    var deleteDefaultRate = useMutation(function (request) {
        return customFetch({
            method: 'POST',
            url: 'pay_rules/delete',
        }, {
            accountId: accountId,
            payRuleId: request,
        });
    }, {
        onSuccess: invalidateQueryCacheForDefaultRates,
    });
    var useCreateDefaultRate = useMutation(function (data) {
        var request = constructCreateUpdatePayItemRequest(data, shiftCollectionId, accountId, payRate, billRate);
        return customFetch({
            method: 'POST',
            url: 'default_rate/create',
        }, request);
    }, {
        onSuccess: invalidateQueryCacheForDefaultRates,
    });
    var updateDefaultRate = useMutation(function (data) {
        var info = data.info;
        var request = constructCreateUpdatePayItemRequest(data, shiftCollectionId, accountId, payRate, billRate);
        return customFetch({
            method: 'POST',
            url: "default_rate/update",
        }, {
            info: __assign({}, info),
            conditions: request.conditions,
            adjustments: request.adjustments,
        });
    }, {
        onSuccess: invalidateQueryCacheForDefaultRates,
    });
    var useUpdateShiftPayManually = useMutation(function (request) {
        return customFetch({
            method: 'POST',
            url: "default_rate/record_manual_updates_for_shift",
        }, request);
    });
    var useRematchShifts = useMutation(function (request) {
        return customFetch({
            method: 'PUT',
            url: 'pay_rules/rematch_shifts',
        }, request);
    });
    var exportDefaultRateTemplateFile = useMutation(function () {
        return customFetch({
            method: 'POST',
            url: 'export/export_default_rate_template',
        });
    });
    return {
        useGetPayRulesForAccount: useGetPayRulesForAccount,
        useBreakdownPayRules: useBreakdownPayRules,
        useGetShiftPaySchemas: useGetShiftPaySchemas,
        createPayRulesMutation: createPayRules,
        updatePayRulesMutation: updatePayRules,
        updatePayRulesOrder: updatePayRulesOrder,
        deletePayRule: deletePayRule,
        useGetDefaultRatesForAccount: useGetDefaultRatesForAccount,
        useGetDefaultRatesForIndividualUser: useGetDefaultRatesForIndividualUser,
        useCreateDefaultRate: useCreateDefaultRate,
        updateDefaultRateMutation: updateDefaultRate,
        deleteDefaultRate: deleteDefaultRate,
        useUpdateShiftPayManually: useUpdateShiftPayManually,
        useRematchShifts: useRematchShifts,
        exportDefaultRateTemplateFile: exportDefaultRateTemplateFile,
    };
};
export default usePayRateV2Api;
