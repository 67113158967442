import { jsx as _jsx } from "react/jsx-runtime";
import { Dropdown, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { cloneDeep, hasIn } from 'lodash';
import React from 'react';
import { getSubMenuTitle, } from 'src/components/OptimizedTableView/Column/TableHeaderDropdown';
import MenuGroupTitle from 'src/components/SharedComponent/DropdownMenu/MenuGroupTitle';
import MenuTitleWithIcon from 'src/components/SharedComponent/DropdownMenu/MenuTitleWithIcon';
import SchemaTypeMenuItemGroups from 'src/components/SharedComponent/SchemaTypeMenuDropdown/SchemaTypeMenuItemGroups';
import { CellInputMode, SubMenuContentIcon, ValueDataType } from 'src/shared';
import { v4 as uuidv4 } from 'uuid';
import { LINK_TO_OBJECT_EMPTY_ID } from '../constant';
var SchemaTypeSelector = function (_a) {
    var column = _a.column, setColumn = _a.setColumn, columnTypeKey = _a.columnTypeKey, setNewSelectSchemaType = _a.setNewSelectSchemaType, menuPopupContainerRef = _a.menuPopupContainerRef, showColumnType = _a.showColumnType;
    return (_jsx(Dropdown, { overlay: _jsx(Menu, { className: "DropdownMenu shadow-none mr-4 w-full", triggerSubMenuAction: "click", onClick: function (info) {
                info.domEvent.stopPropagation();
                var menuKey = info.keyPath[0];
                setNewSelectSchemaType(menuKey);
                if (menuKey === 'rollup')
                    return;
                if (hasIn(SubMenuContentIcon, menuKey)) {
                    var temp = cloneDeep(column);
                    temp.refCollectionId = undefined;
                    temp.refGroupId = undefined;
                    temp.formulaId = undefined;
                    temp.rollupId = undefined;
                    temp.rollupView = undefined;
                    if (menuKey === 'multi' || menuKey === 'single') {
                        temp.inputMode = menuKey;
                        temp.type = ValueDataType.PLAIN_SELECTION;
                    }
                    else if (menuKey === 'formula') {
                        temp.inputMode = CellInputMode.GENERATED;
                        temp.type = menuKey;
                        temp.formulaId = uuidv4();
                    }
                    else if (menuKey === 'generative') {
                        temp.inputMode = CellInputMode.USER_DEFINED_GENERATED;
                        temp.type = ValueDataType.GENERATED_ID;
                    }
                    else if (menuKey === 'linkToObj') {
                        temp.inputMode = CellInputMode.MULTI;
                        temp.type = ValueDataType.TEXT;
                        temp.refCollectionId = LINK_TO_OBJECT_EMPTY_ID;
                    }
                    else if (menuKey === 'linkToSchema') {
                        temp.inputMode = column.refGroupId
                            ? column.inputMode
                            : menuKey;
                        temp.type = ValueDataType.PLAIN_SELECTION;
                    }
                    else {
                        temp.inputMode = CellInputMode.PLAIN;
                        temp.type = menuKey;
                    }
                    setColumn(temp);
                }
            }, children: _jsx(Menu.ItemGroup, { title: _jsx("div", { className: "-mr-2", children: _jsx(MenuGroupTitle, { text: "Type" }) }), children: _jsx(SubMenu, { popupClassName: "DropdownSubMenu z-[1110]", title: _jsx(MenuTitleWithIcon, { icon: SubMenuContentIcon[columnTypeKey], text: getSubMenuTitle(columnTypeKey) }), children: _jsx(SchemaTypeMenuItemGroups, { onSchemaConfigModalOpen: function () { } }) }, "ColumnType") }) }), overlayStyle: {
            zIndex: 1101,
        }, getPopupContainer: function () { return menuPopupContainerRef.current; }, open: showColumnType, children: _jsx("div", {}) }));
};
export default React.memo(SchemaTypeSelector);
