import { jsx as _jsx } from "react/jsx-runtime";
import { CalendarOutlined, ClockCircleOutlined, EditOutlined, MailOutlined, BellOutlined, MessageOutlined, } from '@ant-design/icons';
import { ActionType, AutomationTriggerRecurringType, TriggerType, } from './Interface';
export var TASK_TYPES = [
    ActionType.ASSIGN_TASK,
    TriggerType.TASK_ASSIGNED,
    TriggerType.TASK_COMPLETED,
];
export var TASK_GROUP_TYPES = [
    ActionType.ASSIGN_TASK_GROUP,
    TriggerType.TASK_GROUP_ASSIGNED,
    TriggerType.TASK_GROUP_COMPLETED,
];
export var TEXT_INPUT_VALUE_TYPES = ['text', 'phone', 'number', 'metric', 'currency', 'amount'];
export var POPULAR_TRIGGERS = [
    {
        displayName: 'Shift scheduled to start',
        value: TriggerType.SCHEDULED_SHIFT_START,
        color: '#eaedfc',
        icon: _jsx(CalendarOutlined, {}),
    },
    {
        displayName: 'User clocks in to shift',
        value: TriggerType.CLOCK_IN,
        color: '#f5efec',
        icon: _jsx(ClockCircleOutlined, {}),
    },
    {
        displayName: 'Something is updated',
        value: TriggerType.OBJECT_UPDATED,
        color: '#e8f2fc',
        icon: _jsx(EditOutlined, {}),
    },
    {
        displayName: 'Shift scheduled to end',
        value: TriggerType.SCHEDULED_SHIFT_END,
        color: '#eaedfc',
        icon: _jsx(CalendarOutlined, {}),
    },
    {
        displayName: 'User clocks out of shift',
        value: TriggerType.CLOCK_OUT,
        color: '#f5efec',
        icon: _jsx(ClockCircleOutlined, {}),
    },
    {
        displayName: 'Something is created',
        value: TriggerType.OBJECT_CREATED,
        color: '#e8f2fc',
        icon: _jsx(EditOutlined, {}),
    },
];
export var POPULAR_ACTIONS = [
    {
        displayName: 'Send email',
        value: ActionType.SEND_EMAIL,
        color: '#ffecc8',
        icon: _jsx(MailOutlined, {}),
    },
    {
        displayName: 'Send feed message',
        value: ActionType.SEND_IN_APP,
        color: '#dad6f3',
        icon: _jsx(BellOutlined, {}),
    },
    {
        displayName: 'Clock in',
        value: ActionType.CLOCK_IN,
        color: '#f5efec',
        icon: _jsx(ClockCircleOutlined, {}),
    },
    {
        displayName: 'Send SMS',
        value: ActionType.SEND_SMS,
        color: '#e5f4ef',
        icon: _jsx(MessageOutlined, {}),
    },
    {
        displayName: 'Modify something',
        value: ActionType.MODIFY_OBJECT,
        color: '#e8f2fc',
        icon: _jsx(EditOutlined, {}),
    },
    {
        displayName: 'Clock out',
        value: ActionType.CLOCK_OUT,
        color: '#f5efec',
        icon: _jsx(ClockCircleOutlined, {}),
    },
];
export var MODIFY_OBJECT_MODIFERS = [
    {
        title: 'set to',
        value: 'REPLACE',
    },
    {
        title: 'append with',
        value: 'APPEND',
    },
    {
        title: 'remove',
        value: 'REMOVE',
    },
    {
        title: 'increment',
        value: 'INCREMENT',
    },
    {
        title: 'decrement',
        value: 'DECREMENT',
    },
];
export var RECURRING_TRIGGER_SUPPORT_ACTION_TYPE_SET = new Set([
    ActionType.SEND_EMAIL,
    ActionType.SEND_IN_APP,
    ActionType.SEND_SMS,
    ActionType.SEND_REPORT,
    ActionType.CREATE_RECORD,
]);
export var DAYS_OF_WEEK_OPTIONS = [
    { key: 'mon', value: 'MONDAY', text: 'Mon' },
    { key: 'tue', value: 'TUESDAY', text: 'Tue' },
    { key: 'wed', value: 'WEDNESDAY', text: 'Wed' },
    { key: 'thu', value: 'THURSDAY', text: 'Thu' },
    { key: 'fri', value: 'FRIDAY', text: 'Fri' },
    { key: 'sat', value: 'SATURDAY', text: 'Sat' },
    { key: 'sun', value: 'SUNDAY', text: 'Sun' },
];
export var AUTOMATION_TRIGGER_RECURRING_DETAILS_DEFAULT = {
    type: AutomationTriggerRecurringType.DAILY,
    timeOfDay: '08:00:00',
};
