var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import moment from 'moment';
import { SchemaWithNewValuesType, } from 'src/components/RecordDetailModal/RecordDetailModal';
import { RecordSchemaType, CellInputMode, ValueDataType, selectionStandardTypes, NativePath, } from 'src/shared';
import { getNativePathKey, getNativeType, getKeyForValueDataType } from 'src/utils/Collection';
import { getAccountCurrencySymbol } from 'src/utils/Account';
var getDisplayedValueData = function (valueData) {
    if (valueData === undefined)
        return undefined;
    var displayedData = valueData[valueData.type];
    var structuredData;
    // more complex data
    if (displayedData === undefined) {
        switch (valueData.type) {
            case ValueDataType.CURRENCY: {
                displayedData = valueData['amount'];
                break;
            }
            case ValueDataType.GEOFENCE: {
                structuredData = {
                    lat: Number(valueData['lat']),
                    lng: Number(valueData['lng']),
                    radius: Number(valueData['radius']),
                };
                break;
            }
            case ValueDataType.DATETIME_RANGE: {
                structuredData = {
                    type: valueData.type,
                    showTime: valueData['showTime'],
                    from: valueData['from'],
                    to: valueData['to'],
                };
                break;
            }
            case ValueDataType.DATETIME: {
                structuredData = {
                    type: valueData.type,
                    showTime: valueData['showTime'],
                    dateTime: valueData['datetime'],
                };
                break;
            }
        }
    }
    return structuredData || displayedData;
};
var getSpecialMutateSchemaProperties = function (schemasToMutate) {
    var _a;
    var schemaProperties = [];
    if (schemasToMutate.length == 0)
        return schemaProperties;
    var _b = schemasToMutate[0], schema = _b.schema, newValues = _b.newValues;
    var mutatedNewValue;
    var typeKey;
    if (schema.inputMode === CellInputMode.PLAIN || schema.type === ValueDataType.FILE) {
        typeKey = RecordSchemaType.COLLECTION_VALUE;
    }
    if (schema.type === ValueDataType.FILE) {
        mutatedNewValue = {
            type: schema.type,
            files: newValues,
            inputMode: schema.inputMode,
        };
    }
    if (typeKey) {
        var schemaProperty = (_a = {
                schemaId: schema.id,
                type: typeKey
            },
            _a[typeKey] = mutatedNewValue,
            _a);
        schemaProperties.push(schemaProperty);
    }
    return schemaProperties;
};
var getMutateSchemaProperties = function (schemasToMutate, timezone) {
    var _a, _b, _c;
    var schemaProperties = [];
    for (var _i = 0, schemasToMutate_1 = schemasToMutate; _i < schemasToMutate_1.length; _i++) {
        var schemaWithValues = schemasToMutate_1[_i];
        var schema = schemaWithValues.schema, newValues = schemaWithValues.newValues;
        var newValue = newValues[0] ? newValues[0] : null;
        var typeKey = void 0;
        if (schema.inputMode === CellInputMode.NATIVE) {
            typeKey = getNativePathKey(schema.type);
            var schemaType = schema.type;
            //TODO: handle more special cases here
            if (typeKey === RecordSchemaType.TIMESTAMP) {
                if (schema.nativePath === NativePath.USER_DOB) {
                    typeKey = RecordSchemaType.LOCAL_DATE;
                    newValue = newValue ? moment(newValue).format('YYYY-MM-DD') : newValue;
                }
                else {
                    newValue =
                        timezone &&
                            moment(moment.tz(newValue, 'MMM D, YYYY h:mm A', timezone)).isValid()
                            ? moment
                                .tz(newValue, 'MMM D, YYYY h:mm A', timezone)
                                .toISOString()
                            : moment(newValue).toISOString();
                }
            }
            var type = getNativeType(schemaType);
            if (selectionStandardTypes.has(type)) {
                if (type === 'role' ||
                    (type === 'location' &&
                        schema.nativePath !== NativePath.SHIFT_LOCATION_ID &&
                        schema.nativePath !== NativePath.JOB_LOCATION_ID &&
                        schema.nativePath !== NativePath.PLACEMENT_LOCATION_RID) ||
                    type === 'access_group') {
                    typeKey = RecordSchemaType.UUIDS;
                    newValue = newValues;
                }
                else {
                    typeKey = RecordSchemaType.UUID;
                    newValue = newValues[0];
                }
            }
        }
        else if (schema.inputMode === CellInputMode.MULTI) {
            typeKey = RecordSchemaType.UUIDS;
            newValue = newValues;
        }
        else if (schema.inputMode === CellInputMode.SINGLE) {
            typeKey = RecordSchemaType.UUID;
            newValue = newValues[0];
        }
        else if (schema.inputMode === CellInputMode.PLAIN) {
            typeKey = RecordSchemaType.COLLECTION_VALUE;
            var schemaKey = getKeyForValueDataType(schema.type);
            if (schema.type === ValueDataType.DATETIME_RANGE) {
                newValue = {
                    type: schema.type,
                    from: moment(newValues[0]).toISOString(),
                    to: moment(newValues[1]).toISOString(),
                    inputMode: schema.inputMode,
                };
            }
            else if (schema.type === ValueDataType.DATETIME) {
                newValue = (_a = {
                        type: schema.type
                    },
                    _a[schemaKey] = timezone &&
                        moment(moment.tz(newValue, 'MMM D, YYYY h:mm A', timezone)).isValid()
                        ? moment
                            .tz(newValue, 'MMM D, YYYY h:mm A', timezone)
                            .toISOString()
                        : moment(newValue).toISOString(),
                    _a.inputMode = schema.inputMode,
                    _a);
            }
            else {
                newValue = (_b = {
                        type: schema.type
                    },
                    _b[schemaKey] = schema.type === ValueDataType.CURRENCY && typeof newValue === 'string'
                        ? newValue.replaceAll(getAccountCurrencySymbol(), '')
                        : newValue,
                    _b.inputMode = schema.inputMode,
                    _b);
            }
        }
        if (typeKey) {
            var tempProperty = (_c = {
                    schemaId: schema.id,
                    type: typeKey
                },
                _c[typeKey] = schema.type === ValueDataType.NATIVE_CURRENCY && typeof newValue === 'string'
                    ? newValue.replaceAll(getAccountCurrencySymbol(), '')
                    : newValue,
                _c);
            schemaProperties.push(tempProperty);
        }
    }
    return schemaProperties;
};
var getMutateAllSchemaProperties = function (allSchemasToMutate, timezone) {
    var specialValues = allSchemasToMutate.filter(function (item) { return item.type === SchemaWithNewValuesType.SPECIAL; });
    var specialSchemaProperties = getSpecialMutateSchemaProperties(specialValues);
    var normalValues = allSchemasToMutate.filter(function (item) { return item.type === SchemaWithNewValuesType.NORMAL; });
    var normalSchemaProperties = getMutateSchemaProperties(normalValues, timezone);
    return __spreadArray(__spreadArray([], specialSchemaProperties, true), normalSchemaProperties, true);
};
export { getDisplayedValueData, getSpecialMutateSchemaProperties, getMutateSchemaProperties, getMutateAllSchemaProperties, };
