import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Input, Select } from 'antd';
import { useAccountApi } from 'src/hooks/api';
import { IpName } from 'src/shared';
var SigningPartnerTaskSettingSection = function (_a) {
    var collectionId = _a.collectionId, fileSchemaId = _a.fileSchemaId, templateId = _a.templateId, buttonLabel = _a.buttonLabel, signingPartner = _a.signingPartner, updateSigningPartnerTask = _a.updateSigningPartnerTask;
    var _b = useAccountApi(), useGetAccountCollectionOptionsQuery = _b.useGetAccountCollectionOptionsQuery, useGetAccountDocSigningTemplatesListQuery = _b.useGetAccountDocSigningTemplatesListQuery, useGetSchemaColumnsInCollection = _b.useGetSchemaColumnsInCollection;
    var allCollections = useGetAccountCollectionOptionsQuery().data;
    var signingTemplates = useGetAccountDocSigningTemplatesListQuery({
        enabled: collectionId !== undefined,
    }).data;
    var docusignTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.DOCUSIGN; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var signNowTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.SIGNNOW; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var schemas = useGetSchemaColumnsInCollection({
        collectionId: collectionId !== null && collectionId !== void 0 ? collectionId : '',
        options: {
            enabled: !!collectionId,
        },
    }).data;
    var fileSchemas = useMemo(function () {
        return schemas === null || schemas === void 0 ? void 0 : schemas.columns.filter(function (schema) {
            return schema.type === 'file';
        });
    }, [schemas]);
    var templates = docusignTemplates && docusignTemplates.length ? docusignTemplates : signNowTemplates;
    var templateOptions = templates === null || templates === void 0 ? void 0 : templates.map(function (template) {
        return template.templateName && (_jsx(Select.Option, { value: template.templateId, children: template.templateName }, template.templateId));
    });
    var ipName = docusignTemplates && docusignTemplates.length ? IpName.DOCUSIGN : IpName.SIGNNOW;
    return (_jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Collection" }), _jsx(Select, { className: "TaskDetailModalSelector", placeholder: "Select a Collection", value: collectionId || null, onChange: function (value) {
                    updateSigningPartnerTask({
                        collectionId: value,
                        templateId: null,
                        fileSchemaId: null,
                        buttonLabel: buttonLabel,
                        signingPartner: ipName,
                    });
                }, showSearch: true, children: allCollections === null || allCollections === void 0 ? void 0 : allCollections.map(function (collection) { return (_jsx(Select.Option, { value: collection.id, children: collection.name }, collection.id)); }) }), collectionId && (_jsxs(_Fragment, { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Field" }), _jsx(Select, { className: "TaskDetailModalSelector", placeholder: "Select a Field", value: fileSchemaId, onChange: function (value) {
                            updateSigningPartnerTask({
                                collectionId: collectionId,
                                templateId: null,
                                fileSchemaId: value,
                                buttonLabel: buttonLabel,
                                signingPartner: signingPartner,
                            });
                        }, showSearch: true, children: fileSchemas === null || fileSchemas === void 0 ? void 0 : fileSchemas.map(function (schema) { return (_jsx(Select.Option, { value: schema.id, children: schema.name }, schema.id)); }) }), _jsx("div", { className: "TaskSectionLabel mt-4", children: "Template" }), _jsx(Select, { className: "TaskDetailModalSelector", placeholder: "Select a Template", value: templateId || undefined, onChange: function (value) {
                            return updateSigningPartnerTask({
                                collectionId: collectionId,
                                templateId: value,
                                fileSchemaId: fileSchemaId,
                                buttonLabel: buttonLabel,
                                signingPartner: signingPartner,
                            });
                        }, showSearch: true, children: templateOptions }), _jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Button Label" }), _jsx(Input, { className: "TaskDetailModalInput", value: buttonLabel, onChange: function (e) {
                                    updateSigningPartnerTask({
                                        collectionId: collectionId,
                                        templateId: templateId,
                                        fileSchemaId: fileSchemaId,
                                        buttonLabel: e.target.value,
                                        signingPartner: signingPartner,
                                    });
                                } })] })] }))] }));
};
export default React.memo(SigningPartnerTaskSettingSection);
