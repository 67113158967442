import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment-timezone';
import { DayOfWeek } from 'src/components/Settings/Compliance/Interface';
import ADPLogo from '../utils/icons/IpLogos/ADP.svg';
import SquareLogo from '../utils/icons/IpLogos/Square.svg';
import GustoLogo from '../utils/icons/IpLogos/Gusto.svg';
import DocuSignLogo from '../utils/icons/IpLogos/DocuSign.svg';
import SignNowLogo from '../utils/icons/IpLogos/SignNow.svg';
import RipplingLogo from '../utils/icons/IpLogos/Rippling.svg';
import QuickBooksLogo from '../utils/icons/IpLogos/QuickBooks.svg';
import KamanaLogo from '../utils/icons/IpLogos/Kamana.png';
import PaychexLogo from '../utils/icons/IpLogos/Paychex.png';
import CheckrPayLogo from '../utils/icons/IpLogos/CheckrPay.png';
import SmartRecruitersLogo from '../utils/icons/IpLogos/SmartRecruiters.png';
import TitanLogo from '../utils/icons/IpLogos/Titan.jpeg';
import BullhornLogo from '../utils/icons/IpLogos/Bullhorn.png';
import { CurrencyMap, IpName } from '../shared';
import { AutomationIconStandard } from './icons/AutomationIcons/AutomationIconStandard';
import { AutomationIconRound } from './icons/AutomationIcons/AutomationIconRound';
import { OrganizationUrls } from './OrganizationConstants';
export var setRipplingAuthCode = function (code) {
    localStorage.setItem('ripplingAuthCode', code);
};
export var getRipplingAuthCode = function () {
    return localStorage.getItem('ripplingAuthCode');
};
export var setSelfOnboardingStatus = function (status) {
    localStorage.setItem('isSelfOnboardingOnState', JSON.stringify(status));
};
export var getAppSession = function () {
    return JSON.parse(localStorage.getItem('appSession') || '{}');
};
export var setAccountStartDayOfWeek = function (day) {
    localStorage.setItem('accountStartDayOfWeek', day);
};
export var getAccountStartDayOfWeek = function () {
    return localStorage.getItem('accountStartDayOfWeek') || DayOfWeek.SUNDAY;
};
export var setAccountCurrency = function (accountCurrency) {
    localStorage.setItem('accountCurrency', accountCurrency);
};
export var getAccountCurrencySymbol = function () {
    var currencyCode = localStorage.getItem('accountCurrency');
    if (!currencyCode)
        return '$';
    return CurrencyMap[currencyCode];
};
export var getAccountCurrencyCode = function () {
    return localStorage.getItem('accountCurrency') || 'USD';
};
export var setPageGroupExpandedStatusMap = function (map) {
    localStorage.setItem('pageGroupExpandedStatusMap', JSON.stringify(map));
};
export var getOrgIdentifierFromURL = function () {
    var _a, _b, _c;
    var windowUrl = window.location.hostname;
    var orgUrlMatch = (_a = OrganizationUrls.find(function (org) {
        return windowUrl.toLowerCase().includes(org.url.toLowerCase());
    })) === null || _a === void 0 ? void 0 : _a.key;
    if (orgUrlMatch) {
        return orgUrlMatch;
    }
    var match = (_c = (_b = windowUrl.match(/^(.*?)\./)) === null || _b === void 0 ? void 0 : _b[1]) === null || _c === void 0 ? void 0 : _c.toLowerCase();
    // if www., app, dev-app, staging-app, sand-box return undefined
    if (match &&
        !['www', 'app', 'dev-app', 'staging-app', 'sandbox-app'].includes(match) &&
        !orgUrlMatch) {
        return match;
    }
};
export var getPageGroupExpandedStatus = function (pageGroupId) {
    var _a;
    var map = getPageGroupExpandedStatusMap();
    return (_a = map[pageGroupId]) !== null && _a !== void 0 ? _a : true;
};
export var getPageGroupExpandedStatusMap = function () {
    var map = JSON.parse(localStorage.getItem('pageGroupExpandedStatusMap') || '{}');
    return map;
};
export var setSchedulingTimeRange = function (timeRange) {
    return localStorage.setItem('timeRange', JSON.stringify(timeRange));
};
export var getSchedulingTimeRange = function () {
    var timeRange = JSON.parse(localStorage.getItem('timeRange') || '[]');
    var range = timeRange.map(function (time) {
        return moment(time).startOf('day');
    });
    if (range.length)
        return range;
    return undefined;
};
export var setPrevPageRoute = function (route) {
    localStorage.setItem('prevPage', route);
};
export var getPrevPageRoute = function () {
    return localStorage.getItem('prevPage') || '';
};
export var setPayPeriodPageRoute = function (route) {
    localStorage.setItem('payPeriodPage', route);
};
export var getPayPeriodPageRoute = function () {
    return localStorage.getItem('payPeriodPage') || '';
};
// integration related function
export var getIpDisplayText = function (ipName) {
    if (!ipName)
        return '';
    switch (ipName.toLowerCase()) {
        case 'adp':
            return 'ADP';
        case 'square':
            return 'Square';
        case 'docusign':
            return 'DocuSign';
        case 'rippling':
            return 'Rippling';
        case 'signnow':
            return 'SignNow';
        case 'quickbooks':
            return 'QuickBooks';
        case 'paychex':
            return 'Paychex';
        case 'checkrpay':
            return 'Checkr Pay';
        case 'smartrecruiters':
            return 'SmartRecruiters';
        case 'kamana':
            return 'Kamana';
        case 'bullhorn':
            return 'Bullhorn';
        default:
            return ipName;
    }
};
export var getIntegrationIcon = function (ipName) {
    if (!ipName)
        return _jsx(_Fragment, {});
    switch (ipName.toLowerCase()) {
        case IpName.ADP:
            return _jsx("img", { src: ADPLogo });
        case IpName.SQUARE:
            return _jsx("img", { src: SquareLogo });
        case IpName.GUSTO:
            return _jsx("img", { src: GustoLogo });
        case IpName.DOCUSIGN:
            return _jsx("img", { src: DocuSignLogo });
        case IpName.RIPPLING:
            return _jsx("img", { src: RipplingLogo });
        case IpName.SIGNNOW:
            return _jsx("img", { src: SignNowLogo });
        case IpName.QUICKBOOKS:
            return _jsx("img", { src: QuickBooksLogo, style: { height: 32 } });
        case IpName.PAYCHEX:
            return _jsx("img", { src: PaychexLogo, style: { height: 32, width: 32 } });
        case IpName.CHECKRPAY:
            return _jsx("img", { src: CheckrPayLogo, style: { height: 32 } });
        case IpName.SmartRecruiters:
            return _jsx("img", { src: SmartRecruitersLogo, style: { height: 32 } });
        case IpName.KAMANA:
            return _jsx("img", { src: KamanaLogo });
        case IpName.TITAN:
            return _jsx("img", { src: TitanLogo });
        case IpName.BULLHORN:
            return _jsx("img", { src: BullhornLogo, style: { height: 32, width: 32 } });
        default:
            return _jsx(_Fragment, {});
    }
};
export var renderAccountUserInfo = function (avatarUrl, userName) {
    // invalid user
    if (!userName) {
        return _jsx("div", { className: "employeeIcon" });
    }
    // user without uploaded avatar
    var acronym = '';
    if (!avatarUrl) {
        var matches = userName.match(/\b(\w)/g);
        acronym = (matches === null || matches === void 0 ? void 0 : matches.join('')) || '';
    }
    return (_jsxs("div", { className: "flex w-full items-center", title: userName, children: [avatarUrl ? (_jsx("img", { className: "employeeIcon", src: avatarUrl })) : (_jsx("div", { className: "employeeIcon", children: acronym })), _jsx("div", { className: "ml-2 h-[24px] overflow-hidden text-ellipsis whitespace-nowrap leading-[24px]", children: userName })] }));
};
export var renderAuthorEmployeeIcon = function (author, isRound) {
    if (isRound === void 0) { isRound = false; }
    var iconClassName = "employeeIcon ".concat(isRound ? 'isRound' : '');
    if (!author) {
        return _jsx("div", { className: iconClassName });
    }
    if (author.actorType === 'AUTOMATION') {
        return isRound ? _jsx(AutomationIconRound, {}) : _jsx(AutomationIconStandard, {});
    }
    if (author.authorIcon) {
        return _jsx("img", { className: iconClassName, src: author.authorIcon });
    }
    else {
        var acronym = '';
        if (author.authorTitle) {
            var matches = author.authorTitle.match(/\b(\w)/g);
            acronym = (matches === null || matches === void 0 ? void 0 : matches.join('')) || '';
        }
        return _jsx("div", { className: iconClassName, children: acronym });
    }
};
export var getWeekDay = function (numberOfDay) {
    switch (numberOfDay) {
        case 0:
            return DayOfWeek.SUNDAY;
        case 1:
            return DayOfWeek.MONDAY;
        case 2:
            return DayOfWeek.TUESDAY;
        case 3:
            return DayOfWeek.WEDNESDAY;
        case 4:
            return DayOfWeek.THURSDAY;
        case 5:
            return DayOfWeek.FRIDAY;
        case 6:
            return DayOfWeek.SATURDAY;
        default:
            DayOfWeek.SUNDAY;
    }
    return DayOfWeek.SUNDAY;
};
export var getWeekDayNumber = function (day) {
    switch (day) {
        case DayOfWeek.SUNDAY:
            return 0;
        case DayOfWeek.MONDAY:
            return 1;
        case DayOfWeek.TUESDAY:
            return 2;
        case DayOfWeek.WEDNESDAY:
            return 3;
        case DayOfWeek.THURSDAY:
            return 4;
        case DayOfWeek.FRIDAY:
            return 5;
        case DayOfWeek.SATURDAY:
            return 6;
        default:
            0;
    }
    return 0;
};
export var duplicateFormValueValidation = function (value, allValues) {
    // return true if contains duplicate value
    var duplicateEmails = allValues.filter(function (current) { return current === value; });
    return duplicateEmails.length > 1;
};
export var isInternalUserEmail = function (email) {
    return email.endsWith('@zira.ai') || email.endsWith('@teambridge.com');
};
export var getCannyQueryParameterByName = function (name) {
    var pairStrings = window.location.search.slice(1).split('&');
    var pairs = pairStrings.map(function (pair) { return pair.split('='); });
    return pairs.reduce(function (value, pair) {
        if (value)
            return value;
        return pair[0] === name ? decodeURIComponent(pair[1]) : null;
    }, null);
};
export var getCannySSORedirectURL = function (ssoToken, redirectURL, companyID) {
    return "https://canny.io/api/redirects/sso?companyID=".concat(companyID, "&ssoToken=").concat(ssoToken, "&redirect=").concat(encodeURIComponent(redirectURL));
};
