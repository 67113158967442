import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from 'antd';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useRecoilState } from 'recoil';
import { DownloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { documentPreviewModalDataState } from './Atom';
import './DocumentPreviewModal.scss';
var DocumentPreviewModal = function () {
    var _a = useRecoilState(documentPreviewModalDataState), modalData = _a[0], setModalData = _a[1];
    var docs = useMemo(function () {
        return modalData.map(function (doc) {
            return {
                uri: doc.url,
                fileName: doc.name,
                fileType: doc.type,
            };
        });
    }, [modalData]);
    var CustomHeader = useCallback(function (state, previousDocument, nextDocument) {
        var _a, _b, _c, _d, _e, _f;
        if (!state.currentDocument || ((_b = (_a = state.config) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.disableFileName)) {
            return null;
        }
        var needDisplayDownloadBtn = !((_d = (_c = state.currentDocument) === null || _c === void 0 ? void 0 : _c.fileType) === null || _d === void 0 ? void 0 : _d.includes('pdf'));
        return (_jsxs("div", { className: "mt-[20px] flex items-center justify-between pb-[5px]", children: [_jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "mr-[10px] text-base font-medium", children: state.currentDocument.fileName || '' }), ((_e = state.currentDocument) === null || _e === void 0 ? void 0 : _e.uri) && needDisplayDownloadBtn && (_jsx(ZiraTooltip, { title: "Download File", children: _jsx("a", { href: (_f = state.currentDocument) === null || _f === void 0 ? void 0 : _f.uri, download: true, target: "_blank", rel: "noopener noreferrer", children: _jsx(Button, { icon: _jsx(DownloadOutlined, {}), className: "DownloadBtn" }) }) }))] }), state.documents.length > 1 && (_jsxs("div", { className: "flex items-center", children: [_jsx(Button, { className: "LeftRightBtn", icon: _jsx(LeftOutlined, {}), onClick: previousDocument, disabled: state.currentFileNo === 0 }), _jsx("div", { children: "".concat(state.currentFileNo + 1, " / ").concat(state.documents.length) }), _jsx(Button, { className: "LeftRightBtn", icon: _jsx(RightOutlined, {}), onClick: nextDocument, disabled: state.currentFileNo >= state.documents.length - 1 })] }))] }));
    }, []);
    return (_jsx(Modal, { open: modalData.length > 0, width: "100%", centered: true, zIndex: 1001, footer: null, onCancel: function () { return setModalData([]); }, className: "DocumentPreviewModal", children: _jsx(DocViewer, { documents: docs, pluginRenderers: DocViewerRenderers, prefetchMethod: "GET", className: "TeamBridgeDocViewer", config: { header: { overrideComponent: CustomHeader } } }) }));
};
export default React.memo(DocumentPreviewModal);
