import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, Steps } from 'antd';
import { useRecoilState } from 'recoil';
import { useFileApi } from 'src/hooks/api/useFileApi';
import usePayRateV2Api from '../../../usePayRateV2Api';
import { uploadedDefaultRateFileState, uploadedRateFilePassVerifyState } from '../../../atoms';
import './DefaultRateImportModal.scss';
import { TemplateDownloadIcon } from './utils/icons/TemplateDownloadIcon';
import UploadDefaultRateFileSection from './ImportSteps/UploadDefaultRateFileSection';
import VerifyFileSection from './ImportSteps/VerifyFileSection';
var DefaultRateImportModal = function (_a) {
    var isModalVisible = _a.isModalVisible, onModalClose = _a.onModalClose;
    var exportDefaultRateTemplateFile = usePayRateV2Api().exportDefaultRateTemplateFile;
    var handleDeleteFile = useFileApi().deleteFile;
    var _b = useState(0), currentStep = _b[0], setCurrentStep = _b[1];
    var downloadTemplateRef = useRef(null);
    var _c = useState(), templateFileUrl = _c[0], setTemplateFileUrl = _c[1];
    var _d = useRecoilState(uploadedDefaultRateFileState), uploadedFile = _d[0], setUploadedFile = _d[1];
    var _e = useRecoilState(uploadedRateFilePassVerifyState), uploadedRateFilePassVerify = _e[0], setUploadedRateFilePassVerify = _e[1];
    useEffect(function () {
        if (templateFileUrl && downloadTemplateRef.current) {
            downloadTemplateRef.current.click();
        }
    }, [templateFileUrl]);
    var onStepChange = useCallback(function () {
        setCurrentStep(function (prev) { return prev + 1; });
    }, []);
    var onStepButtonClick = useCallback(function () {
        if (currentStep === 0) {
            setUploadedRateFilePassVerify(false);
            onStepChange();
        }
        else if (currentStep === 1) {
            if (!uploadedRateFilePassVerify) {
                // Delete current uploaded file and go back to upload step
                if (uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.url) {
                    handleDeleteFile(uploadedFile.url);
                    setUploadedFile(undefined);
                }
                setCurrentStep(function (prev) { return prev - 1; });
                return;
            }
            else {
                onStepChange();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentStep,
        onStepChange,
        setUploadedFile,
        setUploadedRateFilePassVerify,
        uploadedFile,
        uploadedRateFilePassVerify,
    ]);
    var renderGoNextButtonText = useMemo(function () {
        if (currentStep === 0)
            return 'Verify Upload';
        if (currentStep === 1) {
            if (uploadedRateFilePassVerify) {
                return 'Import';
            }
            else {
                return 'Go Back and Upload Again';
            }
        }
        return 'Finish';
    }, [currentStep, uploadedRateFilePassVerify]);
    var generateTemplateFile = useCallback(function () {
        exportDefaultRateTemplateFile.mutate(undefined, {
            onSuccess: function (resp) {
                if (resp) {
                    setTemplateFileUrl(resp.url);
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onCancelBtnClick = useCallback(function () {
        setUploadedFile(undefined);
        onModalClose();
    }, [onModalClose, setUploadedFile]);
    var modalFooter = useMemo(function () { return (_jsxs("div", { className: "ImportModalFooter", children: [_jsxs("div", { className: "text-blue-400 font-medium cursor-pointer flex items-center hover:underline", onClick: generateTemplateFile, children: [_jsx(TemplateDownloadIcon, { width: '', height: '', fill: '', className: "mb-[2px]" }), "Download Default Rates Templates"] }), _jsxs("div", { children: [_jsx(Button, { className: "CancelBtn", onClick: onCancelBtnClick, children: "Cancel" }), _jsx(Button, { className: "SaveBtn", onClick: onStepButtonClick, disabled: currentStep === 0 && !uploadedFile, children: renderGoNextButtonText })] })] })); }, [
        currentStep,
        generateTemplateFile,
        onCancelBtnClick,
        onStepButtonClick,
        renderGoNextButtonText,
        uploadedFile,
    ]);
    return (_jsxs(Modal, { open: isModalVisible, centered: true, footer: modalFooter, closable: false, destroyOnClose: true, className: "DefaultRateImportModal", width: 800, children: [_jsxs("div", { children: [_jsx("div", { className: "text-lg font-medium mb-8", children: "Import Default Rates" }), _jsx(Steps, { className: "DefaultRateImportSteps", size: "small", current: currentStep, items: [
                            {
                                title: 'Upload',
                            },
                            {
                                title: 'Verify',
                            },
                            {
                                title: 'Finish',
                            },
                        ] }), currentStep === 0 && _jsx(UploadDefaultRateFileSection, {}), currentStep === 1 && _jsx(VerifyFileSection, {})] }), _jsx("a", { ref: downloadTemplateRef, href: templateFileUrl, download: true })] }));
};
export default React.memo(DefaultRateImportModal);
