import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseOutlined, LinkOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import ZiraWarningPopconfirm from 'src/ui/ZiraWarningPopconfirm';
import { useTasksApi } from 'src/hooks/api';
import { useRecordDetail } from 'src/hooks/component';
import { NonSchemaNativePath } from 'src/shared';
import { collectionTableNonSchemaNativeFieldsState } from 'src/state';
import { collectionNameSelector } from 'src/state/selectors/withAccountDetailState';
import pluralize from 'pluralize';
import TaskDetailLinkedRecordDropdown from './TaskDetailLinkedRecordDropdown';
var TaskDetailLinkedRecordContent = function (_a) {
    var _b, _c, _d, _e, _f;
    var recordId = _a.recordId, dataSource = _a.dataSource;
    var completionDetail = (_d = (_c = (_b = useRecoilValue(collectionTableNonSchemaNativeFieldsState(dataSource))) === null || _b === void 0 ? void 0 : _b[recordId]) === null || _c === void 0 ? void 0 : _c.find(function (field) { return field.path === NonSchemaNativePath.TASK_COMPLETION_DETAIL; })) === null || _d === void 0 ? void 0 : _d.completionDetail;
    var completionDetailRecord = (_e = completionDetail === null || completionDetail === void 0 ? void 0 : completionDetail.completingRecords) === null || _e === void 0 ? void 0 : _e[0];
    var completionDetailRecordId = completionDetailRecord === null || completionDetailRecord === void 0 ? void 0 : completionDetailRecord.recordId;
    var collectionName = useRecoilValue(collectionNameSelector((_f = completionDetailRecord === null || completionDetailRecord === void 0 ? void 0 : completionDetailRecord.collectionId) !== null && _f !== void 0 ? _f : ''));
    var _g = useState(completionDetailRecordId), linkedRecordId = _g[0], setLinkedRecordId = _g[1];
    useEffect(function () {
        setLinkedRecordId(completionDetailRecordId);
    }, [completionDetailRecordId]);
    var _h = useTasksApi(), useUpdateTaskCompletionDetail = _h.useUpdateTaskCompletionDetail, useGetRecordTitles = _h.useGetRecordTitles;
    var recordTitles = useGetRecordTitles({
        body: {
            recordIds: linkedRecordId ? [linkedRecordId] : [],
        },
        options: {
            enabled: !!linkedRecordId,
        },
    }).data;
    var linkedRecordTitle = linkedRecordId
        ? (recordTitles === null || recordTitles === void 0 ? void 0 : recordTitles[linkedRecordId]) ||
            "Empty/Deleted ".concat(collectionName ? pluralize(collectionName, 1) : 'record')
        : undefined;
    var updateLinkedRecord = useCallback(function (completionDetail) {
        useUpdateTaskCompletionDetail.mutate({
            assignedTaskRId: recordId,
            taskCompletionDetail: completionDetail
                ? {
                    completingRecords: [completionDetail],
                }
                : undefined,
        }, {
            onSuccess: function (data) {
                if (data) {
                    setLinkedRecordId(completionDetail === null || completionDetail === void 0 ? void 0 : completionDetail.recordId);
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recordId]);
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var ref = useRef(null);
    return (_jsxs("div", { ref: ref, className: "px-2 h-8 bg-gray-40 text-xs font-medium rounded flex items-center mr-[10px] max-w-1/2 w-[200px]", children: [linkedRecordId && linkedRecordTitle ? (_jsx(ZiraTooltip, { title: "View Details", placement: "topLeft", children: _jsxs("div", { className: "max-w-[90%] cursor-pointer hover:underline overflow-hidden text-ellipsis flex items-center whitespace-nowrap mr-2", onClick: function () { return openRecordDetail(linkedRecordId); }, children: [_jsx(LinkOutlined, { className: "mr-2" }), linkedRecordTitle] }) })) : (_jsx(TaskDetailLinkedRecordDropdown, { recordId: recordId, dataSource: dataSource, onLinkRecord: updateLinkedRecord, children: _jsx("div", { className: "mx-auto", children: "Add Link" }) })), linkedRecordTitle && (_jsx(ZiraWarningPopconfirm, { title: "Are you sure you want to unlink this record?", onConfirm: function () { return updateLinkedRecord(); }, children: _jsx(ZiraTooltip, { title: "Unlink Record", placement: "topLeft", children: _jsx(CloseOutlined, { className: "ml-auto cursor-pointer text-gray-200 hover:text-black" }) }) }))] }));
};
export default React.memo(TaskDetailLinkedRecordContent);
