import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import 'react-calendar-timeline/lib/Timeline.css';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { scheduleColorCodingSelector, scheduleCalendarState, scheduleTimeRangeState, tableCollectionIdSelector, scheduleShiftCardBasicInfoSelector, collectionTableSchemaMapSelector, } from 'src/state';
import { Calendar } from 'antd';
import moment from 'moment';
import './ScheduleCalendarMonthView.scss';
import { isDarkColor } from 'src/utils/Tools/TypeGuard';
import { disableScroll } from 'src/utils/Collection';
import { CollectionTableType } from 'src/shared';
import { getCardColor } from 'src/services';
import ShiftCardDisplayTime from '../../ScheduleCard/ShiftCard/ShiftCardDisplayTime';
import ShiftCardTags from '../../ScheduleCard/ShiftCard/ShiftCardTags';
var ScheduleCalendarMonthView = function (_a) {
    var blockId = _a.blockId, openShiftDetailModal = _a.openShiftDetailModal;
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var colorCodingSchemaId = useRecoilValue(scheduleColorCodingSelector(blockId));
    var schemaMap = useRecoilValue(collectionTableSchemaMapSelector(collectionId));
    var calendar = useRecoilValue(scheduleCalendarState(blockId));
    useEffect(function () {
        disableScroll(false);
    }, []);
    var dateCellRender = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function dateCells(value) {
            var _a;
            var dateKey = moment(value).format('YYYY-MM-DD');
            var recordIds = (_a = calendar[dateKey]) !== null && _a !== void 0 ? _a : [];
            var cells = [];
            // Using recordIds order to generate cells, which has the correct shift order
            recordIds.forEach(function (rid) {
                var cell = snapshot
                    .getLoadable(scheduleShiftCardBasicInfoSelector({
                    type: CollectionTableType.TABLE,
                    collectionId: collectionId,
                    recordId: rid,
                }))
                    .valueMaybe();
                if (cell)
                    cells.push(cell);
            });
            return (_jsx("div", { className: "relative space-y-1", children: cells.map(function (cell) {
                    var cardColor = getCardColor(cell, colorCodingSchemaId, schemaMap);
                    return (_jsxs("div", { className: "ShiftCell space-x-1", style: {
                            backgroundColor: cardColor,
                            color: isDarkColor(cardColor) === 'dark' ? 'white' : 'black',
                        }, onClick: function () {
                            openShiftDetailModal === null || openShiftDetailModal === void 0 ? void 0 : openShiftDetailModal(cell.rid);
                        }, children: [_jsx(ShiftCardDisplayTime, { blockId: blockId, recordId: cell.rid }), _jsx(ShiftCardTags, { blockId: blockId, recordId: cell.rid, style: { height: '100%' } })] }, cell.rid));
                }) }));
        };
    }, [blockId, calendar, collectionId, colorCodingSchemaId, openShiftDetailModal, schemaMap]);
    return (_jsx(Calendar, { className: "ScheduleCalendarMonth", dateCellRender: dateCellRender, value: moment(timeRange[0]), headerRender: function () { return null; }, onChange: function () { return null; }, onSelect: function () { return null; }, onPanelChange: function () { return null; }, validRange: [
            moment(timeRange[0]).startOf('month'),
            moment(timeRange[0]).endOf('month'),
        ] }));
};
export default React.memo(ScheduleCalendarMonthView);
