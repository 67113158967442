var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { useFetch } from '../../../hooks/api';
var useRollup = function (rollupId, options) {
    var customFetch = useFetch().request;
    var queryClient = useQueryClient();
    var _a = useQuery(['useGetRollupQuery', rollupId], function () {
        return customFetch({
            method: 'GET',
            url: "rollup/".concat(rollupId),
        });
    }, __assign(__assign({ enabled: Boolean(rollupId) }, options), { refetchOnMount: false })), data = _a.data, refetch = _a.refetch, isLoading = _a.isLoading, isFetching = _a.isFetching;
    var calculateRollup = useMutation(function (rollupId) {
        return customFetch({
            method: 'POST',
            url: "rollup/calculate/".concat(rollupId),
        });
    });
    var refetchCollection = useRefetchCollection();
    var updateRollup = useMutation(function (_a) {
        var rollupId = _a.rollupId, refCollectionId = _a.refCollectionId, refSchemaId = _a.refSchemaId, rollupType = _a.rollupType, filterSettings = _a.filterSettings, denominatorFilterSettings = _a.denominatorFilterSettings, viewSettings = _a.viewSettings, sumNumberSchemaId = _a.sumNumberSchemaId;
        return customFetch({
            method: 'POST',
            url: "rollup",
        }, {
            id: rollupId,
            refCollectionId: refCollectionId,
            refSchemaId: refSchemaId,
            settings: {
                filterSettings: filterSettings,
                rollupType: rollupType,
                denominatorFilterSettings: denominatorFilterSettings,
                viewSettings: viewSettings,
                numberSchemaId: sumNumberSchemaId,
            },
        });
    }, {
        onSuccess: function (data) {
            if (data === null || data === void 0 ? void 0 : data.id) {
                queryClient.invalidateQueries({ queryKey: ['useGetRollupQuery', data.id] });
            }
            if (data === null || data === void 0 ? void 0 : data.collectionId) {
                refetchCollection(data.collectionId);
            }
        },
    });
    return {
        data: data,
        refetch: refetch,
        calculateRollup: calculateRollup.mutate,
        updateRollup: updateRollup.mutate,
        isLoading: isLoading,
        isFetching: isFetching,
    };
};
export default useRollup;
