var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Pluralize from 'pluralize';
import { DefaultValue, selectorFamily } from 'recoil';
import { DefaultCollectionCell, } from 'src/components/CenterPanel/Interface';
import { TITLE_COLUMN_ID, SearchableCollectionTypeSet, } from 'src/components/OptimizedTableView/Constants';
import { CollectionTableType, CollectionType, DefaultDisplaySettingsObj, NativePath, NonSchemaNativePath, SchemaConfigurationType, ValueDataType, } from 'src/shared';
import { formatCellData } from 'src/services/CollectionDataService/CollectionCellService';
import { collectionTableNonSchemaNativeFieldsState, collectionTableSchemaMutationsState, collectionTableSchemasState, collectionTableState, ifCurrentUserHasDataBuilderPermission, tableCollectionInfoState, tableCollectionRowsState, tableCollectionViewsState, tableSelectedViewIdState, } from 'src/state';
import { getNativeType, getIfNeedsFetchDataInCell, generateNativeCollectionTitle, } from 'src/utils/Collection';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { intersection } from 'lodash';
import { ShiftStatusDisplayNameToColorMap, } from 'src/components/ScheduleView/Constant';
import { accountTimeZoneSelector } from '../withAccountDetailState';
var collectionTableCellMapSelector = selectorFamily({
    key: 'collectionTableCellMapSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var collectionTableData = get(collectionTableState(dataSource));
            var cells = (collectionTableData !== null && collectionTableData !== void 0 ? collectionTableData : {}).cells;
            return cells;
        };
    },
});
var collectionTableRecordState = selectorFamily({
    key: 'collectionTableRecordState',
    get: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a) {
            var get = _a.get;
            var cellMap = get(collectionTableCellMapSelector(dataSource));
            return cellMap ? cellMap[recordId] : undefined;
        };
    },
    set: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a, newRecordData) {
            var set = _a.set;
            if (!(newRecordData instanceof DefaultValue)) {
                set(collectionTableState(dataSource), function (prev) {
                    var _a;
                    if (!prev)
                        return prev;
                    return __assign(__assign({}, prev), { cells: __assign(__assign({}, prev.cells), (_a = {}, _a[recordId] = newRecordData, _a)) });
                });
            }
        };
    },
});
var collectionTableCellState = selectorFamily({
    key: 'collectionTableCellState',
    get: function (_a) {
        var recordId = _a.recordId, schemaId = _a.schemaId, dataSource = __rest(_a, ["recordId", "schemaId"]);
        return function (_a) {
            var _b, _c, _d;
            var get = _a.get;
            var record = get(collectionTableRecordState(__assign({ recordId: recordId }, dataSource)));
            if (!record)
                return;
            if (schemaId === TITLE_COLUMN_ID) {
                return generateNativeCollectionTitle(get(collectionTableTypeSelector(dataSource)), record, get(collectionTableSchemaNativePathMapSelector(dataSource.collectionId)), (_c = (_b = get(collectionTableNonSchemaNativePathSelector(__assign(__assign({}, dataSource), { recordId: recordId, path: NonSchemaNativePath.SHIFT_TIME_ZONE_ID })))) === null || _b === void 0 ? void 0 : _b.timeZoneId) !== null && _c !== void 0 ? _c : get(accountTimeZoneSelector));
            }
            return (_d = record[schemaId]) !== null && _d !== void 0 ? _d : DefaultCollectionCell;
        };
    },
    set: function (_a) {
        var recordId = _a.recordId, schemaId = _a.schemaId, dataSource = __rest(_a, ["recordId", "schemaId"]);
        return function (_a, newCellData) {
            var set = _a.set;
            if (!(newCellData instanceof DefaultValue)) {
                set(collectionTableState(dataSource), function (prev) {
                    var _a, _b;
                    if (!prev)
                        return prev;
                    return __assign(__assign({}, prev), { cells: __assign(__assign({}, prev.cells), (_a = {}, _a[recordId] = __assign(__assign({}, prev.cells[recordId]), (_b = {}, _b[schemaId] = newCellData, _b)), _a)) });
                });
            }
        };
    },
});
var collectionTableRowsSelector = selectorFamily({
    key: 'collectionTableRowsSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var collectionTableData = get(collectionTableState(dataSource));
            var rows = (collectionTableData !== null && collectionTableData !== void 0 ? collectionTableData : {}).rows;
            return rows !== null && rows !== void 0 ? rows : [];
        };
    },
});
var collectionTableRowSelector = selectorFamily({
    key: 'collectionTableRowSelector',
    get: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a) {
            var get = _a.get;
            var rows = get(collectionTableRowsSelector(dataSource));
            return rows.find(function (row) { return row.recordId === recordId; });
        };
    },
});
var collectionTableCellDataBulkSelector = selectorFamily({
    key: 'collectionTableCellDataBulkSelector',
    get: function (_a) {
        var dataSource = _a.dataSource, cells = _a.cells;
        return function (_a) {
            var get = _a.get;
            return cells.map(function (cell) {
                var cellValue = get(collectionTableCellDataSelector(__assign({ type: dataSource.type }, cell)));
                return cellValue;
            });
        };
    },
});
var collectionTableCellDataSelector = selectorFamily({
    key: 'collectionTableCellDataSelector',
    get: function (params) {
        return function (_a) {
            var _b, _c;
            var get = _a.get;
            var collectionCell = get(collectionTableCellState(params));
            if (!collectionCell)
                return;
            var schema = get(collectionTableSchemaSelector({
                collectionId: params.collectionId,
                schemaId: params.schemaId,
            }));
            var recordRow = params.type === CollectionTableType.TABLE
                ? get(collectionTableRowSelector({
                    type: params.type,
                    collectionId: params.collectionId,
                    recordId: params.recordId,
                }))
                : undefined;
            if (params.schemaId === TITLE_COLUMN_ID) {
                // Bespoke title column
                var titleColor = recordRow === null || recordRow === void 0 ? void 0 : recordRow.color;
                var collectionType = get(collectionTableTypeSelector({
                    type: params.type,
                    collectionId: params.collectionId,
                }));
                if (collectionType === CollectionType.SHIFT) {
                    // Try to get shift status color for bespoke shift title
                    var shiftStatusSchemaId = (_b = get(collectionTableNativeSchemaSelector({
                        collectionId: params.collectionId,
                        nativePath: NativePath.SHIFT_STATUS,
                    }))) === null || _b === void 0 ? void 0 : _b.id;
                    if (shiftStatusSchemaId) {
                        var shiftStatus = (_c = get(collectionTableCellState(__assign(__assign({}, params), { schemaId: shiftStatusSchemaId })))) === null || _c === void 0 ? void 0 : _c.content;
                        if (shiftStatus) {
                            titleColor =
                                ShiftStatusDisplayNameToColorMap[shiftStatus];
                        }
                    }
                }
                return formatCellData(collectionCell, undefined, titleColor, true, recordRow === null || recordRow === void 0 ? void 0 : recordRow.avatarUrl);
            }
            return formatCellData(collectionCell, schema, recordRow === null || recordRow === void 0 ? void 0 : recordRow.color);
        };
    },
});
var collectionTableSchemaMapSelector = selectorFamily({
    key: 'collectionTableSchemaMapSelector',
    get: function (collectionId) {
        return function (_a) {
            var get = _a.get;
            var schemas = get(collectionTableSchemasState(collectionId));
            return schemas.reduce(function (acc, schema) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[schema.id] = schema, _a)));
            }, {});
        };
    },
});
var collectionTableSchemaNativePathMapSelector = selectorFamily({
    key: 'collectionTableSchemaNativePathMapSelector',
    get: function (collectionId) {
        return function (_a) {
            var get = _a.get;
            var schemas = get(collectionTableSchemasState(collectionId));
            return schemas.reduce(function (acc, schema) {
                var _a;
                if (!schema.nativePath)
                    return acc;
                return __assign(__assign({}, acc), (_a = {}, _a[schema.nativePath] = schema, _a));
            }, {});
        };
    },
});
var collectionTableSchemaCanEditMapSelector = selectorFamily({
    key: 'collectionTableSchemaCanEditMapSelector',
    get: function (collectionId) {
        return function (_a) {
            var get = _a.get;
            var schemaMap = get(collectionTableSchemaMapSelector(collectionId));
            return Object.values(schemaMap).reduce(function (acc, schema) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[schema.id] = schema.canEdit, _a)));
            }, {});
        };
    },
});
var collectionTableSchemaCanEditSelector = selectorFamily({
    key: 'collectionTableNativeSchemaCanEditSelector',
    get: function (_a) {
        var collectionId = _a.collectionId, schemaId = _a.schemaId, nativePath = _a.nativePath;
        return function (_a) {
            var _b, _c, _d, _e;
            var get = _a.get;
            if (schemaId) {
                var schemaCanEditMap = get(collectionTableSchemaMapSelector(collectionId));
                return (_c = (_b = schemaCanEditMap[schemaId]) === null || _b === void 0 ? void 0 : _b.canEdit) !== null && _c !== void 0 ? _c : false;
            }
            else if (nativePath) {
                var nativeSchemaMap = get(collectionTableSchemaNativePathMapSelector(collectionId));
                return (_e = (_d = nativeSchemaMap[nativePath]) === null || _d === void 0 ? void 0 : _d.canEdit) !== null && _e !== void 0 ? _e : false;
            }
            return false;
        };
    },
});
var collectionTableSchemaCanOnlyUpdateFromEmptyMapSelector = selectorFamily({
    key: 'collectionTableSchemaCanOnlyUpdateFromEmptySelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var schemaMap = get(collectionTableSchemaMapSelector(dataSource.collectionId));
            return Object.values(schemaMap).reduce(function (acc, schema) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[schema.id] = schema.canOnlyUpdateEmpty, _a)));
            }, {});
        };
    },
});
var collectionTableSchemaIdListSelector = selectorFamily({
    key: 'collectionTableSchemaIdListSelector',
    get: function (collectionId) {
        return function (_a) {
            var get = _a.get;
            var schemas = get(collectionTableSchemasState(collectionId));
            var schemaIds = [];
            schemas.map(function (_a) {
                var id = _a.id;
                schemaIds.push(id);
            });
            return schemaIds;
        };
    },
});
var collectionTableSchemaSelector = selectorFamily({
    key: 'collectionTableSchemaSelector',
    get: function (_a) {
        var collectionId = _a.collectionId, schemaId = _a.schemaId;
        return function (_a) {
            var get = _a.get;
            var schemas = get(collectionTableSchemasState(collectionId));
            return schemas.find(function (schema) { return schema.id === schemaId; });
        };
    },
});
var collectionTableNativeSchemaSelector = selectorFamily({
    key: 'collectionTableNativeSchemaSelector',
    get: function (_a) {
        var collectionId = _a.collectionId, nativePath = _a.nativePath;
        return function (_a) {
            var get = _a.get;
            var nativeSchemas = get(collectionTableSchemaNativePathMapSelector(collectionId));
            return nativeSchemas[nativePath];
        };
    },
});
var collectionTableSchemaNameSelector = selectorFamily({
    key: 'collectionTableSchemaSelector',
    get: function (_a) {
        var collectionId = _a.collectionId, schemaId = _a.schemaId;
        return function (_a) {
            var _b;
            var get = _a.get;
            var schema = get(collectionTableSchemaSelector({ collectionId: collectionId, schemaId: schemaId }));
            return (_b = schema === null || schema === void 0 ? void 0 : schema.name) !== null && _b !== void 0 ? _b : '';
        };
    },
});
var collectionTableSchemaShouldShowTimeSelector = selectorFamily({
    key: 'collectionTableSchemaShouldShowTimeSelector',
    get: function (cellPos) {
        return function (_a) {
            var get = _a.get;
            var schema = get(collectionTableSchemaSelector(cellPos));
            if (!schema)
                return false;
            var nativeType = schema.type;
            var nativePath = schema.nativePath;
            var schemaConfig = schema.configuration;
            if (getNativeType(nativeType) === ValueDataType.DATETIME) {
                return !(nativePath === NativePath.USER_DOB ||
                    nativePath === NativePath.PAY_PERIOD_START_DATE ||
                    nativePath === NativePath.PAY_PERIOD_END_DATE ||
                    nativePath === NativePath.DOCUMENT_EXPIRY_DATE ||
                    nativePath === NativePath.TASK_DUE_DATE ||
                    nativePath === NativePath.TASK_COMPLETED_AT ||
                    nativePath === NativePath.PLACEMENT_START_DATE ||
                    nativePath === NativePath.PLACEMENT_END_DATE ||
                    nativePath === NativePath.JOB_START_DATE ||
                    nativePath === NativePath.JOB_END_DATE);
            }
            else if (schema.type === ValueDataType.DATETIME &&
                schemaConfig &&
                schemaConfig.type ===
                    SchemaConfigurationType.DATETIME) {
                return schemaConfig.showTime;
            }
            return true;
        };
    },
});
var collectionTableSchemaShouldShowCountdownSelector = selectorFamily({
    key: 'collectionTableSchemaShouldShowCountdownSelector',
    get: function (cellPos) {
        return function (_a) {
            var get = _a.get;
            var schema = get(collectionTableSchemaSelector(cellPos));
            if (!schema)
                return false;
            var nativeType = schema.type;
            var nativePath = schema.nativePath;
            var schemaConfig = schema.configuration;
            if (getNativeType(nativeType) === ValueDataType.DATETIME) {
                return (nativePath === NativePath.DOCUMENT_EXPIRY_DATE ||
                    nativePath === NativePath.TASK_DUE_DATE);
            }
            else if (schema.type === ValueDataType.DATETIME &&
                schemaConfig &&
                schemaConfig.type ===
                    SchemaConfigurationType.DATETIME) {
                return schemaConfig.isCountdown;
            }
            return false;
        };
    },
});
var collectionTableSchemaButtonSchemaSettingSelector = selectorFamily({
    key: 'collectionTableSchemaButtonSchemaSettingSelector',
    get: function (cellPos) {
        return function (_a) {
            var get = _a.get;
            var schema = get(collectionTableSchemaSelector(cellPos));
            if (!schema)
                return undefined;
            if (schema.type === ValueDataType.BUTTON) {
                return schema.configuration;
            }
        };
    },
});
var collectionTableSchemaRollupIdSelector = selectorFamily({
    key: 'collectionTableSchemaRollupSchemaSettingSelector',
    get: function (cellPos) {
        return function (_a) {
            var get = _a.get;
            var schema = get(collectionTableSchemaSelector(cellPos));
            if (!schema)
                return;
            if (schema.type === ValueDataType.ROLLUP) {
                return schema.rollupId;
            }
        };
    },
});
var collectionTableSchemaIsDOBSelector = selectorFamily({
    key: 'collectionTableSchemaIsDOBSelector',
    get: function (cellPos) {
        return function (_a) {
            var get = _a.get;
            var schema = get(collectionTableSchemaSelector(cellPos));
            if (!schema)
                return false;
            var nativeType = schema.type;
            var nativePath = schema.nativePath;
            return (getNativeType(nativeType) === ValueDataType.DATETIME &&
                nativePath === NativePath.USER_DOB);
        };
    },
});
var collectionTableSchemaFetchTypeSelector = selectorFamily({
    key: 'collectionSchemaFetchTypeSelector',
    get: function (cellPos) {
        return function (_a) {
            var get = _a.get;
            var schema = get(collectionTableSchemaSelector(cellPos));
            if (!schema)
                return {};
            return getIfNeedsFetchDataInCell(schema);
        };
    },
});
var collectionTableSchemaRefCollectionIdSelector = selectorFamily({
    key: 'collectionTableSchemaRefCollectionIdSelector',
    get: function (cellPos) {
        return function (_a) {
            var get = _a.get;
            var schema = get(collectionTableSchemaSelector(cellPos));
            return schema === null || schema === void 0 ? void 0 : schema.refCollectionId;
        };
    },
});
var collectionTableNonSchemaNativePathSelector = selectorFamily({
    key: 'collectionTableNonSchemaNativePathSelector',
    get: function (_a) {
        var recordId = _a.recordId, path = _a.path, dataSource = __rest(_a, ["recordId", "path"]);
        return function (_a) {
            var _b;
            var get = _a.get;
            var nativeFields = get(collectionTableNonSchemaNativeFieldsState(dataSource));
            return (_b = nativeFields[recordId]) === null || _b === void 0 ? void 0 : _b.find(function (field) {
                return field.path === path;
            });
        };
    },
});
var collectionTablePermissionsSelector = selectorFamily({
    key: 'collectionTablePermissionsSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var collectionTableData = get(collectionTableState(dataSource));
            var permissions = (collectionTableData !== null && collectionTableData !== void 0 ? collectionTableData : {}).permissions;
            return permissions;
        };
    },
});
var collectionTableCanEditSelector = selectorFamily({
    key: 'collectionTableCanEditSelector',
    get: function (dataSource) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var permissions = get(collectionTablePermissionsSelector(dataSource));
            return (_b = permissions === null || permissions === void 0 ? void 0 : permissions.canEditCollection) !== null && _b !== void 0 ? _b : false;
        };
    },
});
var collectionTableCanEditSchemasSelector = selectorFamily({
    key: 'collectionTableCanEditSelector',
    get: function (dataSource) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var hasDataBuilderPermission = get(ifCurrentUserHasDataBuilderPermission);
            if (!hasDataBuilderPermission)
                return false;
            var permissions = get(collectionTablePermissionsSelector(dataSource));
            return (_b = permissions === null || permissions === void 0 ? void 0 : permissions.canEditSchemas) !== null && _b !== void 0 ? _b : false;
        };
    },
});
var collectionTableInfoSelector = selectorFamily({
    key: 'collectionTableInfoSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var collectionTableData = get(collectionTableState(dataSource));
            var collectionInfo = (collectionTableData !== null && collectionTableData !== void 0 ? collectionTableData : {}).collectionInfo;
            return collectionInfo;
        };
    },
});
var collectionTableNameSelector = selectorFamily({
    key: 'collectionTableNameSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var collectionInfo = get(collectionTableInfoSelector(dataSource));
            var name = (collectionInfo !== null && collectionInfo !== void 0 ? collectionInfo : {}).name;
            return name !== null && name !== void 0 ? name : '';
        };
    },
});
var collectionTableTypeSelector = selectorFamily({
    key: 'collectionTableTypeSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var collectionInfo = get(collectionTableInfoSelector(dataSource));
            var type = (collectionInfo !== null && collectionInfo !== void 0 ? collectionInfo : {}).type;
            return type !== null && type !== void 0 ? type : CollectionType.CUSTOM;
        };
    },
});
var collectionTableHasSearchbarSelector = selectorFamily({
    key: 'collectionTableHasSearchbarSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var type = get(collectionTableTypeSelector(dataSource));
            return SearchableCollectionTypeSet.has(type);
        };
    },
});
var collectionTableDisplayNameSelector = selectorFamily({
    key: 'collectionTableDisplayNameSelector',
    get: function (dataSource) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var _c = (_b = get(collectionTableInfoSelector(dataSource))) !== null && _b !== void 0 ? _b : {}, _d = _c.type, type = _d === void 0 ? '' : _d, _e = _c.name, name = _e === void 0 ? '' : _e;
            if (!name) {
                return Pluralize.singular(upperCaseStringToStartCase(type));
            }
            return Pluralize.singular(name);
        };
    },
});
var tableCollectionIdSelector = selectorFamily({
    key: 'tableCollectionIdSelector',
    get: function (tableId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var table = get(tableCollectionInfoState(tableId));
            return (_b = table === null || table === void 0 ? void 0 : table.id) !== null && _b !== void 0 ? _b : '';
        };
    },
});
var tableCollectionTypeSelector = selectorFamily({
    key: 'tableCollectionTypeSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var table = get(tableCollectionInfoState(tableId));
            return table === null || table === void 0 ? void 0 : table.type;
        };
    },
});
var tableCollectionRecordOrdersSelector = selectorFamily({
    key: 'tableCollectionRecordOrdersSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var rows = get(tableCollectionRowsState(tableId));
            return rows.map(function (row) { return row.recordId; });
        };
    },
});
var tableCollectionCurrentSizeSelector = selectorFamily({
    key: 'tableCollectionCurrentSizeSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var rows = get(tableCollectionRowsState(tableId));
            return rows.length;
        };
    },
});
var tableCollectionRecordColorSelector = selectorFamily({
    key: 'tableCollectionRecordColorSelector',
    get: function (_a) {
        var tableId = _a.tableId, recordId = _a.recordId;
        return function (_a) {
            var _b;
            var get = _a.get;
            var rows = get(tableCollectionRowsState(tableId));
            return (_b = rows.find(function (row) { return row.recordId === recordId; })) === null || _b === void 0 ? void 0 : _b.color;
        };
    },
});
var tableDisplaySettingsState = selectorFamily({
    key: 'tableDisplaySettingsState',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var displaySettings = get(tableCollectionViewsState(tableId)).displaySettings;
            var viewId = get(tableSelectedViewIdState(tableId));
            var defaultDisplaySettings = DefaultDisplaySettingsObj;
            return viewId
                ? Object.keys(displaySettings[viewId]).length
                    ? displaySettings[viewId]
                    : defaultDisplaySettings
                : defaultDisplaySettings;
        };
    },
    set: function (tableId) {
        return function (_a, newDisplaySettings) {
            var get = _a.get, set = _a.set;
            var viewId = get(tableSelectedViewIdState(tableId));
            set(tableCollectionViewsState(tableId), function (prev) {
                var _a;
                return __assign(__assign({}, prev), { displaySettings: __assign(__assign({}, prev.displaySettings), (_a = {}, _a[viewId] = newDisplaySettings, _a)) });
            });
        };
    },
});
var tableFiltersSelector = selectorFamily({
    key: 'tableFiltersSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var displaySettings = get(tableDisplaySettingsState(tableId));
            return displaySettings.filters;
        };
    },
});
var tableSchemaOptionsSelector = selectorFamily({
    key: 'tableSchemaOptionsSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var displaySettings = get(tableDisplaySettingsState(tableId));
            return displaySettings.schemaOptions;
        };
    },
});
//! Only for tableVisibleColumnIdsSelector use
var tableVisibleSchemaOptionsSelector = selectorFamily({
    key: 'tableVisibleSchemaOptionsSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var schemaOptions = get(tableSchemaOptionsSelector(tableId));
            return schemaOptions.filter(function (option) { return !option.hidden; });
        };
    },
});
var tableVisibleColumnIdsSelector = selectorFamily({
    key: 'tableVisibleColumnIdsSelector',
    get: function (_a) {
        var tableId = _a.tableId, collectionId = _a.collectionId;
        return function (_a) {
            var get = _a.get;
            var schemaOption = get(tableVisibleSchemaOptionsSelector(tableId));
            var schemaOptionSchemaIds = schemaOption.map(function (option) { return option.schemaId; });
            var schemaIds = get(collectionTableSchemaIdListSelector(collectionId));
            return intersection(schemaOptionSchemaIds, schemaIds);
        };
    },
});
var tableVisibleColumnWidthsSelector = selectorFamily({
    key: 'tableVisibleColumnWidthsSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            if (!tableId)
                return {};
            var schemaOptions = get(tableVisibleSchemaOptionsSelector(tableId));
            return schemaOptions.reduce(function (acc, option) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[option.schemaId] = option.width, _a)));
            }, {});
        };
    },
});
var tableGroupBySettingSelector = selectorFamily({
    key: 'tableGroupBySettingSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var displaySettings = get(tableDisplaySettingsState(tableId));
            return displaySettings.groupSetting;
        };
    },
});
var tableIsBoardViewSelector = selectorFamily({
    key: 'tableIsBoardViewSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var boardViewDisplaySetting = get(tableDisplaySettingsState(tableId)).groupSetting;
            return !!boardViewDisplaySetting;
        };
    },
});
var isUserBeingArchivedSelector = selectorFamily({
    key: 'isUserBeingArchivedSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var userArchivedSchema = get(collectionTableSchemaMutationsState(dataSource));
            var userPublishedSchema = get(collectionTableNativeSchemaSelector({
                collectionId: dataSource.collectionId,
                nativePath: NativePath.USER_ARCHIVED,
            }));
            var userPublishedMutation = userArchivedSchema.find(function (schema) { return schema.schemaId === (userPublishedSchema === null || userPublishedSchema === void 0 ? void 0 : userPublishedSchema.id); });
            return (userPublishedMutation === null || userPublishedMutation === void 0 ? void 0 : userPublishedMutation.boolean) === 'true';
        };
    },
});
var isRoleBeingArchivedSelector = selectorFamily({
    key: 'isRoleBeingArchivedSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var roleArchivedSchema = get(collectionTableSchemaMutationsState(dataSource));
            var rolePublishedSchema = get(collectionTableNativeSchemaSelector({
                collectionId: dataSource.collectionId,
                nativePath: NativePath.ROLE_IS_ACTIVE,
            }));
            var rolePublishedMutation = roleArchivedSchema.find(function (schema) { return schema.schemaId === (rolePublishedSchema === null || rolePublishedSchema === void 0 ? void 0 : rolePublishedSchema.id); });
            return (rolePublishedMutation === null || rolePublishedMutation === void 0 ? void 0 : rolePublishedMutation.boolean) === 'false';
        };
    },
});
var isLocationBeingArchivedSelector = selectorFamily({
    key: 'isLocationBeingArchivedSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var locationArchivedSchema = get(collectionTableSchemaMutationsState(dataSource));
            var locationPublishedSchema = get(collectionTableNativeSchemaSelector({
                collectionId: dataSource.collectionId,
                nativePath: NativePath.LOCATION_ARCHIVED,
            }));
            var locationPublishedMutation = locationArchivedSchema.find(function (schema) { return schema.schemaId === (locationPublishedSchema === null || locationPublishedSchema === void 0 ? void 0 : locationPublishedSchema.id); });
            return (locationPublishedMutation === null || locationPublishedMutation === void 0 ? void 0 : locationPublishedMutation.boolean) === 'true';
        };
    },
});
export { 
// Record/Cell
collectionTableCellMapSelector, collectionTableRecordState, collectionTableCellState, collectionTableCellDataSelector, collectionTableCellDataBulkSelector, 
// Schema
collectionTableSchemaMapSelector, collectionTableSchemaNativePathMapSelector, collectionTableSchemaCanEditMapSelector, collectionTableSchemaCanEditSelector, collectionTableSchemaCanOnlyUpdateFromEmptyMapSelector, collectionTableSchemaIdListSelector, collectionTableSchemaSelector, collectionTableNativeSchemaSelector, collectionTableSchemaNameSelector, collectionTableSchemaShouldShowTimeSelector, collectionTableSchemaShouldShowCountdownSelector, collectionTableSchemaIsDOBSelector, collectionTableSchemaButtonSchemaSettingSelector, collectionTableSchemaFetchTypeSelector, collectionTableSchemaRefCollectionIdSelector, collectionTableNonSchemaNativePathSelector, collectionTableSchemaRollupIdSelector, 
// Permissions
collectionTablePermissionsSelector, collectionTableCanEditSelector, collectionTableCanEditSchemasSelector, 
// Info
collectionTableInfoSelector, collectionTableNameSelector, collectionTableTypeSelector, collectionTableHasSearchbarSelector, collectionTableDisplayNameSelector, tableCollectionIdSelector, tableCollectionTypeSelector, 
// Table rows
tableCollectionRecordOrdersSelector, tableCollectionCurrentSizeSelector, tableCollectionRecordColorSelector, 
// Table rows
tableDisplaySettingsState, tableFiltersSelector, tableVisibleColumnIdsSelector, tableVisibleColumnWidthsSelector, tableGroupBySettingSelector, tableIsBoardViewSelector, 
// Archive Options
isUserBeingArchivedSelector, isRoleBeingArchivedSelector, isLocationBeingArchivedSelector, };
