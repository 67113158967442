import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { RatingSchemaIconType } from 'src/shared';
import { StarIcon } from 'src/utils/icons/RatingSchemaIcons/StarIcon';
import { CircleIcon } from 'src/utils/icons/RatingSchemaIcons/CircleIcon';
import { FlagIcon } from 'src/utils/icons/RatingSchemaIcons/FlagIcon';
import { HeartIcon } from 'src/utils/icons/RatingSchemaIcons/HeartIcon';
import { ThumbsUpIcon } from 'src/utils/icons/RatingSchemaIcons/ThumbsUpIcon';
var RatingSchemaCellContent = function (_a) {
    var iconType = _a.iconType, color = _a.color, currentValue = _a.currentValue, maxValueNumber = _a.maxValueNumber;
    var renderIcon = useCallback(function (color) {
        switch (iconType) {
            case RatingSchemaIconType.STAR:
                return _jsx(StarIcon, { fill: color });
            case RatingSchemaIconType.HEART:
                return _jsx(HeartIcon, { fill: color });
            case RatingSchemaIconType.THUMBS_UP:
                return _jsx(ThumbsUpIcon, { fill: color });
            case RatingSchemaIconType.FLAG:
                return _jsx(FlagIcon, { fill: color });
            case RatingSchemaIconType.CIRCLE:
                return _jsx(CircleIcon, { fill: color });
        }
    }, [iconType]);
    var generateIcons = useMemo(function () {
        return Array.from({ length: maxValueNumber }, function (_, index) {
            var value = currentValue !== null && currentValue !== void 0 ? currentValue : (maxValueNumber - 2 > 0 ? maxValueNumber - 2 : 1);
            var shouldFillWithColor = index + 1 <= value;
            return (_jsx("div", { style: { color: shouldFillWithColor ? color : undefined }, children: renderIcon(shouldFillWithColor ? color : undefined) }, index));
        });
    }, [color, currentValue, maxValueNumber, renderIcon]);
    return _jsx("div", { className: "flex gap-2", children: generateIcons });
};
export default React.memo(RatingSchemaCellContent);
