import { sumBy } from 'lodash';
import moment from 'moment-timezone';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { OPTIONS_COLOR } from 'src/shared';
import { getAccountStartDayOfWeek } from 'src/utils/Account';
import { SchedulingStatsOperationType } from '../Interfaces';
import { MAX_WEEK_INDEX_IN_NEEDS_COVERAGE } from './Needs&Coverage/ConfigModal/NeedsCoverageTemplateDetailSettingTable';
export var computeTotalStatsValue = function (statusData) {
    return Object.values(statusData).reduce(function (acc, cur) { return acc + cur; }, 0);
};
export var computeAverageStatValue = function (statusData) {
    if (Object.keys(statusData).length === 0)
        return 0;
    return computeTotalStatsValue(statusData) / Object.keys(statusData).length;
};
var convertToPercentNumber = function (num) {
    return (num * 100).toFixed(2) + '%';
};
export var convertSecondsToHours = function (num) {
    return (num / 3600).toFixed(2);
};
export var isCostMetric = function (key) {
    return key.includes('_COSTS');
};
export var isHoursMetric = function (key) {
    return key.includes('_HOURS');
};
export var isRateMetric = function (key) {
    return key.includes('_RATE');
};
export var renderDisplayValue = function (value, currencySign, stat) {
    if (!stat)
        return '-';
    var showPercent = isRateMetric(stat.key);
    var needConversion = isHoursMetric(stat.key);
    var isCurrency = isCostMetric(stat.key);
    //MARK: can't defined by (!value), otherwise if value is 0, it will be true
    if (value === undefined)
        return '-';
    if (showPercent) {
        return convertToPercentNumber(value);
    }
    else if (needConversion) {
        return convertSecondsToHours(value);
    }
    var displayValue = isCurrency ? value === null || value === void 0 ? void 0 : value.toFixed(2) : value;
    return "".concat(isCurrency ? currencySign : '', " ").concat(displayValue);
};
export var convertDayOfWeekKeyToString = function (dayOfWeek) {
    return ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'][dayOfWeek];
};
export var calculateDiffBetweenMomentConsideringDaylightSaving = function (start, end, unit, timezone) {
    var accountStartDayOfWeek = getAccountStartDayOfWeek();
    var startMoment = moment.tz(start, timezone).day(accountStartDayOfWeek);
    var endMoment = moment.tz(end, timezone).day(accountStartDayOfWeek);
    return startMoment.diff(endMoment, unit, false);
};
export var intervalOptions = function () {
    var options = [];
    for (var i = 1; i <= MAX_WEEK_INDEX_IN_NEEDS_COVERAGE + 1; i++) {
        options.push({
            value: i,
            label: i === 1 ? 'Weekly' : i === 2 ? 'Bi-Weekly' : "".concat(i, "-Week"),
        });
    }
    return options;
};
export var getScheduleTemplateCountStatColor = function (actual, expected) {
    var percentage = (actual / expected) * 100;
    if (percentage < 100) {
        // Red (Understaffed)
        return OPTIONS_COLOR[6];
    }
    else if (percentage > 100) {
        // Yellow (Overstaffed)
        return OPTIONS_COLOR[4];
    }
    else {
        // Green (Staffed)
        return OPTIONS_COLOR[3];
    }
};
export var getScheduleTemplateCountStatStatus = function (actual, expected) {
    var percentage = (actual / expected) * 100;
    if (percentage < 100) {
        // Red (Understaffed)
        return 'Understaffed';
    }
    else if (percentage > 100) {
        // Yellow (Overstaffed)
        return 'Overstaffed';
    }
    else {
        // Green (Staffed)
        return 'Staffed';
    }
};
export var getScheduleTemplateTotalsCountStat = function (stats, isBudgeted) {
    var totalsStat = {
        templateId: 'template-totals',
        stats: {},
    };
    stats.forEach(function (stat) {
        Object.keys(stat.stats).forEach(function (key) {
            var _a, _b;
            if (!totalsStat.stats[key]) {
                totalsStat.stats[key] = {
                    actual: 0,
                    expected: 0,
                };
            }
            totalsStat.stats[key].actual += stat.stats[key].actual;
            totalsStat.stats[key].expected += isBudgeted
                ? stat.stats[key].expected
                : ((_b = (_a = stat.stats[key].manuallyUpdated) === null || _a === void 0 ? void 0 : _a.expected) !== null && _b !== void 0 ? _b : stat.stats[key].expected);
        });
    });
    return totalsStat;
};
var trimStatKey = function (key) {
    return key.split('_')[0];
};
export var generateScheduleStatOptionName = function (stat) {
    // Single metric
    if (!stat.second) {
        return upperCaseStringToStartCase(stat.first);
    }
    // Combo Metric
    else {
        var first = upperCaseStringToStartCase(trimStatKey(stat.first));
        var second = upperCaseStringToStartCase(trimStatKey(stat.second));
        if (stat.operation === SchedulingStatsOperationType.PERCENTAGE) {
            return "".concat(first, " % of ").concat(second, " Hours");
        }
        else if (stat.operation === SchedulingStatsOperationType.COMPARE) {
            if (isCostMetric(stat.first) && isCostMetric(stat.second)) {
                return "".concat(first, " vs ").concat(second, " Costs");
            }
            else if (isHoursMetric(stat.first) && isHoursMetric(stat.second)) {
                return "".concat(first, " vs ").concat(second, " Hours");
            }
            else {
                return "".concat(upperCaseStringToStartCase(stat.first), " vs ").concat(upperCaseStringToStartCase(second));
            }
        }
    }
    return upperCaseStringToStartCase(stat.first);
};
export var generateShiftTemplateListInOrder = function (templateListResp) {
    if (!templateListResp)
        return [];
    var list = [];
    if (templateListResp.settings && templateListResp.settings.order.length !== 0) {
        templateListResp.settings.order.map(function (id) {
            var template = templateListResp.data[id];
            if (template)
                list.push(template);
        });
    }
    else {
        Object.values(templateListResp.data).map(function (template) {
            if (template)
                list.push(template);
        });
    }
    return list;
};
var descriptionMap = {
    SCHEDULED_HOURS: 'All scheduled hours',
    TOTAL_SHIFTS: 'Number of total Scheduled + open shifts, by day',
    SHOW_RATE: 'Percent of Shifts with a Clock in Time vs. Total Shifts Scheduled',
    WORKED_HOURS: 'Number of worked hours',
    TOTAL_HOURS: 'Total hours scheduled',
    BUDGETED_COSTS: 'Estimated cost calculation based on Budgeted Needs and Average Wage',
    BUDGETED_HOURS: 'Estimated hours calculation based on Budgeted Needs',
    COMPLETED_SHIFTS: 'Number of shifts with "Complete" Status',
    LABOR_COSTS: 'Number of scheduled hours multiplied by Wage',
    NO_SHOW_SHIFTS: 'Number of Shifts with "No Show" Status',
    OPEN_SHIFTS: 'Number of unclaimed Shifts',
    OVERTIME_COSTS: 'Hours worked multiplied by overtime premium cost of employee',
    OVERTIME_HOURS: 'Number of Overtime Hours worked and scheduled Overtime hours',
    OVERTIME_SHIFTS: 'Number of Shifts with any hours of overtime',
    SCHEDULED_COSTS: 'Future Scheduled Cost: Scheduled Hours multiplied by Employee Wage, considering Overtime Wage for Overtime Hours',
    WORKED_HOURS_SCHEDULED_HOURS: 'Number of Worked Hours (based on time punches) vs. estimated scheduled hours',
    LABOR_COSTS_SCHEDULED_COSTS: 'Number of Worked Hours multiplied by Wage vs. number of Budget Hours multiplied by Average Wage',
    WORKED_HOURS_BUDGETED_HOURS: 'Number of Worked Hours (based on time punches) vs. estimated number of Budget Hours (based on needs)',
    LABOR_COSTS_BUDGETED_COSTS: 'Number of Worked Hours multiplied by Wage vs. estimated number of Budget Hours multiplied by Average Wage',
    OVERTIME_SHIFTS_TOTAL_SHIFTS: 'Number of Shifts with any hours of Overtime vs. number of total Scheduled and Open Shifts, by day',
    OVERTIME_COSTS_SCHEDULED_COSTS: 'Hours worked multiplied by overtime premium cost of employee vs. estimated future Scheduled Cost',
    SCHEDULED_COSTS_BUDGETED_COSTS: 'Estimated cost calculation based on budgeted needs and average wage vs. estimated cost calculation based on budgeted needs',
};
export var generateDescription = function (stat) {
    if (stat.second) {
        var combinedKey = "".concat(stat.first, "_").concat(stat.second);
        return descriptionMap[combinedKey];
    }
    return descriptionMap[stat.first] || 'Description not found';
};
export var calculateTotalActualHours = function (timeRange, stats) {
    var totalHoursData = {};
    timeRange.slice(0, -1).map(function (time) {
        var date = moment(time).format('YYYY-MM-DD');
        var totalSecondsForAllRole = sumBy(stats, function (role) { var _a, _b, _c; return (_c = (_b = (_a = role.data) === null || _a === void 0 ? void 0 : _a[date]) === null || _b === void 0 ? void 0 : _b.actualSeconds) !== null && _c !== void 0 ? _c : 0; });
        totalHoursData[date] = Number(convertSecondsToHours(totalSecondsForAllRole));
    });
    return totalHoursData;
};
export var calculateTotalHours = function (timeRange, hppdData, censusData) {
    var totalHoursData = {};
    timeRange.slice(0, -1).map(function (time) {
        var _a;
        var date = moment(time).format('YYYY-MM-DD');
        var censusValue = (_a = censusData[date]) !== null && _a !== void 0 ? _a : 0;
        var totalHoursForAllRole = 0;
        hppdData.map(function (role) {
            var ratio = role.data;
            var value = censusValue * ratio;
            var newValue = totalHoursForAllRole + value;
            totalHoursForAllRole = newValue;
        });
        totalHoursData[date] = Number(totalHoursForAllRole.toFixed(2));
    });
    return totalHoursData;
};
export var getDemandRatioHoursStatColor = function (actual, expected) {
    var percentage = (actual / expected) * 100;
    if (percentage < 95) {
        // Red (Understaffed)
        return OPTIONS_COLOR[6];
    }
    else if (percentage > 105) {
        // Yellow (Overstaffed)
        return OPTIONS_COLOR[4];
    }
    else {
        // Green (Staffed)
        return OPTIONS_COLOR[3];
    }
};
