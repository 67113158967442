var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { isArray } from 'lodash';
import { useRecordComponent, useWindowSize } from 'src/hooks/component';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { useRecoilValue } from 'recoil';
import { getNativeFieldCellContent, getNativeFieldCellOptions, getTagTextColor, } from '../../../utils/Collection';
import { NativePath, CollectionType } from '../../../shared';
import UserDetailMetricsRow from './Metrics/UserDetailMetricsRow';
import ShiftDetailHeaderActions from './Actions/Shift/ShiftDetailHeaderActions';
import RecordDetailModalActions from './Actions/RecordDetailModalActions';
import RecordDetailModalTitle from './Titles/RecordDetailModalTitle';
import ShiftDetailModalTitle from './Titles/Shift/ShiftDetailModalTitle';
import TimeOffDetailModalTitle from './Titles/TimeOff/TimeOffDetailModalTitle';
import DocumentDetailModalTitle from './Titles/Document/DocumentDetailModalTitle';
import ContactDetailModalTitle from './Titles/Contact/ContactDetailModalTitle';
import CustomDetailModalTitle from './Titles/Custom/CustomDetailModalTitle';
import LocationDetailModalTitle from './Titles/Location/LocationDetailModalTitle';
import RoleDetailModalTitle from './Titles/Role/RoleDetailModalTitle';
import UserDetailModalTitle from './Titles/User/UserDetailModalTitle';
import LocationDetailHeaderActions from './Actions/Location/LocationDetailHeaderActions';
import ShiftDetailOpenCount from './Extras/Shift/ShiftDetailOpenCount';
import UserDetailHeaderActions from './Actions/User/UserDetailHeaderActions';
import TaskDetailModalTitle from './Titles/Task/TaskDetailModalTitle';
import ShiftGroupModalTitle from './Titles/ShiftGroup/ShiftGroupModalTitle';
import ShiftDetailCreateGroup from './Extras/Shift/ShiftDetailCreateGroup';
import PlacementDetailModalTitle from './Titles/Placement/PlacementDetailModalTitle';
import JobDetailModalTitle from './Titles/Job/JobDetailModalTitle';
import RecordDetailMetricsSchemasSection from './RecordDetailMetricsSchemasSection';
import TaskDetailHeaderActions from './Actions/Task/TaskDetailHeaderActions';
export var TagStatusColor = {
    red: '#FFEDEC',
    green: '#E5F4EF',
    grey: '#F4F4F7',
};
var RecordDetailModalHeaderWrapper = function (_a) {
    var isCreatingNew = _a.isCreatingNew, recordId = _a.recordId, dataSource = _a.dataSource, collectionType = _a.collectionType, canEditCollection = _a.canEditCollection, bulkMode = _a.bulkMode, defaultTimeZone = _a.defaultTimeZone, onCloseModal = _a.onCloseModal, recordColor = _a.recordColor, shiftDetailProps = _a.shiftDetailProps, userDetailProps = _a.userDetailProps;
    var _b = shiftDetailProps !== null && shiftDetailProps !== void 0 ? shiftDetailProps : {}, shiftTimeDetails = _b.shiftTimeDetails, duplicateAction = _b.duplicateAction, shiftFacePicData = _b.shiftFacePicData, onApplyTemplateFields = _b.onApplyTemplateFields, onApplyOldTemplateFields = _b.onApplyOldTemplateFields, onDetachExistingTemplate = _b.onDetachExistingTemplate, recurringShiftAction = _b.recurringShiftAction, shiftTemplateData = _b.shiftTemplateData;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var _c = useWindowSize().windowWidth, windowWidth = _c === void 0 ? 0 : _c;
    var isWideScreenView = windowWidth > 1200;
    var addColorToSingleRecord = useRecordComponent().addColorToSingleRecord;
    var _d = useState(recordColor), currentColor = _d[0], setCurrentColor = _d[1];
    var _e = useState(false), colorSelectorVisible = _e[0], setColorSelectorVisible = _e[1];
    var onUpdateRecordColor = useCallback(function (color) { return __awaiter(void 0, void 0, void 0, function () {
        var isSuccess;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addColorToSingleRecord(dataSource.collectionId, recordId, color)];
                case 1:
                    isSuccess = _a.sent();
                    if (isSuccess) {
                        setCurrentColor(color);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [addColorToSingleRecord, dataSource.collectionId, recordId]);
    var modalHeaderRef = useRef(null);
    // For Role/User detail
    var renderActiveOrNotTag = useCallback(function (path) {
        var _a, _b;
        var status = (_a = getNativeFieldCellContent(modalRecord, nativeSchemaMap, path)) !== null && _a !== void 0 ? _a : getNativeFieldCellOptions(modalRecord, nativeSchemaMap, path);
        var statusValue = isArray(status) ? (_b = status[0]) === null || _b === void 0 ? void 0 : _b.id : status;
        if (statusValue) {
            var isTrue = statusValue === 'true';
            var backgroundColor = isTrue ? TagStatusColor.green : TagStatusColor.red;
            var text = isTrue ? 'Active' : 'Inactive';
            if (path === NativePath.USER_ARCHIVED) {
                backgroundColor = isTrue ? TagStatusColor.red : TagStatusColor.green;
                text = isTrue ? 'Inactive' : 'Active';
            }
            return (_jsx("span", { className: "RecordStatusTag", style: {
                    backgroundColor: backgroundColor,
                    color: getTagTextColor(backgroundColor),
                }, children: text }));
        }
        return null;
    }, [modalRecord, nativeSchemaMap]);
    var renderModalTitle = useCallback(function () {
        if (bulkMode)
            return _jsx(RecordDetailModalTitle, { title: "Bulk Edit" });
        // For role/location/custom title
        var recordColorSelectorProps = !isCreatingNew
            ? {
                currentColor: currentColor,
                visible: colorSelectorVisible,
                onVisibleChange: setColorSelectorVisible,
                onUpdateRecordColor: onUpdateRecordColor,
            }
            : undefined;
        switch (collectionType) {
            case CollectionType.SHIFT: {
                return (_jsx(ShiftDetailModalTitle, { recordId: recordId, isCreatingNew: isCreatingNew, dataSource: dataSource, shiftTimeDetails: shiftTimeDetails, isBulkEdit: bulkMode, defaultTimeZone: defaultTimeZone, shiftFacePicData: shiftFacePicData, recurringShiftData: recurringShiftAction === null || recurringShiftAction === void 0 ? void 0 : recurringShiftAction.recurringShiftData, shiftTemplateData: shiftTemplateData, onDetachExistingTemplate: onDetachExistingTemplate, timeZoneId: defaultTimeZone, canEditCollection: canEditCollection }));
            }
            case CollectionType.USER: {
                var avatarUrl = (userDetailProps !== null && userDetailProps !== void 0 ? userDetailProps : {}).avatarUrl;
                return (_jsx(UserDetailModalTitle, { dataSource: dataSource, renderActiveOrNotTag: renderActiveOrNotTag, avatarUrl: avatarUrl, recordColor: recordColor, modalHeaderRef: modalHeaderRef }));
            }
            case CollectionType.BREAK: {
                return _jsx(RecordDetailModalTitle, { title: "Break" });
            }
            case CollectionType.TIMEOFF: {
                return _jsx(TimeOffDetailModalTitle, { dataSource: dataSource });
            }
            case CollectionType.LOCATION: {
                return (_jsx(LocationDetailModalTitle, { dataSource: dataSource, recordColorSelectorProps: recordColorSelectorProps }));
            }
            case CollectionType.ROLE: {
                return (_jsx(RoleDetailModalTitle, { dataSource: dataSource, recordColorSelectorProps: recordColorSelectorProps, renderActiveOrNotTag: renderActiveOrNotTag }));
            }
            case CollectionType.CONTACT: {
                return _jsx(ContactDetailModalTitle, { dataSource: dataSource });
            }
            case CollectionType.DOCUMENT: {
                return _jsx(DocumentDetailModalTitle, { dataSource: dataSource });
            }
            case CollectionType.TASK: {
                return _jsx(TaskDetailModalTitle, { dataSource: dataSource });
            }
            case CollectionType.SHIFT_GROUP: {
                return (_jsx(ShiftGroupModalTitle, { dataSource: dataSource, recordColorSelectorProps: recordColorSelectorProps }));
            }
            case CollectionType.PLACEMENT: {
                return _jsx(PlacementDetailModalTitle, { dataSource: dataSource });
            }
            case CollectionType.JOB: {
                return _jsx(JobDetailModalTitle, { dataSource: dataSource });
            }
            default: {
                return (_jsx(CustomDetailModalTitle, { dataSource: dataSource, recordColorSelectorProps: recordColorSelectorProps }));
            }
        }
    }, [
        bulkMode,
        collectionType,
        colorSelectorVisible,
        currentColor,
        dataSource,
        defaultTimeZone,
        isCreatingNew,
        onDetachExistingTemplate,
        onUpdateRecordColor,
        recordColor,
        recurringShiftAction === null || recurringShiftAction === void 0 ? void 0 : recurringShiftAction.recurringShiftData,
        renderActiveOrNotTag,
        shiftFacePicData,
        shiftTemplateData,
        shiftTimeDetails,
        userDetailProps,
        recordId,
        canEditCollection,
    ]);
    var shiftTemplateSchema = nativeSchemaMap[NativePath.SHIFT_TEMPLATE_ID];
    var recordExistingTemplateInfo = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_TEMPLATE_ID)[0];
    var canEditShiftTemplate = !shiftTemplateSchema
        ? false
        : shiftTemplateSchema.canEdit &&
            (!recordExistingTemplateInfo || !shiftTemplateSchema.canOnlyUpdateEmpty);
    var extraActions = (_jsxs(_Fragment, { children: [collectionType === CollectionType.SHIFT && shiftDetailProps && (_jsx(ShiftDetailHeaderActions, { recordId: recordId, dataSource: dataSource, duplicateAction: duplicateAction, onApplyTemplateFields: onApplyTemplateFields, onApplyOldTemplateFields: onApplyOldTemplateFields, recurringShiftAction: recurringShiftAction, canEditShiftTemplate: canEditShiftTemplate && !bulkMode })), collectionType === CollectionType.LOCATION && !isCreatingNew && (_jsx(LocationDetailHeaderActions, { recordId: recordId })), collectionType === CollectionType.USER && !isCreatingNew && (_jsx(UserDetailHeaderActions, { recordId: recordId })), collectionType === CollectionType.TASK && !isCreatingNew && (_jsx(TaskDetailHeaderActions, { recordId: recordId, collectionId: dataSource.collectionId, collectionRecord: modalRecord }))] }));
    var extraHeaderContent = useMemo(function () {
        switch (collectionType) {
            case CollectionType.SHIFT: {
                // Show open count if no assignee
                var showOpenCount = !getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_USER_ID)[0];
                return showOpenCount ? (_jsxs(_Fragment, { children: [_jsx(ShiftDetailOpenCount, { recordId: recordId, dataSource: dataSource, canEdit: canEditCollection }), _jsx(ShiftDetailCreateGroup, { isCreatingNew: isCreatingNew, recordId: recordId, dataSource: dataSource, canEdit: canEditCollection })] })) : (_jsx(ShiftDetailCreateGroup, { isCreatingNew: isCreatingNew, recordId: recordId, dataSource: dataSource, canEdit: canEditCollection }));
            }
            default:
                return null;
        }
    }, [
        collectionType,
        modalRecord,
        nativeSchemaMap,
        isCreatingNew,
        recordId,
        dataSource,
        canEditCollection,
    ]);
    return (_jsx("div", { className: "ModalHeaderWrapper", children: _jsxs("div", { className: "ModalHeader", ref: modalHeaderRef, children: [renderModalTitle(), canEditCollection && (_jsx(RecordDetailModalActions, { collectionId: dataSource.collectionId, recordId: recordId, extraActions: extraActions, onClose: function () { return onCloseModal(true); } })), _jsx("div", { className: "ModalHeaderExtraContent", children: extraHeaderContent }), !isCreatingNew && !bulkMode && isWideScreenView && (_jsx(RecordDetailMetricsSchemasSection, { collectionId: dataSource.collectionId, collectionType: collectionType, recordId: recordId, extraMetrics: collectionType === CollectionType.USER ? (_jsx(UserDetailMetricsRow, { userRecordId: recordId })) : undefined }))] }) }));
};
export default React.memo(RecordDetailModalHeaderWrapper);
