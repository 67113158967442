export var OvertimeRuleType;
(function (OvertimeRuleType) {
    OvertimeRuleType["DAILY"] = "DAILY";
    OvertimeRuleType["WEEKLY"] = "WEEKLY";
    OvertimeRuleType["DAY_OF_WEEK"] = "DAY_OF_WEEK";
    OvertimeRuleType["SPECIAL"] = "SPECIAL";
})(OvertimeRuleType || (OvertimeRuleType = {}));
export var SpecialOvertimeRuleWithNoMultiplier;
(function (SpecialOvertimeRuleWithNoMultiplier) {
    SpecialOvertimeRuleWithNoMultiplier["SEVEN_DAY_RULE"] = "SEVEN_DAY_RULE";
    SpecialOvertimeRuleWithNoMultiplier["SEVEN_MINUTE_RULE"] = "SEVEN_MINUTE_RULE";
    SpecialOvertimeRuleWithNoMultiplier["ROUND_SHIFT_RULE"] = "ROUND_SHIFT_RULE";
})(SpecialOvertimeRuleWithNoMultiplier || (SpecialOvertimeRuleWithNoMultiplier = {}));
export var SpecialOvertimeRuleEnumsAsArray = [
    'SEVEN_DAY_RULE',
    'SEVEN_MINUTE_RULE',
    'ROUND_SHIFT_RULE',
];
export var SpecialOvertimeRuleWithMultiplier;
(function (SpecialOvertimeRuleWithMultiplier) {
    SpecialOvertimeRuleWithMultiplier["US_HOLIDAYS"] = "US_HOLIDAYS";
})(SpecialOvertimeRuleWithMultiplier || (SpecialOvertimeRuleWithMultiplier = {}));
export var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek["MONDAY"] = "MONDAY";
    DayOfWeek["TUESDAY"] = "TUESDAY";
    DayOfWeek["WEDNESDAY"] = "WEDNESDAY";
    DayOfWeek["THURSDAY"] = "THURSDAY";
    DayOfWeek["FRIDAY"] = "FRIDAY";
    DayOfWeek["SATURDAY"] = "SATURDAY";
    DayOfWeek["SUNDAY"] = "SUNDAY";
})(DayOfWeek || (DayOfWeek = {}));
export var CarryOverLimitTypes = ['ASSIGNED_DATE', 'DATE_SCHEMA', 'AT_ANY_TIME'];
export var CarryOverLimitTypeToLabel = function (type) {
    if (type === 'ASSIGNED_DATE') {
        return 'custom date';
    }
    if (type === 'DATE_SCHEMA') {
        return "user's anniversary of";
    }
    if (type === 'AT_ANY_TIME') {
        return 'at any time';
    }
};
export var OvertimeCalculationSetting;
(function (OvertimeCalculationSetting) {
    OvertimeCalculationSetting["REGULAR"] = "REGULAR";
    OvertimeCalculationSetting["PER_LOCATION"] = "PER_LOCATION";
})(OvertimeCalculationSetting || (OvertimeCalculationSetting = {}));
