var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Divider } from 'antd';
import { useRecoilValue } from 'recoil';
import { currentGeneralPermissions, isCurrentUserOwnerPermission } from 'src/state';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { isInternalUserSelector } from 'src/state/selectors/withAccountDetailState';
import { OrganizationContext } from 'src/App';
import { CHAT_FEATURE_GATING_KEY, NEEDS_COVERAGE, NEW_SHIFT_TEMPLATE, } from '../FeatureGatingKeyConstant';
var SettingsSider = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var pathname = location.pathname;
    var generalPermissions = useRecoilValue(currentGeneralPermissions);
    var isOwner = useRecoilValue(isCurrentUserOwnerPermission);
    var isChatFeatureEnabled = useRecoilValue(featureEnableStatusState(CHAT_FEATURE_GATING_KEY));
    var isNewShiftTemplateEnabled = useRecoilValue(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
    var isNeedsCoverageFeatureEnabled = useRecoilValue(featureEnableStatusState(NEEDS_COVERAGE));
    var isNeedsCoverageEnabled = isNeedsCoverageFeatureEnabled && isNewShiftTemplateEnabled;
    var isInternalUser = useRecoilValue(isInternalUserSelector);
    var orgMode = useContext(OrganizationContext);
    var paySettingsRoute = __spreadArray(__spreadArray([], ((generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.PAY)
        ? [
            {
                title: 'Pay Rates',
                url: '/settings/payrates',
            },
            {
                title: 'Holiday Pay',
                url: '/settings/holiday',
            },
            {
                title: 'Differentials',
                url: '/settings/differentials',
            },
            {
                title: 'Attestations',
                url: '/settings/attestation',
            },
        ]
        : []), true), ((generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.PAY_PERIODS)
        ? [
            {
                title: 'Pay Periods',
                url: '/settings/payperiods',
            },
        ]
        : []), true);
    var accountExtraSettingsRoute = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], ((generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.ACCESS_GROUPS)
        ? [
            {
                title: 'Access Groups',
                url: '/settings/access_groups',
            },
        ]
        : []), true), ((generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.BUILD_DATA)
        ? [
            {
                title: 'Database',
                url: '/settings/collections',
            },
        ]
        : []), true), ((generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.INTEGRATIONS)
        ? [
            {
                title: 'Integrations',
                url: '/settings/integrations',
            },
        ]
        : []), true), ((generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.BILLING)
        ? [
            {
                title: 'Billing',
                url: '/settings/billing',
            },
        ]
        : []), true);
    var toolsSettingsRoute = __spreadArray(__spreadArray([], (isOwner && isChatFeatureEnabled
        ? [
            {
                title: 'Team Chat',
                url: '/settings/team_chat',
            },
        ]
        : []), true), (isOwner && isNeedsCoverageEnabled
        ? [
            {
                title: 'Scheduling',
                url: '/settings/scheduling',
            },
        ]
        : []), true);
    return (_jsxs("div", { className: "SettingsSider", children: [_jsx("div", { className: "SiderTitle", children: "Settings" }), (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.EDIT_ACCOUNT) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "SettingsSiderHeading", children: "General" }), _jsx("div", { className: 'SettingsBtnWrapper ' +
                            (pathname == '/settings/business' ? 'Selected' : ''), onClick: function () {
                            navigate("/settings/business");
                        }, children: "Basic Details" }), isInternalUser && (_jsx("div", { className: 'SettingsBtnWrapper ' +
                            (pathname == '/settings/branding' ? 'Selected' : ''), onClick: function () {
                            navigate("/settings/branding");
                        }, children: "Branding" })), _jsx("div", { className: 'SettingsBtnWrapper ' +
                            (pathname == '/settings/dashboard' ? 'Selected' : ''), onClick: function () {
                            navigate("/settings/dashboard");
                        }, children: "Dashboard" }), _jsx(Divider, { className: "SettingsSiderDivider" })] })), (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.TIME_TRACKING) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "SettingsSiderHeading", children: "Time Tracking" }), !orgMode && (_jsx("div", { className: 'SettingsBtnWrapper ' +
                            (pathname == '/settings/timeclock' ? 'Selected' : ''), onClick: function () {
                            navigate('/settings/timeclock');
                        }, children: "Time Clock" })), _jsx("div", { className: 'SettingsBtnWrapper ' +
                            (pathname == '/settings/overtime' ? 'Selected' : ''), onClick: function () {
                            navigate('/settings/overtime');
                        }, children: "Overtime" }), _jsx("div", { className: 'SettingsBtnWrapper ' +
                            (pathname == '/settings/breaks' ? 'Selected' : ''), onClick: function () {
                            navigate('/settings/breaks');
                        }, children: "Breaks" }), _jsx("div", { className: 'SettingsBtnWrapper ' +
                            (pathname == '/settings/timeoff' ? 'Selected' : ''), onClick: function () {
                            navigate('/settings/timeoff');
                        }, children: "Time Off" }), _jsx("div", { className: 'SettingsBtnWrapper ' +
                            (pathname == '/settings/geofence' ? 'Selected' : ''), onClick: function () {
                            navigate("/settings/geofence");
                        }, children: "Geofences" }), _jsx(Divider, { className: "SettingsSiderDivider" })] })), !!paySettingsRoute.length && _jsx("div", { className: "SettingsSiderHeading", children: "Pay" }), paySettingsRoute.map(function (item) { return (_jsx("div", { className: 'SettingsBtnWrapper ' + (pathname == item.url ? 'Selected' : ''), onClick: function () {
                    navigate(item.url);
                }, children: item.title }, item.title)); }), !!paySettingsRoute.length && _jsx(Divider, { className: "SettingsSiderDivider" }), !!toolsSettingsRoute.length && _jsx("div", { className: "SettingsSiderHeading", children: "Tools" }), toolsSettingsRoute.map(function (item) { return (_jsx("div", { className: 'SettingsBtnWrapper ' + (pathname == item.url ? 'Selected' : ''), onClick: function () {
                    navigate(item.url);
                }, children: item.title }, item.title)); }), !!toolsSettingsRoute.length && _jsx(Divider, { className: "SettingsSiderDivider" }), !!accountExtraSettingsRoute.length && (_jsx("div", { className: "SettingsSiderHeading", children: "Account" })), accountExtraSettingsRoute.map(function (item) { return (_jsx("div", { className: 'SettingsBtnWrapper ' + (pathname == item.url ? 'Selected' : ''), onClick: function () {
                    navigate(item.url);
                }, children: item.title }, item.title)); })] }));
};
export default React.memo(SettingsSider);
