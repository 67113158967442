var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var FlagIconSvg = function (props) { return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M18.9997 6.66634H17.333V5.66634C17.333 4.37967 16.2863 3.33301 14.9997 3.33301H5.33301V13.333H14.861C15.1583 13.333 15.3063 13.693 15.097 13.9023L12.8663 16.133C13.269 16.461 13.7743 16.6663 14.333 16.6663H18.9997C20.2863 16.6663 21.333 15.6197 21.333 14.333V8.99967C21.333 7.71301 20.2863 6.66634 18.9997 6.66634Z", fill: props.fill || '#A7A8AC' }), _jsx("path", { d: "M5 22.6663C4.448 22.6663 4 22.2183 4 21.6663V2.33301C4 1.78101 4.448 1.33301 5 1.33301C5.552 1.33301 6 1.78101 6 2.33301V21.6663C6 22.2183 5.552 22.6663 5 22.6663Z", fill: props.fill || '#A7A8AC' })] })); };
export var FlagIcon = function (props) { return (_jsx(Icon, __assign({ component: function () { return _jsx(FlagIconSvg, __assign({}, props)); } }, props))); };
