var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import '../FeedContainer.scss';
import { Collapse } from 'antd';
import moment from 'moment';
var Panel = Collapse.Panel;
import ActivityFeedState from 'src/hooks/state/ActivityFeed';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import { ArrowDownOutlined } from '@ant-design/icons';
import { useAccountHistoryComponent, useTimeZone } from 'src/hooks/component';
import { useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { TaskRuleInfoProperty, TaskTemplateInfoProperty } from '../../Interface';
import { DownArrowOutlinedIcon } from '../../../../utils/icons/DownArrowOutlined';
import { renderAuthorEmployeeIcon } from '../../../../utils/Account';
import { getTaskTypeDisplayText } from './util';
var ActivityFeedPageSize = 100;
var ActivityFeedEvents = function (props) {
    var collapsed = props.collapsed, subjectId = props.subjectId, actorType = props.actorType, onOpenRecordDetail = props.onOpenRecordDetail, actorIds = props.actorIds;
    var _a = useState(moment().toISOString()), fromTimestamp = _a[0], setFromTimestamp = _a[1];
    var _b = useState(true), canLoadMore = _b[0], setCanLoadMore = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var firstTimeLoadData = useRef(true);
    var hasScrollBar = useCallback(function () {
        var div = document.getElementsByClassName('activityEvents')[0];
        return (div === null || div === void 0 ? void 0 : div.scrollHeight) > (div === null || div === void 0 ? void 0 : div.clientHeight);
    }, []);
    var _d = ActivityFeedState.useContainer(), getEventSummaryText = _d.getEventSummaryText, collectionFilters = _d.collectionFilters, actorFilters = _d.actorFilters, eventFilters = _d.eventFilters;
    var accountId = useRecoilValue(accountIdState);
    var getAccountHistory = useAccountHistoryComponent().getAccountHistory;
    var _e = useState([]), activityFeedData = _e[0], setActivityFeedData = _e[1];
    var timeFormatFn = useTimeZone().timeFormatFn;
    var getActivityFeedData = useCallback(function (reset) { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!canLoadMore && !reset && !isLoading)
                        return [2 /*return*/];
                    setIsLoading(true);
                    if (reset)
                        firstTimeLoadData.current = true;
                    return [4 /*yield*/, getAccountHistory({
                            accountId: accountId,
                            subjectId: subjectId,
                            filters: {
                                collectionIds: collectionFilters,
                                actorTypes: actorFilters.length == 0 && actorType ? [actorType] : actorFilters,
                                eventTypes: eventFilters,
                                actorIds: actorIds,
                            },
                            from: reset ? moment().toISOString() : fromTimestamp,
                            pageSize: ActivityFeedPageSize,
                        })];
                case 1:
                    resp = _a.sent();
                    if (resp) {
                        setActivityFeedData(reset ? resp.accountHistories : activityFeedData.concat(resp.accountHistories));
                        if (resp.nextFrom) {
                            setFromTimestamp(resp.nextFrom);
                        }
                        setCanLoadMore(resp.size === ActivityFeedPageSize);
                    }
                    setIsLoading(false);
                    if (reset)
                        firstTimeLoadData.current = false;
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        accountId,
        activityFeedData,
        actorFilters,
        canLoadMore,
        collectionFilters,
        eventFilters,
        fromTimestamp,
        isLoading,
        subjectId,
    ]);
    // Initial and filter change fetch
    useEffect(function () {
        getActivityFeedData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, actorFilters, collectionFilters, eventFilters, subjectId, actorType, collapsed]);
    var handlePaginationScroll = useCallback(function (event) {
        event.stopPropagation();
        if (hasScrollBar()) {
            var atBottom = event.target.scrollHeight -
                event.target.scrollTop -
                event.target.clientHeight <=
                50;
            if (!isLoading && atBottom && canLoadMore) {
                getActivityFeedData();
            }
        }
    }, [canLoadMore, getActivityFeedData, hasScrollBar, isLoading]);
    var genHeader = useCallback(function (events) {
        var _a;
        var timeAgo = timeFormatFn(events[0].recordedAt, 'MMM D', false);
        var actorIconData = {
            actorType: events[0].content.actor.type,
            authorTitle: events[0].content.actor.name,
            authorIcon: (_a = events[0].content.actor.avatarUrl) !== null && _a !== void 0 ? _a : '',
        };
        var summaryText = getEventSummaryText(events);
        var timeStamp = timeFormatFn(events[0].recordedAt, 'h:mm a', true);
        var canOpenActorRecordDetail = events[0].content.actor.recordId && onOpenRecordDetail;
        return (_jsx("div", { className: "activityOuterContainer", children: _jsxs("div", { className: "activityInnerContainer", children: [_jsx("div", { className: "activityAvatar", children: renderAuthorEmployeeIcon(actorIconData, true) }), _jsxs("div", { className: "activityDescription", children: [_jsxs("span", { className: "eventDescription font-medium", children: [_jsx(ZiraTooltip, { title: canOpenActorRecordDetail
                                            ? "Open ".concat(actorIconData.authorTitle, "'s record details")
                                            : undefined, children: _jsx("span", { className: "font-semibold".concat(canOpenActorRecordDetail
                                                ? ' cursor-pointer hover:underline'
                                                : ''), onClick: function (e) {
                                                if (canOpenActorRecordDetail) {
                                                    e.stopPropagation();
                                                    onOpenRecordDetail(events[0].content.actor.recordId);
                                                }
                                            }, children: "".concat(actorIconData.authorTitle) }) }), summaryText] }), _jsx("span", { className: "timeAgo text-gray-400", children: "".concat(timeAgo, " at ").concat(timeStamp) })] })] }) }));
    }, [getEventSummaryText, onOpenRecordDetail, timeFormatFn]);
    var renderContentType = useCallback(function (event) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73;
        var messageContent = ((_a = event.content) === null || _a === void 0 ? void 0 : _a.messageContent) || '';
        var elements;
        // Avoids using dangerouslySetInnerHTML
        if (((_b = event.content) === null || _b === void 0 ? void 0 : _b.type) === 'email_sent') {
            var lines = messageContent.split('<br/>');
            elements = lines.map(function (part, index) { return (_jsxs(Fragment, { children: [index > 0 && _jsx("br", {}), part] }, index)); });
        }
        switch (event.content.type) {
            case 'email_sent':
                return (_jsxs("div", { children: [!!event.content.recipients && (_jsxs(_Fragment, { children: ['Sent an email to ', _jsx("span", { className: "bold", children: event.content.recipients
                                        .map(function (recipient) {
                                        if (recipient.user)
                                            return recipient.user.name;
                                        else
                                            return recipient.emailOrPhone;
                                    })
                                        .join(', ') }), _jsx("br", {})] })), 'Email: ', _jsx("span", { className: "", children: elements })] }));
            case 'sms_sent':
                return (_jsxs("div", { children: [!!event.content.recipients && (_jsxs(_Fragment, { children: ['Sent an SMS to ', _jsx("span", { className: "bold", children: event.content.recipients
                                        .map(function (recipient) {
                                        if (recipient.user)
                                            return recipient.user.name;
                                        else
                                            return recipient.emailOrPhone;
                                    })
                                        .join(', ') }), _jsx("br", {})] })), 'Message: ', _jsx("span", { className: "", children: messageContent })] }));
            case 'in_app_message':
                return (_jsxs("div", { children: ['Message: ', _jsx("span", { className: "", children: messageContent })] }));
            case 'break_created':
                return (_jsxs("div", { children: ['Shift break was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'location_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.location.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'location_updated':
                return (_jsxs("div", { children: [((_c = event.content.location) === null || _c === void 0 ? void 0 : _c.name) + ' ', _jsx("span", { className: "bold", children: (_d = event.content.schema) === null || _d === void 0 ? void 0 : _d.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_e = event.content.oldValues) === null || _e === void 0 ? void 0 : _e.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_f = event.content.newValues) === null || _f === void 0 ? void 0 : _f.map(function (val) { return val + ' '; }) })] }));
            case 'location_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.location.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'role_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.role.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'role_updated':
                return (_jsxs("div", { children: [((_g = event.content.role) === null || _g === void 0 ? void 0 : _g.name) + ' ', _jsx("span", { className: "bold", children: (_h = event.content.schema) === null || _h === void 0 ? void 0 : _h.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_j = event.content.oldValues) === null || _j === void 0 ? void 0 : _j.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_k = event.content.newValues) === null || _k === void 0 ? void 0 : _k.map(function (val) { return val + ' '; }) })] }));
            case 'role_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.role.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'timeoff_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "", children: event.content.timeoff.approvalStatus }), ' ', _jsx("span", { className: "", children: event.content.timeoff.reason }), ' timeoff was created for ', _jsx("span", { className: "bold", children: event.content.user.name }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.timeoff.startAt, 'lll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.timeoff.endAt, 'lll') }), ' by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'timeoff_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_l = event.content.schema) === null || _l === void 0 ? void 0 : _l.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_m = event.content.oldValues) === null || _m === void 0 ? void 0 : _m.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_o = event.content.newValues) === null || _o === void 0 ? void 0 : _o.map(function (val) { return val + ' '; }) })] }));
            case 'timeoff_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "", children: event.content.timeoff.approvalStatus }), ' ', _jsx("span", { className: "", children: event.content.timeoff.reason }), ' timeoff was deleted for ', _jsx("span", { className: "bold", children: event.content.user.name }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.timeoff.startAt, 'lll') }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.timeoff.endAt, 'lll', true) }), ' by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'pay_period_created':
                return (_jsxs("div", { children: ['A pay period from ', _jsx("span", { className: "", children: timeFormatFn(event.content.payPeriod.startDate, 'll') }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.payPeriod.endDate, 'll') }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'pay_period_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_p = event.content.schema) === null || _p === void 0 ? void 0 : _p.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_q = event.content.oldValues) === null || _q === void 0 ? void 0 : _q.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_r = event.content.newValues) === null || _r === void 0 ? void 0 : _r.map(function (val) { return val + ' '; }) })] }));
            case 'pay_period_deleted':
                return (_jsxs("div", { children: ['A pay period from ', _jsx("span", { className: "", children: timeFormatFn(event.content.payPeriod.startDate, 'll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.payPeriod.endDate, 'll') }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'user_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.user.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'user_updated':
                return (_jsxs("div", { children: [((_s = event.content.user) === null || _s === void 0 ? void 0 : _s.name) + ' ', _jsx("span", { className: "bold", children: (_t = event.content.schema) === null || _t === void 0 ? void 0 : _t.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_u = event.content.oldValues) === null || _u === void 0 ? void 0 : _u.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_v = event.content.newValues) === null || _v === void 0 ? void 0 : _v.map(function (val) { return val + ' '; }) })] }));
            case 'user_pay_updated':
                return (_jsxs("div", { children: [((_w = event.content.user) === null || _w === void 0 ? void 0 : _w.name) + ' ', _jsx("span", { className: "bold", children: "Pay Rate" }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_x = event.content.oldValues) === null || _x === void 0 ? void 0 : _x.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_y = event.content.newValues) === null || _y === void 0 ? void 0 : _y.map(function (val) { return val + ' '; }) })] }));
            case 'user_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.user.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'contact_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.contact.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'contact_updated':
                return (_jsxs("div", { children: [((_z = event.content.contact) === null || _z === void 0 ? void 0 : _z.name) + ' ', _jsx("span", { className: "bold", children: (_0 = event.content.schema) === null || _0 === void 0 ? void 0 : _0.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_1 = event.content.oldValues) === null || _1 === void 0 ? void 0 : _1.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_2 = event.content.newValues) === null || _2 === void 0 ? void 0 : _2.map(function (val) { return val + ' '; }) })] }));
            case 'contact_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.contact.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'shift_created':
                return (_jsxs("div", { children: ['Shift created for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.shift.startAt, 'lll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.shift.endAt, 'lll') })] }));
            case 'shift_deleted':
                return (_jsxs("div", { children: ['Shift deleted for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.shift.startAt, 'lll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.shift.endAt, 'lll') })] }));
            case 'shift_group_created':
                return (_jsxs("div", { children: ['Shift group created for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.shiftGroup.startAt, 'lll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.shiftGroup.endAt, 'lll') })] }));
            case 'shift_group_deleted':
                return (_jsxs("div", { children: ['Shift group deleted for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.shiftGroup.startAt, 'lll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.shiftGroup.endAt, 'lll') })] }));
            case 'shift_wage_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: "Pay Rate" }), ' updated from', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: event.content.oldPayRate }) }), ' to', _jsx("span", { className: "", children: event.content.newPayRate + ' ' }), _jsx("span", { className: "bold", children: "Bill Rate" }), ' updated from', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: event.content.oldBillRate }) }), ' to', _jsx("span", { className: "", children: event.content.newBillRate })] }));
            case 'task_template_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskTemplate.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_template_updated':
                return event.content.propertyName === TaskTemplateInfoProperty.TASK_TYPE ? (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_3 = event.content.oldValues) === null || _3 === void 0 ? void 0 : _3.map(function (val) {
                                    return val ? getTaskTypeDisplayText(val) : '';
                                }) }) }), ' to ', _jsx("span", { className: "", children: (_4 = event.content.newValues) === null || _4 === void 0 ? void 0 : _4.map(function (val) {
                                return val ? getTaskTypeDisplayText(val) : '';
                            }) })] })) : (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_5 = event.content.oldValues) === null || _5 === void 0 ? void 0 : _5.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_6 = event.content.newValues) === null || _6 === void 0 ? void 0 : _6.map(function (val) { return val + ' '; }) })] }));
            case 'task_template_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskTemplate.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.task.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'task_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_7 = event.content.schema) === null || _7 === void 0 ? void 0 : _7.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_8 = event.content.oldValues) === null || _8 === void 0 ? void 0 : _8.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_9 = event.content.newValues) === null || _9 === void 0 ? void 0 : _9.map(function (val) { return val + ' '; }) })] }));
            case 'task_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.task.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'task_group_template_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskGroup.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_group_template_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_10 = event.content.oldValues) === null || _10 === void 0 ? void 0 : _10.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_11 = event.content.newValues) === null || _11 === void 0 ? void 0 : _11.map(function (val) { return val + ' '; }) })] }));
            case 'task_group_template_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskGroup.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_rule_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskRule.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_rule_updated':
                return event.content.propertyName === TaskRuleInfoProperty.CONDITIONS ? (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' were updated'] })) : (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_12 = event.content.oldValues) === null || _12 === void 0 ? void 0 : _12.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_13 = event.content.newValues) === null || _13 === void 0 ? void 0 : _13.map(function (val) { return val + ' '; }) })] }));
            case 'task_rule_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskRule.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'document_created':
                return (_jsxs("div", { children: [' Document was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'document_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_14 = event.content.schema) === null || _14 === void 0 ? void 0 : _14.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_15 = event.content.oldValues) === null || _15 === void 0 ? void 0 : _15.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_16 = event.content.newValues) === null || _16 === void 0 ? void 0 : _16.map(function (val) { return val + ' '; }) })] }));
            case 'document_deleted':
                return (_jsxs("div", { children: ['Document was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'document_completed':
                return (_jsxs("div", { children: [' Document was completed by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'placement_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.placement.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'placement_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_17 = event.content.schema) === null || _17 === void 0 ? void 0 : _17.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_18 = event.content.oldValues) === null || _18 === void 0 ? void 0 : _18.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_19 = event.content.newValues) === null || _19 === void 0 ? void 0 : _19.map(function (val) { return val + ' '; }) })] }));
            case 'placement_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.placement.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'job_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.job.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'job_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_20 = event.content.schema) === null || _20 === void 0 ? void 0 : _20.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_21 = event.content.oldValues) === null || _21 === void 0 ? void 0 : _21.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_22 = event.content.newValues) === null || _22 === void 0 ? void 0 : _22.map(function (val) { return val + ' '; }) })] }));
            case 'job_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.job.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'record_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: ((_23 = event.content.record) === null || _23 === void 0 ? void 0 : _23.title)
                                ? event.content.record.title
                                : event.content.collectionName }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'record_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: ((_24 = event.content.record) === null || _24 === void 0 ? void 0 : _24.title)
                                ? (_25 = event.content.record) === null || _25 === void 0 ? void 0 : _25.title
                                : event.content.collectionName }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'record_updated':
            case 'shift_group_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_26 = event.content.schema) === null || _26 === void 0 ? void 0 : _26.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_27 = event.content.oldValues) === null || _27 === void 0 ? void 0 : _27.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_28 = event.content.newValues) === null || _28 === void 0 ? void 0 : _28.map(function (val) { return val + ' '; }) })] }));
            case 'shift_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_29 = event.content.schema) === null || _29 === void 0 ? void 0 : _29.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_30 = event.content.oldValues) === null || _30 === void 0 ? void 0 : _30.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_31 = event.content.newValues) === null || _31 === void 0 ? void 0 : _31.map(function (val) { return val + ' '; }) })] }));
            case 'shift_clock_in':
                return (_jsxs("div", { children: ['Clocked in at ', _jsx("span", { className: "bold", children: timeFormatFn((_32 = event.content.shift) === null || _32 === void 0 ? void 0 : _32.clockIn, 'LT', false) }), ' for Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_33 = event.content.shift) === null || _33 === void 0 ? void 0 : _33.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_34 = event.content.user) === null || _34 === void 0 ? void 0 : _34.name })] }));
            case 'shift_unscheduled_clock_in':
                return (_jsxs("div", { children: ['Clocked in at ', _jsx("span", { className: "bold", children: timeFormatFn((_35 = event.content.shift) === null || _35 === void 0 ? void 0 : _35.clockIn, 'LT') }), ' for ', _jsx("span", { className: "bold", children: (_36 = event.content.user) === null || _36 === void 0 ? void 0 : _36.name })] }));
            case 'shift_clock_out':
                return (_jsxs("div", { children: ['Clocked out at ', _jsx("span", { className: "bold", children: timeFormatFn((_37 = event.content.shift) === null || _37 === void 0 ? void 0 : _37.clockOut, 'LT') }), ' for ', _jsx("span", { className: "bold", children: (_38 = event.content.user) === null || _38 === void 0 ? void 0 : _38.name })] }));
            case 'shift_break_in':
                return (_jsxs("div", { children: ['Clocked in a ', _jsx("span", { className: "bold", children: (_39 = event.content.breakRule) === null || _39 === void 0 ? void 0 : _39.name }), ' break at ', _jsx("span", { className: "bold", children: timeFormatFn((_40 = event.content.shiftBreak) === null || _40 === void 0 ? void 0 : _40.clockIn, 'LT') }), ' for ', _jsx("span", { className: "bold", children: (_41 = event.content.user) === null || _41 === void 0 ? void 0 : _41.name })] }));
            case 'shift_break_out':
                return (_jsxs("div", { children: ['Clocked out of a ', _jsx("span", { className: "bold", children: (_42 = event.content.breakRule) === null || _42 === void 0 ? void 0 : _42.name }), ' break at ', _jsx("span", { className: "bold", children: timeFormatFn((_43 = event.content.shiftBreak) === null || _43 === void 0 ? void 0 : _43.clockOut, 'LT') }), ' for ', _jsx("span", { className: "bold", children: (_44 = event.content.user) === null || _44 === void 0 ? void 0 : _44.name })] }));
            case 'shift_claim':
                return (_jsxs("div", { children: ['Claimed a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_45 = event.content.shift) === null || _45 === void 0 ? void 0 : _45.startAt, 'lll') })] }));
            case 'shift_release':
                return (_jsxs("div", { children: ['Released a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_46 = event.content.shift) === null || _46 === void 0 ? void 0 : _46.startAt, 'lll') })] }));
            case 'release_request_approved':
                return (_jsxs("div", { children: ['Approved release request of a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_47 = event.content.shift) === null || _47 === void 0 ? void 0 : _47.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_48 = event.content.user) === null || _48 === void 0 ? void 0 : _48.name })] }));
            case 'release_request_rejected':
                return (_jsxs("div", { children: ['Rejected release request of a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_49 = event.content.shift) === null || _49 === void 0 ? void 0 : _49.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_50 = event.content.user) === null || _50 === void 0 ? void 0 : _50.name })] }));
            case 'shift_request_approved':
                return (_jsxs("div", { children: ['Approved a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_51 = event.content.shift) === null || _51 === void 0 ? void 0 : _51.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_52 = event.content.user) === null || _52 === void 0 ? void 0 : _52.name })] }));
            case 'shift_request_rejected':
                return (_jsxs("div", { children: ['Rejected a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_53 = event.content.shift) === null || _53 === void 0 ? void 0 : _53.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_54 = event.content.user) === null || _54 === void 0 ? void 0 : _54.name })] }));
            case 'shift_group_claimed':
                return (_jsxs("div", { children: ['Claimed a Shift Group scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_55 = event.content.shiftGroup) === null || _55 === void 0 ? void 0 : _55.startAt, 'lll') })] }));
            case 'shift_group_request_approved':
                return (_jsxs("div", { children: ['Approved a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_56 = event.content.shiftGroup) === null || _56 === void 0 ? void 0 : _56.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_57 = event.content.user) === null || _57 === void 0 ? void 0 : _57.name })] }));
            case 'shift_group_request_rejected':
                return (_jsxs("div", { children: ['Rejected a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_58 = event.content.shiftGroup) === null || _58 === void 0 ? void 0 : _58.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_59 = event.content.user) === null || _59 === void 0 ? void 0 : _59.name })] }));
            case 'shift_recommended':
                return (_jsx("div", { children: !!event.content.users && (_jsxs(_Fragment, { children: ['Recommended a Shift to ', _jsx("span", { className: "bold", children: event.content.users
                                    .map(function (user) {
                                    return user.name;
                                })
                                    .join(', ') })] })) }));
            case 'shift_recommendation_accepted':
                return (_jsxs("div", { children: ['Accepted a Shift Recommendation for a shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_60 = event.content.shift) === null || _60 === void 0 ? void 0 : _60.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_61 = event.content.user) === null || _61 === void 0 ? void 0 : _61.name })] }));
            case 'shift_recommendation_rejected':
                return (_jsxs("div", { children: ['Rejected a Shift Recommendation for a shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_62 = event.content.shift) === null || _62 === void 0 ? void 0 : _62.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_63 = event.content.user) === null || _63 === void 0 ? void 0 : _63.name })] }));
            case 'project_started':
                return (_jsxs("div", { children: ['Project ', _jsx("span", { className: "bold", children: (_64 = event.content.project) === null || _64 === void 0 ? void 0 : _64.name }), ' started at ', _jsx("span", { className: "bold", children: timeFormatFn((_65 = event.content.tracking) === null || _65 === void 0 ? void 0 : _65.clockIn, 'lll') }), ' by ', _jsx("span", { className: "bold", children: (_66 = event.content.user) === null || _66 === void 0 ? void 0 : _66.name })] }));
            case 'project_ended':
                return (_jsxs("div", { children: ['Project ', _jsx("span", { className: "bold", children: (_67 = event.content.project) === null || _67 === void 0 ? void 0 : _67.name }), ' end at ', _jsx("span", { className: "bold", children: timeFormatFn((_68 = event.content.tracking) === null || _68 === void 0 ? void 0 : _68.clockIn, 'lll') }), ' by ', _jsx("span", { className: "bold", children: (_69 = event.content.user) === null || _69 === void 0 ? void 0 : _69.name })] }));
            case 'task_assigned':
                return (_jsx("span", { className: "bold", children: "".concat(event.content.actor.name, " assigned ").concat(event.content.taskName, " to ").concat(event.content.assignee) }));
            case 'task_completed':
                return (_jsx("span", { className: "bold", children: "".concat(event.content.assignee, " completed ").concat(event.content.taskName) }));
            case 'task_group_assigned':
                return (_jsx("span", { className: "bold", children: "".concat(event.content.assignee, " was assigned ").concat(event.content.taskGroupName) }));
            case 'task_group_completed':
                return (_jsx("span", { className: "bold", children: "".concat(event.content.assignee, " completed ").concat(event.content.taskGroupName) }));
            case 'permission_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' updated ', _jsx("span", { className: "bold", children: (_70 = event.content.accessGroup) === null || _70 === void 0 ? void 0 : _70.name }), "'s ", ((_71 = event.content.accessGroup) === null || _71 === void 0 ? void 0 : _71.id) ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "bold", children: (_72 = event.content.accessGroup) === null || _72 === void 0 ? void 0 : _72.type }), ' ', event.content.accessGroup.id, ' permission to ', _jsx("span", { className: "bold", children: event.content.accessGroup.permission })] })) : (_jsxs(_Fragment, { children: [_jsx("span", { className: "bold", children: (_73 = event.content.accessGroup) === null || _73 === void 0 ? void 0 : _73.type }), ' permission'] }))] }));
            case 'schedule_published':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' published schedule from ', _jsx("span", { className: "bold", children: event.content.from }), ' to ', _jsx("span", { className: "bold", children: event.content.to }), ', ', _jsx("span", { className: "bold", children: event.content.shiftNumber }), ' shifts were published'] }));
            case 'schedule_copied':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' copied schedule from ', _jsx("span", { className: "bold", children: event.content.from }), ' to ', _jsx("span", { className: "bold", children: event.content.to }), ', ', _jsx("span", { className: "bold", children: event.content.shiftNumber }), ' shifts were copied'] }));
            case 'schedule_cleared':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' cleared schedule from ', _jsx("span", { className: "bold", children: event.content.from }), ' to ', _jsx("span", { className: "bold", children: event.content.to }), ', ', _jsx("span", { className: "bold", children: event.content.shiftNumber }), ' shifts were cleared'] }));
            case 'button_clicked':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' clicked ', _jsx("span", { className: "bold", children: event.content.buttonName }), ' button.'] }));
        }
    }, [timeFormatFn]);
    if (isLoading && firstTimeLoadData.current === true)
        return (_jsx("div", { className: "activityEvents", children: _jsx(ZiraRowsSkeleton, { rowNumber: 10 }) }));
    return (_jsx("div", { className: "activityEvents", onScroll: handlePaginationScroll, children: _jsxs(Collapse, { expandIcon: function () { return _jsx(DownArrowOutlinedIcon, {}); }, expandIconPosition: "end", ghost: true, children: [activityFeedData === null || activityFeedData === void 0 ? void 0 : activityFeedData.map(function (events, idx) {
                    return (_jsx(Panel, { className: "activityPanel mb-[12px] bg-white hover:border-azure-50 hover:shadow-hover", header: genHeader(events), children: events === null || events === void 0 ? void 0 : events.map(function (event) {
                            var content = event.content;
                            var canOpenRecordDetail = onOpenRecordDetail && !!content.recordId;
                            return (_jsxs("div", { className: "panelRow", children: [_jsx("div", {}), _jsxs("div", { className: "activityDetail".concat(canOpenRecordDetail ? ' hover:shadow-card' : ''), onClick: function () {
                                            if (canOpenRecordDetail) {
                                                onOpenRecordDetail(content.recordId);
                                            }
                                        }, children: [_jsx("div", { className: "employeeIcon" }), renderContentType(event)] })] }, "".concat(event.recordedAt, "-").concat(idx)));
                        }) }, idx));
                }), !(activityFeedData === null || activityFeedData === void 0 ? void 0 : activityFeedData.length) ? _jsx("div", { children: "No Events" }) : _jsx(_Fragment, {}), canLoadMore && !hasScrollBar() && (_jsxs("div", { className: "loadMore", onClick: function () { return getActivityFeedData(); }, children: [_jsx(ArrowDownOutlined, {}), _jsx("span", { children: "Load More" })] }))] }) }));
};
export default React.memo(ActivityFeedEvents);
