var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useAccountApi, useAutomation } from 'src/hooks/api';
import { Select } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { CollectionType } from 'src/shared';
import BuilderBar from 'src/components/Automations/BuilderBar';
import { accountIdState } from 'src/state';
import { useRecoilValue } from 'recoil';
import { AutomationComponent, AutomationFilterType, } from 'src/components/Automations/Interface';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { formFilterSaveObject } from 'src/components/Automations/util';
import { TaskRuleConditionOperator } from '../../interface';
var Option = Select.Option;
var TaskRuleConditions = function (_a) {
    var ruleId = _a.ruleId, collectionId = _a.collectionId, conditionData = _a.conditionData, onConditionChange = _a.onConditionChange;
    var accountId = useRecoilValue(accountIdState);
    var _b = useState([]), filterOptions = _b[0], setFilterOptions = _b[1];
    var _c = useState(conditionData.conditions.map(function (condition) { return (__assign(__assign({}, condition), { id: uuidv4(), appliesTo: AutomationFilterType.RECORD })); })), currentConditions = _c[0], setCurrentConditions = _c[1];
    useEffect(function () {
        setCurrentConditions(conditionData.conditions.map(function (condition) { return (__assign(__assign({}, condition), { id: uuidv4(), appliesTo: AutomationFilterType.RECORD })); }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruleId]);
    var useGetAccountCollectionOptionsQuery = useAccountApi().useGetAccountCollectionOptionsQuery;
    var useGetAutomationFilterOptions = useAutomation().useGetAutomationFilterOptions;
    var getAutomationFilterOptionsMutation = useGetAutomationFilterOptions();
    // Fetch all collections
    var allCollections = useGetAccountCollectionOptionsQuery().data;
    useEffect(function () {
        var _a;
        if (collectionId) {
            // Init filter options (used in filters and send message)
            getAutomationFilterOptionsMutation.mutate({ accountId: accountId, collectionId: collectionId }, {
                onSuccess: function (resp) {
                    var _a;
                    setFilterOptions((_a = resp === null || resp === void 0 ? void 0 : resp.schemaGroups.filter(function (group) { return group.type === AutomationFilterType.RECORD; })) !== null && _a !== void 0 ? _a : []);
                },
            });
        }
        else {
            var jobCollectionId = (_a = allCollections === null || allCollections === void 0 ? void 0 : allCollections.find(function (collection) { return collection.type === CollectionType.JOB; })) === null || _a === void 0 ? void 0 : _a.id;
            if (jobCollectionId) {
                onConditionChange(jobCollectionId, {
                    conditions: [],
                    operator: TaskRuleConditionOperator.AND,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionId]);
    var addCondition = useCallback(function () {
        setCurrentConditions(function (prev) { return __spreadArray(__spreadArray([], prev, true), [{ id: uuidv4() }], false); });
    }, []);
    var renderConditionOperatorSelect = useCallback(function (disabled) { return (_jsxs(Select, { className: "min-w-[100px] text-lg", disabled: disabled, value: conditionData.operator, onChange: function (operator) {
            onConditionChange(collectionId, __assign(__assign({}, conditionData), { operator: operator }));
        }, children: [_jsx(Option, { value: TaskRuleConditionOperator.AND, children: "AND" }, TaskRuleConditionOperator.AND), _jsx(Option, { value: TaskRuleConditionOperator.OR, children: "OR" }, TaskRuleConditionOperator.OR)] })); }, [collectionId, conditionData, onConditionChange]);
    return (_jsxs("div", { className: "TaskRuleConditions flex flex-col w-full gap-4", children: [_jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel", children: "Collection" }), _jsx(Select, { className: "w-full", value: collectionId, placeholder: "Select a Collection", onChange: function () {
                            return onConditionChange(collectionId, {
                                conditions: conditionData.conditions,
                                operator: TaskRuleConditionOperator.AND,
                            });
                        }, disabled: !!collectionId, showSearch: true, filterOption: function (input, option) {
                            var _a;
                            return ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : '')
                                .toLowerCase()
                                .includes(input.toLowerCase());
                        }, children: allCollections === null || allCollections === void 0 ? void 0 : allCollections.map(function (option) { return (_jsx(Option, { value: option.id, children: option.name }, option.id)); }) })] }), _jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel", children: "Select Condition" }), currentConditions.map(function (condition, index) {
                        var _a;
                        return (_jsxs("div", { className: "flex items-center gap-4", children: [index === 0 && (_jsx("div", { className: "pl-[10px] min-w-[100px] text-lg font-medium", children: "IF" })), index === 1 && _jsx(_Fragment, { children: renderConditionOperatorSelect(false) }), index > 1 && _jsx(_Fragment, { children: renderConditionOperatorSelect(true) }), _jsx(BuilderBar, { accountId: accountId, allCollections: allCollections, baseCollectionId: collectionId, component: AutomationComponent.FILTER, savedFilter: condition.schemaId ? condition : undefined, defaultFilterOptions: filterOptions, sendDataBack: function (builderState) {
                                        var filterSaveObj = __assign(__assign({}, formFilterSaveObject(builderState)), { id: condition.id });
                                        setCurrentConditions(function (prev) {
                                            return prev.map(function (c) { return (c.id !== condition.id ? c : filterSaveObj); });
                                        });
                                        onConditionChange(collectionId, __assign(__assign({}, conditionData), { conditions: currentConditions.map(function (c) {
                                                return c.id !== condition.id ? c : filterSaveObj;
                                            }) }));
                                    } }), _jsx("div", { className: "rounded-lg p-1 hover:cursor-pointer hover:bg-gray-60 border-2 border-[#f4f4f5]", onClick: function () {
                                        setCurrentConditions(function (prev) {
                                            return prev.filter(function (c) { return c.id !== condition.id; });
                                        });
                                        onConditionChange(collectionId, __assign(__assign({}, conditionData), { conditions: currentConditions.filter(function (c) { return c.id !== condition.id; }) }));
                                    }, children: _jsx(DeleteOutlinedIcon, { fill: "black" }) })] }, (_a = condition.id) !== null && _a !== void 0 ? _a : index));
                    })] }), _jsxs("div", { className: "w-36 h-[32px] cursor-pointer pl-2 pr-3 py-1.5 bg-white rounded-lg border border-black border-opacity-10 justify-center items-center gap-2 inline-flex", onClick: addCondition, children: [_jsx("div", { className: "relative text-xl", children: "+" }), _jsx("div", { className: "text-black text-sm font-semibold", children: "Add Condition" })] })] }));
};
export default React.memo(TaskRuleConditions);
