import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PaymentRulesPill from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesTable/component/PaymentRulesTableCell/component/PaymentRulesPill';
import { useRecoilValue } from 'recoil';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { CollectionType } from 'src/shared';
import { useAccountApi } from 'src/hooks/api';
import PaymentRulesTag from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesTable/component/PaymentRulesTableCell/component/PaymentRulesTag';
import PaymentRuleDateTimeConditionTag from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesTable/component/PaymentRulesTableCell/component/PaymentRulesConditionPill/component/PaymentRuleDateTimeConditionTag';
import { availablePayConditionSchemasState } from 'src/components/Settings/PayRate/atoms';
var PaymentRulesAdjustmentPill = function (_a) {
    var _b, _c, _d, _e, _f;
    var condition = _a.condition;
    var clause = condition.clauseValue;
    var availablePayConditionSchemas = useRecoilValue(availablePayConditionSchemasState);
    var shiftCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.SHIFT));
    var filterGroup = availablePayConditionSchemas.find(function (item) {
        return item.collectionId === shiftCollectionId;
    });
    var schemaName = ((_b = filterGroup === null || filterGroup === void 0 ? void 0 : filterGroup.schemas.find(function (item) { return item.id === condition.schemaId; })) === null || _b === void 0 ? void 0 : _b.name) ||
        condition.schemaId;
    var useGetUniversalSimpleListV2 = useAccountApi().useGetUniversalSimpleListV2;
    var simpleList = useGetUniversalSimpleListV2({
        schemaId: condition.schemaId,
    }).data;
    return (_jsxs(PaymentRulesPill, { children: [_jsx("span", { className: "text-black ", children: "[Shifts]" }), ' ', _jsx("span", { className: "text-gray-500 ", children: schemaName }), ' ', _jsx("span", { className: "text-black", children: clause }), (_d = (_c = condition.bespokeValue) === null || _c === void 0 ? void 0 : _c.daysOfWeek) === null || _d === void 0 ? void 0 : _d.map(function (dayOfWeek) { return (_jsx(PaymentRulesTag, { children: dayOfWeek }, "".concat(condition.id, "-").concat(dayOfWeek))); }), !condition.bespokeValue && ((_e = condition.filterValue) === null || _e === void 0 ? void 0 : _e.type) === 'datetime' && (_jsx(PaymentRuleDateTimeConditionTag, { condition: condition })), (_f = condition.valueIds) === null || _f === void 0 ? void 0 : _f.map(function (id) {
                var universalListOption = simpleList === null || simpleList === void 0 ? void 0 : simpleList.options.find(function (item) { return item.id === id; });
                if (!universalListOption)
                    return null;
                return (_jsx(PaymentRulesTag, { color: universalListOption.color, children: universalListOption.name }, "tag-".concat(id, "}")));
            })] }));
};
export default PaymentRulesAdjustmentPill;
