import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
var VerifyFileSection = function () {
    var isVerified = true;
    var contentBgColor = isVerified ? '#E5F4EF' : '#FFEDEC';
    var contentTextColor = isVerified ? '#4CB391' : '#B3342F';
    var renderContentText = useMemo(function () {
        if (isVerified) {
            return (_jsx(_Fragment, { children: "No errors were found in your upload. You are good to proceed with the import process." }));
        }
        else {
            return (_jsxs(_Fragment, { children: [_jsx("span", { className: "font-medium", children: "Warning" }), _jsx("br", {}), " The new rates will take effect once the file is verified. You cannot go back to the old pay rates once the columns are synced."] }));
        }
    }, [isVerified]);
    return (_jsxs("div", { className: "mt-5 rounded-lg p-3 font-medium flex gap-2", style: {
            backgroundColor: contentBgColor,
            color: contentTextColor,
            alignItems: isVerified ? 'center' : 'flex-start',
        }, children: [_jsx(InfoCircleOutlined, { style: {
                    color: contentTextColor,
                    stroke: contentTextColor,
                    fontSize: '18px',
                }, size: 24 }), _jsx("div", { children: renderContentText })] }));
};
export default React.memo(VerifyFileSection);
