import { CellInputMode, ItemDataSource, ItemRelationTypeSet, NativeGeneratedSet, NativePath, SchemaDisplayType, ShiftGeneratedSchemaSet, TitleSchemaNativePathSet, ValueDataType, } from '../../shared';
export var getDataSourceOfSchema = function (schema) {
    if (schema.inputMode == CellInputMode.NATIVE ||
        (schema.nativePath && NativeGeneratedSet.has(schema.nativePath))) {
        return ItemDataSource.NATIVE_FIELDS;
    }
    if (ItemRelationTypeSet.has(schema.type)) {
        return ItemDataSource.RELATIONS;
    }
    if (schema.type == ValueDataType.PLAIN_SELECTION) {
        return ItemDataSource.SELECTION_VALUES;
    }
    if (schema.type === ValueDataType.RECORD_METRIC) {
        return ItemDataSource.RECORD_METRIC_VALUES;
    }
    return ItemDataSource.ITEMS_DATA;
};
export var getDisplayType = function (schema) {
    if (schema.type == ValueDataType.DATETIME) {
        return SchemaDisplayType.TIME;
    }
    if (schema.type == ValueDataType.BUTTON) {
        return SchemaDisplayType.BUTTON;
    }
    if (schema.type == ValueDataType.FILE) {
        return SchemaDisplayType.FILES;
    }
    if (schema.type == ValueDataType.SIGNATURE) {
        return SchemaDisplayType.SIGNATURE;
    }
    if (schema.type === ValueDataType.TITLE) {
        return SchemaDisplayType.TITLE;
    }
    if (schema.type === ValueDataType.FORMULA) {
        return SchemaDisplayType.FORMULA;
    }
    if (schema.inputMode == CellInputMode.GENERATED) {
        if (schema.nativePath == NativePath.SHIFT_STATUS) {
            return SchemaDisplayType.TEXT;
        }
        else if (ShiftGeneratedSchemaSet.has(schema.nativePath) ||
            schema.type === ValueDataType.RECORD_METRIC) {
            return SchemaDisplayType.TEXT;
        }
        else if (schema.nativePath == NativePath.PLACEMENT_STATUS) {
            return SchemaDisplayType.SELECTION;
        }
    }
    if (schema.inputMode == CellInputMode.NATIVE) {
        if ((schema.type === ValueDataType.NATIVE_SPECIAL &&
            schema.nativePath == NativePath.LOCATION_ADDRESS) ||
            schema.nativePath === NativePath.USER_LAST_ACTIVE) {
            return SchemaDisplayType.TEXT;
        }
        else if (schema.type === ValueDataType.NATIVE_NAME &&
            schema.nativePath &&
            TitleSchemaNativePathSet.has(schema.nativePath)) {
            return SchemaDisplayType.TITLE;
        }
        else if (schema.type === ValueDataType.NATIVE_SELECTION ||
            schema.type === ValueDataType.NATIVE_GROUP_ID ||
            schema.type === ValueDataType.NATIVE_TEMPLATE_ID) {
            return SchemaDisplayType.SELECTION;
        }
        return getDisplayTypeOfStdObjType(schema.type) || SchemaDisplayType.TEXT;
    }
    if (schema.type == ValueDataType.CUSTOM) {
        return SchemaDisplayType.SELECTION;
    }
    if (schema.inputMode == CellInputMode.SINGLE ||
        schema.inputMode == CellInputMode.MULTI ||
        schema.inputMode == CellInputMode.INTEGRATION) {
        return schema.type == ValueDataType.USER
            ? SchemaDisplayType.USER
            : SchemaDisplayType.SELECTION;
    }
    if (schema.inputMode == CellInputMode.USER_DEFINED_GENERATED &&
        schema.type == ValueDataType.PLAIN_SELECTION) {
        return SchemaDisplayType.SELECTION;
    }
    if (schema.type === ValueDataType.ROLLUP) {
        return SchemaDisplayType.ROLLUP;
    }
    return SchemaDisplayType.TEXT;
};
export var getDisplayTypeOfStdObjType = function (type) {
    var suffix = getSuffixOfTypeString(type);
    if (!type.startsWith('native_')) {
        return null;
    }
    if (suffix == 'text' ||
        suffix == 'number' ||
        suffix == 'currency' ||
        suffix == 'email' ||
        suffix == 'phone' ||
        suffix == 'url') {
        return SchemaDisplayType.TEXT;
    }
    if (suffix == 'datetime') {
        return SchemaDisplayType.TIME;
    }
    if (suffix == 'bool' ||
        suffix == 'location_id' ||
        suffix == 'location_ids' ||
        suffix == 'type_id' ||
        suffix == 'role_id' ||
        suffix == 'role_ids' ||
        suffix == 'break_policy_id' ||
        suffix == 'break_rule_id' ||
        suffix == 'timeoff_policy_id' ||
        suffix == 'timeoff_type_id' ||
        suffix == 'overtime_policy_id' ||
        suffix == 'user_access_group_ids' ||
        suffix == 'overtime_type_id' ||
        suffix == 'geofence_id' ||
        suffix == 'holiday_policy_id' ||
        suffix == 'special' ||
        suffix == 'group_id' ||
        suffix == 'placement_rid' ||
        suffix == 'shift_rid' ||
        suffix == 'job_rid') {
        return SchemaDisplayType.SELECTION;
    }
    if (suffix == 'user_id' || suffix === 'user_ids') {
        return SchemaDisplayType.USER;
    }
    return SchemaDisplayType.TEXT;
};
var getSuffixOfTypeString = function (type) {
    return type.substring(7);
};
export var isSchemaTitle = function (schema) {
    return (schema.type === ValueDataType.TITLE ||
        (schema.type === ValueDataType.NATIVE_NAME &&
            !!schema.nativePath &&
            TitleSchemaNativePathSet.has(schema.nativePath)));
};
