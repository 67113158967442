var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createContext, useCallback, useReducer } from 'react';
import { Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from 'react-query';
import { SettingOutlined } from '@ant-design/icons';
import { useFetch } from 'src/hooks/api';
import { RollupType } from 'src/components/RollupConfigurationModal/interface/constants';
import RollupViewSettingsToggles from 'src/components/RollupConfigurationModal/components/RollupViewSettingsToggles';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import NumberSchemaSelector from 'src/components/RollupConfigurationModal/components/NumberSchemaSelector';
import RollupReverseCollectionSelector from './components/RollupReverseCollectionSelector/RollupReverseCollectionSelector';
import RollupTypeSelector from './components/RollupTypeSelector';
import useRollup from './hooks/useRollup';
var initialState = {
    collectionId: undefined,
    rollupType: RollupType.COUNT,
    viewSettings: {
        showValues: true,
        showProgressBar: false,
        showPercent: false,
    },
    refCollectionId: undefined,
    refSchemaId: undefined,
    sumNumberSchemaId: undefined,
    errorMessage: undefined,
};
export var ROLLUP_MODAL_Z_INDEX = 1100;
export var RollupConfigurationContext = createContext({
    rollupConfiguration: initialState,
    setViewConfiguration: function (viewConfig) {
        // eslint-disable-next-line
        console.error("Not implemented, ".concat(viewConfig, " not set"));
    },
    setRollupType: function (value) {
        // eslint-disable-next-line
        console.error("Not implemented, ".concat(value, " not set"));
    },
    setRefIds: function (collectionId, schemaId) {
        // eslint-disable-next-line
        console.error("Not implemented, ".concat(collectionId, ", ").concat(schemaId, " not set"));
    },
    setRollupFilter: function (filterSettings) {
        // eslint-disable-next-line
        console.error("Not implemented ".concat((filterSettings === null || filterSettings === void 0 ? void 0 : filterSettings[0]) || 'No filter'));
    },
    setDenominatorFilter: function (filterSettings) {
        // eslint-disable-next-line
        console.error("Not implemented ".concat((filterSettings === null || filterSettings === void 0 ? void 0 : filterSettings[0]) || 'No filter'));
    },
    setSumNumberSchema: function (value) {
        // eslint-disable-next-line
        console.error("Not implemented ".concat(value, " for Sum Schema"));
    },
});
export var RollupActionTypes;
(function (RollupActionTypes) {
    RollupActionTypes[RollupActionTypes["SET_ROLLUP_TYPE"] = 0] = "SET_ROLLUP_TYPE";
    RollupActionTypes[RollupActionTypes["SET_VIEW_SETTINGS"] = 1] = "SET_VIEW_SETTINGS";
    RollupActionTypes[RollupActionTypes["SET_REF_IDS"] = 2] = "SET_REF_IDS";
    RollupActionTypes[RollupActionTypes["SET_ERROR_MESSAGE"] = 3] = "SET_ERROR_MESSAGE";
    RollupActionTypes[RollupActionTypes["RESET"] = 4] = "RESET";
    RollupActionTypes[RollupActionTypes["SET_FILTER_SETTINGS"] = 5] = "SET_FILTER_SETTINGS";
    RollupActionTypes[RollupActionTypes["SET_DENOMINATOR_FILTER_SETTINGS"] = 6] = "SET_DENOMINATOR_FILTER_SETTINGS";
    RollupActionTypes[RollupActionTypes["SET_SUM_NUMBER_SCHEMA"] = 7] = "SET_SUM_NUMBER_SCHEMA";
})(RollupActionTypes || (RollupActionTypes = {}));
function rollupReducer(state, action) {
    switch (action.type) {
        case RollupActionTypes.SET_ROLLUP_TYPE:
            return __assign(__assign({}, state), { rollupType: action.payload, 
                //Reset view setting and number schema whenever rollup type changes
                viewSettings: {
                    showValues: true,
                    showProgressBar: false,
                    showPercent: false,
                }, sumNumberSchemaId: undefined });
        case RollupActionTypes.SET_VIEW_SETTINGS:
            return __assign(__assign({}, state), { viewSettings: __assign({}, action.payload) });
        case RollupActionTypes.SET_REF_IDS:
            return __assign(__assign({}, state), { refCollectionId: action.payload.collectionId, refSchemaId: action.payload.schemaId, filterSettings: [], denominatorFilterSettings: [], sumNumberSchemaId: undefined, errorMessage: undefined });
        case RollupActionTypes.SET_ERROR_MESSAGE:
            return __assign(__assign({}, state), { errorMessage: action.payload });
        case RollupActionTypes.SET_FILTER_SETTINGS:
            return __assign(__assign({}, state), { filterSettings: action.payload });
        case RollupActionTypes.SET_DENOMINATOR_FILTER_SETTINGS:
            return __assign(__assign({}, state), { denominatorFilterSettings: action.payload });
        case RollupActionTypes.SET_SUM_NUMBER_SCHEMA: {
            return __assign(__assign({}, state), { sumNumberSchemaId: action.payload });
        }
        case RollupActionTypes.RESET:
            return __assign(__assign({}, initialState), { collectionId: state.collectionId });
    }
}
var RollupConfigurationModal = function (_a) {
    var preexistingRollupId = _a.preexistingRollupId, onClose = _a.onClose, collectionId = _a.collectionId, onRollupSchemaSave = _a.onRollupSchemaSave;
    var _b = useReducer(rollupReducer, __assign(__assign({}, initialState), { collectionId: collectionId })), rollupConfiguration = _b[0], dispatch = _b[1];
    var setViewConfiguration = useCallback(function (viewConfig) {
        dispatch({ type: RollupActionTypes.SET_VIEW_SETTINGS, payload: viewConfig });
    }, []);
    var setRollupType = useCallback(function (rollupType) {
        dispatch({ type: RollupActionTypes.SET_ROLLUP_TYPE, payload: rollupType });
    }, []);
    var setRefIds = useCallback(function (collectionId, schemaId) {
        dispatch({
            type: RollupActionTypes.SET_REF_IDS,
            payload: {
                collectionId: collectionId,
                schemaId: schemaId,
            },
        });
    }, []);
    var setFilterSetting = useCallback(function (filterSettings) {
        dispatch({
            type: RollupActionTypes.SET_FILTER_SETTINGS,
            payload: filterSettings,
        });
    }, []);
    var setDenominatorSetting = useCallback(function (filterSettings) {
        dispatch({
            type: RollupActionTypes.SET_DENOMINATOR_FILTER_SETTINGS,
            payload: filterSettings,
        });
    }, []);
    var setSumNumberSchema = useCallback(function (sumSchemaId) {
        dispatch({
            type: RollupActionTypes.SET_SUM_NUMBER_SCHEMA,
            payload: sumSchemaId,
        });
    }, []);
    var customFetch = useFetch().request;
    var isFetching = useQuery(['useGetRollupQuery', preexistingRollupId], function () {
        return customFetch({
            method: 'GET',
            url: "rollup/".concat(preexistingRollupId),
        });
    }, {
        enabled: !!preexistingRollupId,
        onSuccess: function (data) {
            var _a, _b;
            if (data) {
                var refCollectionId = data.refCollectionId, refSchemaId = data.refSchemaId, settings = data.settings;
                setRefIds(refCollectionId, refSchemaId);
                setRollupType((settings === null || settings === void 0 ? void 0 : settings.rollupType) || RollupType.COUNT);
                setViewConfiguration(settings.viewSettings);
                setSumNumberSchema(settings === null || settings === void 0 ? void 0 : settings.numberSchemaId);
                setFilterSetting(((_a = settings === null || settings === void 0 ? void 0 : settings.filterSettings) === null || _a === void 0 ? void 0 : _a.map(function (filter) { return (__assign(__assign({}, filter), { id: uuidv4() })); })) || []);
                if (settings.rollupType === 'PROGRESS_TRACKER') {
                    setDenominatorSetting(((_b = settings === null || settings === void 0 ? void 0 : settings.denominatorFilterSettings) === null || _b === void 0 ? void 0 : _b.map(function (filter) { return (__assign(__assign({}, filter), { id: uuidv4() })); })) || []);
                }
            }
        },
    }).isFetching;
    var updateRollup = useRollup(preexistingRollupId).updateRollup;
    var refetchCollection = useRefetchCollection();
    return (_jsx(Modal, { width: 580, zIndex: ROLLUP_MODAL_Z_INDEX, open: true, centered: true, closable: false, bodyStyle: { padding: '16px' }, onOk: function () {
            var refSchemaId = rollupConfiguration.refSchemaId, refCollectionId = rollupConfiguration.refCollectionId, rollupType = rollupConfiguration.rollupType, filterSettings = rollupConfiguration.filterSettings, denominatorFilterSettings = rollupConfiguration.denominatorFilterSettings, viewSettings = rollupConfiguration.viewSettings, sumNumberSchemaId = rollupConfiguration.sumNumberSchemaId;
            if (refSchemaId && refCollectionId) {
                if (onRollupSchemaSave && collectionId) {
                    var newRollupId_1 = uuidv4();
                    onRollupSchemaSave(newRollupId_1, refCollectionId, refSchemaId, function () {
                        updateRollup({
                            rollupId: newRollupId_1,
                            refCollectionId: refCollectionId,
                            refSchemaId: refSchemaId,
                            rollupType: rollupType,
                            filterSettings: filterSettings,
                            denominatorFilterSettings: denominatorFilterSettings,
                            viewSettings: viewSettings,
                            sumNumberSchemaId: sumNumberSchemaId,
                        });
                        refetchCollection(collectionId);
                    });
                }
                else if (preexistingRollupId && collectionId) {
                    updateRollup({
                        rollupId: preexistingRollupId,
                        refCollectionId: refCollectionId,
                        refSchemaId: refSchemaId,
                        rollupType: rollupType,
                        filterSettings: filterSettings,
                        denominatorFilterSettings: denominatorFilterSettings,
                        viewSettings: viewSettings,
                        sumNumberSchemaId: sumNumberSchemaId,
                    });
                    refetchCollection(collectionId);
                }
                onClose();
            }
            else {
                dispatch({
                    type: RollupActionTypes.SET_ERROR_MESSAGE,
                    payload: 'Please set a reverse collection',
                });
            }
        }, afterClose: function () { return dispatch({ type: RollupActionTypes.RESET }); }, onCancel: onClose, okText: "Save", destroyOnClose: true, okButtonProps: {
            type: 'default',
            className: 'ZiraModalSaveBtn',
            disabled: (rollupConfiguration.rollupType === RollupType.PROGRESS_TRACKER &&
                !rollupConfiguration.viewSettings.showPercent &&
                !rollupConfiguration.viewSettings.showValues &&
                !rollupConfiguration.viewSettings.showProgressBar) ||
                (rollupConfiguration.rollupType === RollupType.SUM &&
                    !rollupConfiguration.sumNumberSchemaId),
        }, children: _jsxs(RollupConfigurationContext.Provider, { value: {
                rollupConfiguration: rollupConfiguration,
                setViewConfiguration: setViewConfiguration,
                setRollupType: setRollupType,
                setRefIds: setRefIds,
                setRollupFilter: setFilterSetting,
                setDenominatorFilter: setDenominatorSetting,
                setSumNumberSchema: setSumNumberSchema,
            }, children: [_jsxs("div", { className: "flex flex-col space-y-4", children: [_jsxs("div", { className: "ModalTitle space-x-2", children: [_jsx("span", { className: "text-gray400", children: _jsx(SettingOutlined, {}) }), _jsx("span", { className: "font-medium text-[15px]", children: "Configure Roll Up Schema" })] }), _jsx(RollupTypeSelector, {}), !isFetching && (_jsxs(_Fragment, { children: [_jsx(RollupReverseCollectionSelector, { type: "numerator", hasDenominator: rollupConfiguration.rollupType === RollupType.PROGRESS_TRACKER }), rollupConfiguration.rollupType === RollupType.PROGRESS_TRACKER && (_jsx(RollupReverseCollectionSelector, { type: "denominator", hasDenominator: true })), rollupConfiguration.rollupType === RollupType.SUM && (_jsx(NumberSchemaSelector, {}))] })), rollupConfiguration.rollupType === RollupType.PROGRESS_TRACKER && (_jsx(RollupViewSettingsToggles, {}))] }), !!rollupConfiguration.errorMessage && (_jsx("span", { className: "text-red-1000", children: rollupConfiguration.errorMessage }))] }) }));
};
export default RollupConfigurationModal;
