import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { message, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { getUploadUrl, UploadUrlType } from 'src/hooks/api/useUploadApi';
import octoClient from '../services/octoClient';
export var UploadFileType;
(function (UploadFileType) {
    UploadFileType["IMAGE"] = "image/*";
    UploadFileType["AUDIO"] = "audio/*";
    UploadFileType["VIDEO"] = "video/*";
    UploadFileType["PNG"] = ".png";
    UploadFileType["JPG"] = ".jpg";
    UploadFileType["JPEG"] = ".jpeg";
    UploadFileType["DOC"] = ".doc";
    UploadFileType["DOCX"] = ".docx";
    UploadFileType["PDF"] = ".pdf";
    UploadFileType["CSV"] = ".CSV";
})(UploadFileType || (UploadFileType = {}));
var ComponentWithUpload = function (props) {
    var acceptedFileType = props.acceptedFileType, children = props.children, successAction = props.successAction, type = props.type;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _a = useState(), token = _a[0], setToken = _a[1];
    var uploadFileURL = octoClient.serverUrl + getUploadUrl(token ? type : UploadUrlType.Public);
    useEffect(function () {
        var mounted = true;
        getAccessTokenSilently().then(function (resp) {
            if (mounted)
                setToken(resp);
        });
        return function () {
            mounted = false;
        };
    }, [getAccessTokenSilently]);
    return (_jsx(Upload, { accept: acceptedFileType.toString(), name: 'file', showUploadList: false, action: uploadFileURL, headers: token ? { Authorization: "Bearer ".concat(token) } : {}, onChange: function (info) {
            message.destroy();
            if (info.file.status === 'uploading') {
                message.loading('Uploading...', 1000);
            }
            else if (info.file.status === 'done') {
                successAction(info);
                message.success("".concat(info.file.name, " file uploaded successfully."));
            }
            else if (info.file.status === 'error') {
                message.error("".concat(info.file.name, " file upload failed."));
            }
        }, children: children }));
};
export default React.memo(ComponentWithUpload);
