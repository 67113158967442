var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment-timezone';
import { ShiftStatusDisplayNameToColorMap, } from 'src/components/ScheduleView/Constant';
import { CellInputMode, ItemDataSource, NativePath, OPTIONS_COLOR_MAP, SchemaDisplayType, ShiftDurationGeneratedSchemaSet, ValueDataType, } from 'src/shared';
import { getAccountCurrencyCode } from 'src/utils/Account';
import { checkStaleOptionInCell, getCurrencyValue, getNativeType } from 'src/utils/Collection';
import { getSelectionOptionFromApprovalStatusString } from './ApprovalStatusService';
import { getDataSourceOfSchema, getDisplayType } from './CollectionSchemaService';
export var formatCellData = function (collectionCell, schema, recordColor, 
//! For native bespoke title only
isNativeTitle, avatarUrl) {
    var _a, _b, _c;
    var content = collectionCell.content, options = collectionCell.options, versionedOptions = collectionCell.versionedOptions, cellFiles = collectionCell.files;
    if (!schema) {
        if (isNativeTitle) {
            var text = (_a = content === null || content === void 0 ? void 0 : content.split('avatarUrl')[0]) !== null && _a !== void 0 ? _a : null;
            var hasAvatarUrl = (content === null || content === void 0 ? void 0 : content.split('avatarUrl')[1]) === '';
            return {
                title: {
                    value: text,
                    color: recordColor,
                    avatar: hasAvatarUrl ? avatarUrl : undefined,
                },
            };
        }
        return;
    }
    var displayType = getDisplayType(schema);
    if (displayType === SchemaDisplayType.TEXT) {
        if (getNativeType(schema.type) === ValueDataType.CURRENCY ||
            schema.type === ValueDataType.CURRENCY) {
            var accountCurrency = getAccountCurrencyCode();
            return {
                amount: {
                    symbol: accountCurrency,
                    value: content ? getCurrencyValue(content) : '0.00',
                },
            };
        }
        if (getNativeType(schema.type) === ValueDataType.PHONE) {
            return {
                phone: content,
            };
        }
        if (schema.nativePath == NativePath.LOCATION_ADDRESS) {
            return { address: content };
        }
        if (schema.nativePath === NativePath.USER_LAST_ACTIVE) {
            return {
                text: {
                    value: content,
                    color: content !== 'No activity yet' ? 'GREEN' : 'GREY',
                },
            };
        }
        if (ShiftDurationGeneratedSchemaSet.has(schema.nativePath)) {
            return {
                text: {
                    value: content,
                },
            };
        }
        if ((schema.nativePath === NativePath.SHIFT_STATUS ||
            schema.nativePath === NativePath.SHIFT_GROUP_STATUS) &&
            content) {
            return {
                text: {
                    value: content,
                    color: ShiftStatusDisplayNameToColorMap[content],
                },
            };
        }
        if (schema.type === ValueDataType.RECORD_METRIC) {
            return {
                text: {
                    value: content,
                    color: content ? getRecordMetricColor(content) : undefined,
                },
            };
        }
        var isStale = checkStaleOptionInCell(options);
        return {
            text: {
                value: isStale ? 'Regenerating...' || '' : content,
            },
        };
    }
    if (displayType === SchemaDisplayType.TITLE) {
        return {
            text: {
                value: content,
                color: recordColor,
            },
        };
    }
    if (displayType === SchemaDisplayType.TIME) {
        var time = content ? [moment(content).toISOString()] : null;
        return { time: time };
    }
    if (displayType === SchemaDisplayType.USER) {
        return {
            users: options.map(function (option) { return ({
                rid: option.id,
                name: option.name,
                iconUrl: option.avatarUrl,
            }); }),
        };
    }
    if (displayType === SchemaDisplayType.SELECTION) {
        var isStale = checkStaleOptionInCell(options);
        if (schema.inputMode == CellInputMode.USER_DEFINED_GENERATED &&
            (isStale || !options.length)) {
            return {
                text: {
                    value: 'Regenerating...',
                },
            };
        }
        if (schema.type === ValueDataType.NATIVE_BOOL && content) {
            //! Boolean type is returned as { content: 'true'/'false' } from API so we convert to selections
            return {
                selections: [
                    {
                        id: content,
                        label: content === 'true' ? 'Yes' : 'No',
                        color: content === 'true' ? '#E5F4EF' : '#FFEDEC',
                    },
                ],
            };
        }
        if (schema.type == ValueDataType.NATIVE_SPECIAL &&
            schema.nativePath == NativePath.TIMEOFF_APPROVAL_STATUS &&
            content) {
            //! Approval status type is returned as { content: 'PENDING'/'ACCEPTED'/'REJECTED' } from API so we convert to selections
            return {
                selections: [getSelectionOptionFromApprovalStatusString(content)],
            };
        }
        return {
            selections: options.length
                ? options.map(function (option) { return ({
                    id: option.id,
                    label: option.name,
                    color: option.color,
                }); })
                : versionedOptions === null || versionedOptions === void 0 ? void 0 : versionedOptions.map(function (option) { return ({
                    id: option.id,
                    label: option.name,
                    color: option.color,
                }); }),
        };
    }
    if (displayType === SchemaDisplayType.FILES) {
        var files = {
            mode: getDataSourceOfSchema(schema) == ItemDataSource.NATIVE_FIELDS
                ? 'single'
                : 'multiple',
            data: cellFiles,
            canLinkToTemplate: true,
        };
        return { files: files };
    }
    if (displayType === SchemaDisplayType.SIGNATURE) {
        return { signature: content };
    }
    if (displayType === SchemaDisplayType.FORMULA) {
        return { formula: schema.formulaId || null };
    }
    if (displayType === SchemaDisplayType.BUTTON) {
        return {
            button: {
                value: 'Button',
            },
        };
    }
    if (displayType === SchemaDisplayType.ROLLUP) {
        return {
            rollup: {
                value: content,
                numerator: ((_b = options.find(function (option) { return option.name === 'numerator'; })) === null || _b === void 0 ? void 0 : _b.value) || null,
                denominator: ((_c = options.find(function (option) { return option.name === 'denominator'; })) === null || _c === void 0 ? void 0 : _c.value) || null,
            },
        };
    }
};
/** Filters out invalid select tags  */
export var getValidSelectTags = function (currentTags, allOptions) {
    var tags = [];
    var allTagIds = allOptions.map(function (option) { return option.id; });
    for (var _i = 0, currentTags_1 = currentTags; _i < currentTags_1.length; _i++) {
        var selectedTag = currentTags_1[_i];
        var tagIndex = allTagIds.indexOf(selectedTag.id);
        if (tagIndex !== -1) {
            var foundTag = allOptions[tagIndex];
            tags.push({
                id: foundTag.id,
                text: foundTag.name,
                color: foundTag.color || '',
                userAvatar: foundTag.image,
            });
            if (tags.length === currentTags.length) {
                break;
            }
        }
    }
    return tags;
};
/** Parse back boolean from options to content  */
export var parseBackBooleanCollectionCells = function (collectionRecord) {
    var collectionCells = __assign({}, collectionRecord);
    Object.keys(collectionCells).forEach(function (schemaId) {
        var cell = collectionCells[schemaId];
        if (cell.options[0] && ['true', 'false'].includes(cell.options[0].id)) {
            cell = __assign(__assign({}, cell), { options: [], content: cell.options[0].id });
            collectionCells[schemaId] = cell;
        }
    });
    return collectionCells;
};
export var getRecordMetricColor = function (textValue) {
    if (!textValue)
        return;
    var numbers = textValue.split('/');
    var isRatio = numbers.length === 2 && !isNaN(Number(numbers[0])) && !isNaN(Number(numbers[1]));
    if (!isRatio)
        return;
    var numerator = Number(numbers[0]);
    var denominator = Number(numbers[1]);
    var percentage = (numerator / denominator) * 100;
    if (numerator === denominator || percentage >= 100) {
        return OPTIONS_COLOR_MAP.Green;
    }
    else if (percentage >= 50) {
        return OPTIONS_COLOR_MAP.Yellow;
    }
    else {
        return OPTIONS_COLOR_MAP.Red;
    }
};
