var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createContainer } from 'unstated-next';
export var ActivityFeedActorTypes = ['USER', 'AUTOMATION', 'INTEGRATION', 'SYSTEM', 'IMPORT'];
var useActivityFeed = function () {
    var _a = useState([]), collectionFilters = _a[0], setCollectionFilters = _a[1];
    var _b = useState([]), actorFilters = _b[0], setActorFilters = _b[1];
    var _c = useState([]), eventFilters = _c[0], setEventFilters = _c[1];
    var collectionFiltersRef = useRef(collectionFilters);
    var actorFiltersRef = useRef(actorFilters);
    var eventFiltersRef = useRef(eventFilters);
    useEffect(function () {
        collectionFiltersRef.current = collectionFilters;
    }, [collectionFilters]);
    useEffect(function () {
        actorFiltersRef.current = actorFilters;
    }, [actorFilters]);
    useEffect(function () {
        eventFiltersRef.current = eventFilters;
    }, [eventFilters]);
    var filterArray = useCallback(function (item, tempFilters) {
        var index = tempFilters.indexOf(item);
        if (index > -1) {
            tempFilters.splice(index, 1);
        }
        else {
            tempFilters.push(item);
        }
        return tempFilters;
    }, []);
    var filterCollections = useCallback(function (collectionId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempFilters;
        return __generator(this, function (_a) {
            tempFilters = cloneDeep(collectionFiltersRef.current);
            setCollectionFilters(filterArray(collectionId, tempFilters));
            return [2 /*return*/];
        });
    }); }, [filterArray]);
    var filterSources = useCallback(function (source) { return __awaiter(void 0, void 0, void 0, function () {
        var tempFilters;
        return __generator(this, function (_a) {
            tempFilters = cloneDeep(actorFiltersRef.current);
            setActorFilters(filterArray(source, tempFilters));
            return [2 /*return*/];
        });
    }); }, [filterArray]);
    var filterEventTypes = useCallback(function (type) { return __awaiter(void 0, void 0, void 0, function () {
        var tempFilters;
        return __generator(this, function (_a) {
            tempFilters = cloneDeep(eventFiltersRef.current);
            setEventFilters(filterArray(type, tempFilters));
            return [2 /*return*/];
        });
    }); }, [filterArray]);
    var getEventSummaryText = useCallback(function (events) {
        var _a;
        var inner = '';
        switch (events[0].content.type) {
            case 'email_sent':
                inner = ' sent email(s)';
                break;
            case 'sms_sent':
                inner = ' sent SMS message(s)';
                break;
            case 'in_app_message':
                inner = ' sent in-app message(s)';
                break;
            case 'break_created':
                inner =
                    ' created ' + events.length + ' breaks in ' + events[0].content.collectionName;
                break;
            case 'location_created':
                inner =
                    ' created ' +
                        events.length +
                        ' locations in ' +
                        events[0].content.collectionName;
                break;
            case 'location_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'location_deleted':
                inner =
                    ' deleted ' +
                        events.length +
                        ' locations in ' +
                        events[0].content.collectionName;
                break;
            case 'role_created':
                inner =
                    ' created ' + events.length + ' roles in ' + events[0].content.collectionName;
                break;
            case 'role_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'role_deleted':
                inner =
                    ' deleted ' + events.length + ' roles in ' + events[0].content.collectionName;
                break;
            case 'timeoff_created':
                inner =
                    ' created ' + events.length + ' timeoffs in ' + events[0].content.collectionName;
                break;
            case 'timeoff_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'timeoff_deleted':
                inner =
                    ' deleted ' + events.length + ' timeoffs in ' + events[0].content.collectionName;
                break;
            case 'pay_period_created':
                inner =
                    ' created ' +
                        events.length +
                        ' pay periods in ' +
                        events[0].content.collectionName;
                break;
            case 'pay_period_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'pay_period_deleted':
                inner =
                    ' deleted ' +
                        events.length +
                        ' pay periods in ' +
                        events[0].content.collectionName;
                break;
            case 'user_created':
                inner =
                    ' created ' + events.length + ' users in ' + events[0].content.collectionName;
                break;
            case 'user_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'user_deleted':
                inner =
                    ' deleted ' + events.length + ' users in ' + events[0].content.collectionName;
                break;
            case 'user_pay_updated':
                inner = ' made ' + events.length + " edits to a User's Pay Rate";
                break;
            case 'contact_created':
                inner =
                    ' created ' + events.length + ' contacts in ' + events[0].content.collectionName;
                break;
            case 'contact_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'contact_deleted':
                inner =
                    ' deleted ' + events.length + ' contacts in ' + events[0].content.collectionName;
                break;
            case 'task_template_created':
                inner = ' created ' + events.length + ' task templates';
                break;
            case 'task_template_updated':
                inner =
                    ' made ' +
                        events.length +
                        ' edits in the ' +
                        ((_a = events[0].content.taskTemplate) === null || _a === void 0 ? void 0 : _a.name) +
                        ' template';
                break;
            case 'task_template_deleted':
                inner = ' deleted ' + events.length + ' task templates';
                break;
            case 'task_created':
                inner =
                    ' created ' + events.length + ' tasks in ' + events[0].content.collectionName;
                break;
            case 'task_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'task_deleted':
                inner =
                    ' deleted ' + events.length + ' tasks in ' + events[0].content.collectionName;
                break;
            case 'task_group_template_created':
                inner = ' created ' + events.length + ' task group templates';
                break;
            case 'task_group_template_updated':
                inner = ' updated ' + events.length + ' task group templates';
                break;
            case 'task_group_template_deleted':
                inner = ' deleted ' + events.length + ' task group templates';
                break;
            case 'task_rule_created':
                inner = ' created ' + events.length + ' task rules';
                break;
            case 'task_rule_updated':
                inner = ' updated ' + events.length + ' task rules';
                break;
            case 'task_rule_deleted':
                inner = ' deleted ' + events.length + ' task rules';
                break;
            case 'document_created':
                inner =
                    ' created ' +
                        events.length +
                        ' documents in ' +
                        events[0].content.collectionName;
                break;
            case 'document_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'document_deleted':
                inner =
                    ' deleted ' +
                        events.length +
                        ' documents in ' +
                        events[0].content.collectionName;
                break;
            case 'document_completed':
                inner =
                    ' completed ' +
                        events.length +
                        ' documents in ' +
                        events[0].content.collectionName;
                break;
            case 'placement_created':
                inner =
                    ' created ' +
                        events.length +
                        ' placements in ' +
                        events[0].content.collectionName;
                break;
            case 'placement_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'placement_deleted':
                inner =
                    ' deleted ' +
                        events.length +
                        ' placements in ' +
                        events[0].content.collectionName;
                break;
            case 'job_created':
                inner = ' created ' + events.length + ' jobs in ' + events[0].content.collectionName;
                break;
            case 'job_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'job_deleted':
                inner = ' deleted ' + events.length + ' jobs in ' + events[0].content.collectionName;
                break;
            case 'record_created':
                inner =
                    ' created ' + events.length + ' records in ' + events[0].content.collectionName;
                break;
            case 'record_updated':
                inner =
                    ' made ' +
                        events.length +
                        ' record edits in ' +
                        events[0].content.collectionName;
                break;
            case 'record_deleted':
                inner =
                    ' deleted ' + events.length + ' records in ' + events[0].content.collectionName;
                break;
            case 'shift_created':
                inner =
                    ' created ' + events.length + ' shifts in ' + events[0].content.collectionName;
                break;
            case 'shift_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'shift_deleted':
                inner =
                    ' deleted ' + events.length + ' shifts in ' + events[0].content.collectionName;
                break;
            case 'shift_group_created':
                inner =
                    ' created ' +
                        events.length +
                        ' shift groups in ' +
                        events[0].content.collectionName;
                break;
            case 'shift_group_updated':
                inner = ' made ' + events.length + ' edits in ' + events[0].content.collectionName;
                break;
            case 'shift_group_deleted':
                inner =
                    ' deleted ' +
                        events.length +
                        ' shift groups in ' +
                        events[0].content.collectionName;
                break;
            case 'shift_clock_in':
                inner = ' clocked into a Shift';
                break;
            case 'shift_unscheduled_clock_in':
                inner = ' clocked into an Unscheduled Shift';
                break;
            case 'shift_clock_out':
                inner = ' clocked out of a Shift';
                break;
            case 'shift_break_in':
                inner = ' clocked into a Break';
                break;
            case 'shift_break_out':
                inner = ' clocked out of a Break';
                break;
            case 'shift_claim':
                inner = ' submitted a Shift Claim';
                break;
            case 'shift_group_claimed':
                inner = ' submitted a Shift Group Claim';
                break;
            case 'shift_release':
                inner = ' released a Shift';
                break;
            case 'shift_request_approved':
                inner = ' approved a Shift Request';
                break;
            case 'shift_request_rejected':
                inner = ' rejected a Shift Request';
                break;
            case 'shift_group_request_approved':
                inner = ' approved a Shift Group Request';
                break;
            case 'shift_group_request_rejected':
                inner = ' rejected a Shift Group Request';
                break;
            case 'shift_recommended':
                inner = ' recommended a Shift';
                break;
            case 'shift_recommendation_accepted':
                inner = ' accepted a Shift Recommendation';
                break;
            case 'shift_recommendation_rejected':
                inner = ' rejected a Shift Recommendation';
                break;
            case 'project_started':
                inner = ' started a project';
                break;
            case 'project_ended':
                inner = ' ended a project';
                break;
            case 'task_assigned':
                inner = " assigned a new task ".concat(events[0].content.taskName, " to ").concat(events[0].content.assignee);
                break;
            case 'task_completed':
                inner = " completed the following task: ".concat(events[0].content.taskName);
                break;
            case 'task_group_assigned':
                inner = " assigned new task group: ".concat(events[0].content.taskGroupName, " to ").concat(events[0].content.assignee);
                break;
            case 'task_group_completed':
                inner = " completed the following task group: ".concat(events[0].content.taskGroupName);
                break;
            case 'permission_updated':
                inner = " made ".concat(events.length, " permission edits");
                break;
            case 'schedule_published':
            case 'schedule_copied':
            case 'schedule_cleared':
                inner = " performed ".concat(events.length, " schedule actions");
                break;
            case 'button_clicked':
                inner = " clicked ".concat(events[0].content.buttonName, " button");
                break;
            case 'shift_wage_updated':
                inner = " updated the matched pay rate";
                break;
            default:
                break;
        }
        return inner;
    }, []);
    return {
        collectionFilters: collectionFilters,
        actorFilters: actorFilters,
        eventFilters: eventFilters,
        filterCollections: filterCollections,
        filterSources: filterSources,
        filterEventTypes: filterEventTypes,
        getEventSummaryText: getEventSummaryText,
    };
};
var ActivityFeedState = createContainer(useActivityFeed);
export default ActivityFeedState;
