import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { collectionTableSchemaRollupIdSelector } from 'src/state';
import RollupProgressBar from 'src/components/OptimizedTableView/Cell/RollupCell/component/RollupProgressBar';
import { useRecoilValue } from 'recoil';
import useRollup from 'src/components/RollupConfigurationModal/hooks/useRollup';
import { RollupType } from 'src/components/RollupConfigurationModal/interface/constants';
var RollupCellHOC = function (_a) {
    var _b;
    var numerator = _a.numerator, denominator = _a.denominator, value = _a.value, cellPos = _a.cellPos, forceViewSettings = _a.forceViewSettings, forceShow = _a.forceShow;
    var rollupId = useRecoilValue(collectionTableSchemaRollupIdSelector(cellPos));
    var _c = useRollup(rollupId), data = _c.data, isFetching = _c.isFetching;
    var rollupSetting = data === null || data === void 0 ? void 0 : data.settings;
    var viewSetting = ((_b = data === null || data === void 0 ? void 0 : data.settings) === null || _b === void 0 ? void 0 : _b.viewSettings) || forceViewSettings;
    if (isFetching) {
        return _jsx("div", { children: "..." });
    }
    if (numerator !== null &&
        denominator !== null &&
        ((rollupSetting === null || rollupSetting === void 0 ? void 0 : rollupSetting.rollupType) === RollupType.PROGRESS_TRACKER || forceShow) &&
        viewSetting) {
        return (_jsxs("div", { className: "flex flex-row space-x-1 h-full w-full items-center justify-center text-[13px]", children: [viewSetting.showValues && (_jsx("div", { className: "flex flex-row items-center justify-center w-[50px] truncate shrink-0", children: "".concat(numerator, "/").concat(denominator) })), viewSetting.showProgressBar && (_jsx(RollupProgressBar, { percentage: numerator / denominator })), viewSetting.showPercent && (_jsx("div", { className: "flex flex-row items-center justify-center w-[50px] truncate shrink-0", children: numerator === 0 && denominator === 0
                        ? Number(0).toLocaleString(undefined, {
                            style: 'percent',
                            maximumFractionDigits: 2,
                        })
                        : Number(numerator / denominator).toLocaleString(undefined, {
                            style: 'percent',
                            maximumFractionDigits: 2,
                        }) }))] }));
    }
    else {
        return (_jsx("div", { className: "flex space-x-2 items-center h-full text-[13px]", children: value === null || isNaN(Number(value)) || Number(value) % 1 === 0
                ? value
                : Number(value).toFixed(2) }));
    }
};
export default React.memo(RollupCellHOC);
