var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Select } from 'antd';
import RatingIconColorSelector from 'src/components/SchemaConfigModal/RatingSchemaConfig/RatingIconColorSelector';
import { generateRatingMaxValueOptions, ratingSchemaIconMap, } from 'src/components/SchemaConfigModal/RatingSchemaConfig/Constant';
import RatingSchemaCellContent from './RatingSchemaCellContent';
var RatingSchemaConfigurationSection = function (_a) {
    var ratingSchemaConfig = _a.ratingSchemaConfig, schemaConfigOnChange = _a.schemaConfigOnChange;
    var onIconTypeChange = useCallback(function (value) {
        schemaConfigOnChange(__assign(__assign({}, ratingSchemaConfig), { iconType: value }));
    }, [ratingSchemaConfig, schemaConfigOnChange]);
    var onIconColorChange = useCallback(function (color) {
        schemaConfigOnChange(__assign(__assign({}, ratingSchemaConfig), { color: color }));
    }, [ratingSchemaConfig, schemaConfigOnChange]);
    var onMaxRatingValueChange = useCallback(function (value) {
        schemaConfigOnChange(__assign(__assign({}, ratingSchemaConfig), { maxRating: value }));
    }, [ratingSchemaConfig, schemaConfigOnChange]);
    return (_jsxs("div", { children: [_jsxs("div", { children: [_jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: "Icons" }), _jsx(Select, { dropdownMatchSelectWidth: true, className: "w-full", value: ratingSchemaConfig.iconType, onChange: onIconTypeChange, children: Object.entries(ratingSchemaIconMap).map(function (_a) {
                            var key = _a[0], icon = _a[1];
                            return (_jsx(Select.Option, { value: key, children: icon }, key));
                        }) })] }), _jsxs("div", { children: [_jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: "Color" }), _jsx(RatingIconColorSelector, { currentColor: ratingSchemaConfig.color, onUpdateColor: onIconColorChange })] }), _jsxs("div", { children: [_jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: "Maximum Value" }), _jsx(Select, { dropdownMatchSelectWidth: true, className: "w-full", value: ratingSchemaConfig.maxRating, onChange: onMaxRatingValueChange, children: generateRatingMaxValueOptions().map(function (value) { return (_jsx(Select.Option, { value: value, children: value }, value)); }) })] }), _jsxs("div", { children: [_jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: "Preview" }), _jsx("div", { className: "w-full border border-gray-60 bg-[#F7F7F7] rounded-md p-2", children: _jsx(RatingSchemaCellContent, { iconType: ratingSchemaConfig.iconType, color: ratingSchemaConfig.color, maxValueNumber: ratingSchemaConfig.maxRating }) })] })] }));
};
export default React.memo(RatingSchemaConfigurationSection);
