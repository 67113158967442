var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DownOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Dropdown, Row } from 'antd';
import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { usePageApi, useSetting } from 'src/hooks/api';
import { CollectionType, NativePath } from 'src/shared';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
var MobileCustomShownData = function (_a) {
    var _b;
    var onChange = _a.onChange, _c = _a.fieldKeys, fieldKeys = _c === void 0 ? [] : _c, _d = _a.forbiddenSchemas, forbiddenSchemas = _d === void 0 ? new Set() : _d, accessGroupId = _a.accessGroupId, collectionType = _a.collectionType;
    var _e = useState(false), dropdownVisible = _e[0], setDropdownVisible = _e[1];
    var _f = useState(fieldKeys), selectedShemaIds = _f[0], setSelectedSchemaIds = _f[1];
    var useGetPayrollEnhancementSetting = useSetting().useGetPayrollEnhancementSetting;
    var useGetCollectionSchemaGroupsIncludingHiddenFromCollectionType = usePageApi().useGetCollectionSchemaGroupsIncludingHiddenFromCollectionType;
    var schemas = useGetCollectionSchemaGroupsIncludingHiddenFromCollectionType({
        type: collectionType,
        options: {
            enabled: dropdownVisible,
            cacheTime: 0,
        },
    }).data;
    var payrollEnhancementEnabled = useGetPayrollEnhancementSetting({
        options: {
            enabled: collectionType === CollectionType.SHIFT,
        },
    }).data;
    // Include user-custom schemas (no nativePath but createdAt and updatedAt dont have nativePath either so deliberately exclude those)
    // and include all nativePaths except specific forbidden nativePaths
    var customizableSchemas = (_b = schemas === null || schemas === void 0 ? void 0 : schemas.filter(function (schema) {
        return (!schema.nativePath && !['Created At', 'Updated At'].includes(schema.name)) ||
            ((schema.nativePath === NativePath.SHIFT_PAYROLL_STATUS ||
                schema.nativePath === NativePath.SHIFT_BILLING_STATUS) &&
                payrollEnhancementEnabled) ||
            (schema.nativePath != null && !forbiddenSchemas.has(schema.nativePath));
    })) !== null && _b !== void 0 ? _b : [];
    if (!accessGroupId)
        return _jsx(_Fragment, {});
    return (_jsx(Dropdown, { menu: {
            items: [],
        }, trigger: ['click'], onOpenChange: setDropdownVisible, dropdownRender: function () {
            return (_jsx("div", { className: "bg-white shadow-lg rounded-sm pr-2 overflow-auto max-h-[60vh] mr-2", style: {
                    boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
                }, children: _jsx(DropdownContent, { onChange: function (newIds) {
                        setSelectedSchemaIds(newIds);
                        onChange(newIds);
                    }, accessGroupDetailId: accessGroupId, customizableSchemas: customizableSchemas, selectedSchemaIds: selectedShemaIds }) }));
        }, children: _jsxs("div", { className: "rounded bg-gray-50 px-3 py-1 font-medium hover:cursor-pointer", children: ["Select Options ", _jsx(DownOutlined, {})] }) }));
};
var DropdownContent = function (_a) {
    var accessGroupDetailId = _a.accessGroupDetailId, selectedSchemaIds = _a.selectedSchemaIds, customizableSchemas = _a.customizableSchemas, onChange = _a.onChange;
    var sortCallback = function (a, b) {
        return selectedSchemaIds.indexOf(a.id) - selectedSchemaIds.indexOf(b.id);
    };
    var selectedSchemas = customizableSchemas
        .filter(function (schema) { return selectedSchemaIds.includes(schema.id); })
        .sort(sortCallback);
    var unselectedSchemas = customizableSchemas.filter(function (schema) { return !selectedSchemas.find(function (s) { return s.id === schema.id; }); });
    var onSchemaOptionVisibleToggle = useCallback(function (schemaId, isToggledOn) {
        var newSchemaUUIDs = isToggledOn
            ? selectedSchemaIds.filter(function (id) { return id !== schemaId; })
            : selectedSchemaIds.concat(schemaId);
        onChange(newSchemaUUIDs);
    }, [onChange, selectedSchemaIds]);
    var onSchemaOptionDragEnd = useCallback(function (_a) {
        var source = _a.source, destination = _a.destination;
        if (!destination)
            return;
        var newOrderedSchemas = cloneDeep(selectedSchemas);
        var reorderedItem = newOrderedSchemas.splice(source.index, 1)[0];
        newOrderedSchemas.splice(destination.index, 0, reorderedItem);
        onChange(newOrderedSchemas.map(function (s) { return s.id; }));
    }, [onChange, selectedSchemas]);
    return (_jsx(DragDropContext, { onDragEnd: onSchemaOptionDragEnd, children: _jsx(Droppable, { droppableId: accessGroupDetailId, children: function (provided) { return (_jsxs("div", __assign({ className: "flex w-full flex-col space-y-3 p-2" }, provided.droppableProps, { ref: provided.innerRef, children: [selectedSchemas.map(function (schema, idx) {
                        return (_jsx(Draggable, { draggableId: schema.id, index: idx, children: function (provided) {
                                return (_jsxs(Row, __assign({}, provided === null || provided === void 0 ? void 0 : provided.draggableProps, { ref: provided === null || provided === void 0 ? void 0 : provided.innerRef, onClick: function (e) { return e.stopPropagation(); }, align: "middle", justify: "space-between", children: [_jsxs("div", { className: "flex flex-row gap-1 items-center font-medium pr-3", children: [provided && (_jsx(DnDHandleIcon, __assign({ style: { cursor: 'grab' } }, provided.dragHandleProps))), _jsx("p", { className: "truncate", children: schema.name })] }), _jsx(Checkbox, { onChange: function () {
                                                return onSchemaOptionVisibleToggle(schema.id, true);
                                            }, defaultChecked: true })] })));
                            } }, "selected-".concat(schema.id, "-").concat(idx)));
                    }), provided.placeholder, unselectedSchemas.length > 0 && selectedSchemas.length !== 0 && (_jsx(Divider, {})), unselectedSchemas.map(function (schema, idx) {
                        return (_jsxs(Row, { onClick: function (e) { return e.stopPropagation(); }, align: "middle", justify: "space-between", children: [_jsxs("div", { className: "flex flex-row gap-1 items-center font-medium pr-3", children: [_jsx(DnDHandleIcon, { style: { cursor: 'auto', opacity: 0 } }), _jsx("p", { className: "truncate", children: schema.name })] }), _jsx(Checkbox, { onChange: function () {
                                        return onSchemaOptionVisibleToggle(schema.id, false);
                                    }, defaultChecked: false })] }, "unselected-".concat(schema.id, "-").concat(idx)));
                    })] }))); } }) }));
};
export default MobileCustomShownData;
